.contactInfoBlock{
    width: 100%;
    &__block-header{
        font-size: 44px;
        font-weight: $font-header-weight;
        text-align: center;
        line-height: 53.72px;
        color: $text-color-blue;
    }
    &__container{
        display: flex;
        gap: 80px;
        padding: 80px 20px;
        justify-content: space-between;
    
        @include mobiletablet {
            flex-direction: column !important;
            align-items: center;
            padding: 80px 20px;
        }
        .roundImageWidth{
            max-width: 20vw !important;
            @include mobiletablet{
                max-width: 50vw !important;
            }
        }
        .rounded-img-container{
            position: relative;
            width: 100%;
            padding-top: 100%;
            border-radius: 50%; 
            overflow: hidden;
        }

        .text-section {
            display: flex;
            flex-direction: column;
            gap: 25px;
            align-items: start;
            width: 100%;
    
            font-size: 16px;
            line-height: 26px;
    
            &__title {
                color: $text-color-blue;
                margin-top: unset;
                font-size: 36px;
            }

            ul {
                padding: 0;
                padding-inline-start: 17px;
            }

            &__media {
                width: 100%;
    
                @include desktop {
                    max-width: 50vw;
                }
                
                > img, > video {
                    width: 100%;
                    object-fit: cover;
                }
            }
    
            &__caption-container {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
            }
    
            &__caption-title, &__caption-text {
                font-size: 14px;
                margin: 0;
            }
    
            &__caption-title {
                color: $text-color-blue;
                font-weight:$font-header-weight;
            } 
    
            &__caption-text {
                color: $text-color-darker-grey;
                font-weight:$font-body-weight;
            }
    
            @include mobiletablet {
                align-items: center;
            }
    
            &__cards > div {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
    
            .card-block {
                > div {
                    > h4, > p {
                        font-size: 16px;
                        line-height: 26px;                    
                    }
                }
            }
            .contactInfoBlock__form{
                width: 100%;
                form{
                    margin:  0;
                    padding: 0;

                  @include desktop {
                    h2, aside, .Form__Status {
                        margin-left: 0;
                        margin-right: 0;
                    }
                  }  

                }
                .EPiServerForms{
                    background-color: unset;
                    margin: 0;
                }
                .Form__MainBody{
                    margin: 0;
                    @include mobiletablet{
                        margin: 0 auto;
                    }
                }
                .Form__Description{
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                }
                .EPiServerForms label, .EPiServerForms .Form__Element__Caption{
                    color:$text-color-blue;
                    font-weight: $font-header-weight;
                    font-size: 12px;
                    font-family: inherit;
                    margin: 0;
                }
                input[type="text"],input[type="email"],textarea,select{
                    border: 1px solid #EEF1F4;
                    max-height: 100px;
                }
                .EPiServerForms button{
                    width: 100%;
                    background-color: $background-orange !important;
                    margin: 0;
                }
                select, option{
                    background-color: $background-white;
                    color: $text-color-darker-grey;
                }
                select{
                    padding: 10px;
                    font-size: 14px;
                    font-family: inherit;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    background-image: url('/src/images/chevron-down-small.svg'); 
                    background-repeat: no-repeat; 
                    background-position: right 10px center; 
                    background-size: 25px; 
                }
            }   
            .contactInfoblock__link{
                a{
                    color: $text-color-blue;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    text-decoration: none;
                }
                .arrow {    
                    display: block;
                    color: $text-color-orange;
                    width: 16px;
                    height: 16px;
                    background-image: url(/src/images/ArrowBlue.svg);
                }
            }

        }
        
        .btn-primary {
            @include button-primary($background-orange, $text-color-white);
            margin: 0 auto;
            font-weight: 500;
        }

    }

    &__rounded-image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}