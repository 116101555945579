﻿body {
  margin: 0;
  position: relative;
  background-color: $background-white;
  color: $text-color-black;

  &.fixedPosition {
    position: fixed;
    width: 100%;
  }
}

.swiper-container:not(.swiper-initialized){
  visibility: hidden;
}

//Hide weglot original languageSwitcher
.country-selector { display:none; }

.weglot-hide{
  display: none !important;
}

.swiper-container {
  width: 100%;
  min-height: 60vh;
  position: relative;
  overflow: hidden;
}

.alert-danger {
  color: $text-color-orange;
  background-color: rgba($background-orange, 0.1);
  border-color: $text-color-orange;
  border-radius: 0;
}

.alert-success {
  color: $text-color-blue;
  background-color: rgba($background-blue, 0.1);
  border-color: $text-color-blue;
  border-radius: 0;
}

.highlight {
  color: $text-color-orange;
}

.modal-mask {
  background-color: rgba(0, 0, 0, 0.6);

  .modal-container .fa-times {
    color: $text-color-blue;
  }

  .fa-remove, .fa-close, .fa-times {
    position: absolute;
    right: 38.4px;
    top: 30.4px;
    width: 22.6px;
    height: 22.6px;

    @include mobiletablet {
      right: 20px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:hover, &:focus, &:active {
      outline: 0;
    }
  }

  .fa-remove:before, .fa-close:before, .fa-times:before, .fa-remove:after, .fa-close:after, .fa-times:after {
    position: absolute;
    right: 10px;
    top: 0;
    content: "";
    height: 22.6px;
    width: 2px;
    background-color: $border-blue;
  }

  .modal-container {
    font-family: $font-body;
    padding: 36px 38px;
    border-radius: 3px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
    width: 100% !important;
    max-width: 932px;

    @include mobiletablet {
      height: 100%;
      border-radius: 0;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      max-width: none;
    }

    > div {

      > h3 {
        font-size: 14px;
        font-weight: 500;
        color: $text-color-blue;
        padding: 0 38px;
        margin: 0 0 26px;
        text-transform: uppercase;

        @include mobiletablet {
          padding: 0 20px;
        }
      }
    }

    .embed-responsive {
      margin-top: 35px;
    }
  }
}

p {
  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin: 15px 0 15px 15px;

      &:before {
        content: '\f105';
        font-family: "FontAwesome";
        display: inline-block;
        margin-left: -1em;
        width: 1em;
        color: $text-color-orange;
      }
    }
  }
}

.nefab-list {
  list-style: none;
  padding-left: 0;

  li {
    margin: 15px 0 15px 15px;
    font-size: 16px;

    @include mobile {
      font-size: 14px;
    }

    &:before {
      content: '\f105';
      font-family: "FontAwesome";
      display: inline-block;
      margin-left: -1em;
      width: 1em;
      color: $text-color-orange;
    }
  }
}

.checkbox-container {
  position: relative;
  display: block;
  max-width: 500px;
  margin-bottom: 40px;


  label {
    cursor: pointer;
    display: inline;
    width: 100%;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.58;
    color: $text-color-darkgrey;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0;
      left: 0;
      background: $background-white;
      border-radius: 0;
      display: inline;
    }

    &:after {
      content: '';
      width: 10px;
      height: 6px;
      position: absolute;
      top: 4px;
      left: 3px;
      border: 2px solid $border-blue;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.5;
    }
  }

  input[type=checkbox] {
    visibility: hidden;
    display: inline;
    margin: 0 10px 0 0;

    &:checked + label:after {
      opacity: 1;
    }
  }

  p {
    display: inline !important;
  }

  span {
    font-size: 10px;
    color: $text-color-grey;
  }
}

blockquote {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  color: $text-color-blue;
  border: 0;
  padding: 0;
  margin: 25px 0;

  &:before {
    content: none
  }

  &:after {
    content: none;
  }

  @include mobile {
    font-size: 25px;
    font-weight: 300;
    line-height: 1.38;
    color: $text-color-orange;
  }
}

.pagination {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 10px 0 80px;
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  @include button-primary($background-blue, $text-color-white);
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  @include button-default($text-color-blue);
  opacity: 0.3;
}

.pagination > li > a, .pagination > li > span {
  @include button-default($text-color-blue);
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}


.pagination > li {
  margin: 5px;
}

.cookie-notice {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 25px 20px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.58);
  border: 1px solid #476190;
  background-color: rgba($background-blue, 0.9);
  color: $text-color-white;
  z-index: 9991;

  .container {
    @include mobile {
      padding: 0;
    }
  }

  p {
    color: $text-color-white;
  }

  a {
    color: $text-color-white;

    &:hover, &:focus {
      text-decoration: underline;
      color: $text-color-white;
    }
  }

  #btn-accept-cookies {
    @include button-primary($background-white, $text-color-blue);
    margin-top: 10px;

    @include mobile {
      margin-right: 20px;
    }
  }
}

.news-signup {
  padding: 70px 0 100px;

  @include mobile {
    padding: 0 0 55px;
  }


  .container {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    padding: 0 175px;

    @media (max-width: 1599px) {
      padding: 0 59px;
    }

    @include tablet {
      padding: 0 20px;
    }

    @include mobile {
      flex-flow: wrap;
      padding: 0 20px;
    }
  }

  &__info {
    margin-right: 40px;

    @include mobile {
      margin: 0 auto;
    }

    h5 {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.43;
      letter-spacing: normal;
      color: $text-color-blue;
      margin-top: 0;


      @include mobile {
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.5px;
        color: $text-color-orange;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 25px;
      }
    }

    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 2;
      letter-spacing: normal;
      margin-bottom: 0;

      @include mobile {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: center;
      }
    }
  }

  &__input {
    position: relative;

    @include mobile {
      width: 100%;
    }

    .alert {
      position: absolute;
      font-size: 10px;
      color: $text-color-grey;
      margin-top: 10px;
      padding: 0;
    }

    label {
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 1.5px;
      color: $text-color-blue;
      text-transform: uppercase;
      margin-bottom: 10px;

      @include mobile {
        margin-top: 25px;
      }
    }

    .input-group {
      width: 475px;
      height: 47px;

      @include tablet {
        width: 380px;
      }

      @include mobile {
        width: 100%;
      }
    }

    input {
      background: $background-white;
      border: 0;
      box-shadow: none;
      border-radius: 0;
      height: 47px;
      font-size: 12px;
      color: $text-color-black;

      &:focus, &:active {
        outline: 0;
        box-shadow: none;
        border: 0;
      }
    }

    .alert-danger {
      border: 0;
      background: transparent;
      color: $text-color-orange;
      font-size: 16px;
    }

    .alert-success {
      border: 0;
      background: transparent;
      color: $text-color-blue;
      font-size: 16px;
    }

    .input-group-addon {
      background: $background-white;
      border: 0;
      padding: 6px 10px;

      button {
        background: transparent;
        color: $text-color-blue;
        border: 0;
        margin-top: 10px;

        &:after {
          content: "";
          background-image: url(/src/images/ArrowBlue.svg);
          background-size: 8.4px 8.4px;
          background-repeat: no-repeat;
          padding: 0px 5px;
        }

        &:focus, &:active {
          outline: 0;
        }
      }
    }
  }
}

.navmenu-fixed-right {
  left: auto !important;
}

.arrow {
  &-left {
    &:before {
      content: "";
      display: block;
      background-image: url('/src/images/ArrowWhite.svg');
      background-size: 8px 8px;
      background-repeat: no-repeat;
      padding: 6px;
      margin-right: 5px;
    }
  }

  &-right {
    &:after {
      content: "";
      display: block;
      background-image: url('/src/images/ArrowWhite.svg');
      background-size: 8px 8px;
      background-repeat: no-repeat;
      padding: 6px;
      margin-left: 5px;
    }
  }
}

.content-shading {
  &-invertedmode {
    background: $text-color-blue;
    color: #fff;

    h1, h2, h3, h4, h5, h6 {
      color: #fff !important;
    }
  }

  &-thirtyfromtop {
    background: linear-gradient(0deg, rgba(255, 255, 153, 0) 0, rgba(41, 137, 216, 0) 70%, $background-pale-grey 0, $background-pale-grey);

    @include mobiletablet {
      background: transparent;
    }

    hr {
      border-top: 1px solid #ddd;
    }
  }

  &-thirtyfrombottom {
    background: linear-gradient(180deg, white 30%, white 70%, #eff1f5 0%);

    @include mobiletablet {
      background: transparent;
    }

    hr {
      border-top: 1px solid #ddd;
    }
  }

  &-seventyfrombottom {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 1) 30%, $background-pale-grey 0%);

    @include mobile {
      background: transparent;
    }

    hr {
      border-top: 1px solid #ddd;
    }
  }

  &-cover {
    background: linear-gradient(0deg, rgba(255, 255, 153, 0) 0, rgba(41, 137, 216, 0) 0%, $background-pale-grey 0, $background-pale-grey);

    hr {
      border-top: 1px solid #ddd;
    }
  }
}

.container {
  @media (min-width: 768px) {
    width: 100%;
  }

  @include mobiletablet {
    padding-right: 20px;
    padding-left: 20px;
  }

  @media (min-width: 992px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 1600px) {
    width: 1498px;
    max-width: auto;
    max-width: unset;
    padding-left: 0;
    padding-right: 0;
  }
}

.clickable {
  cursor: pointer;
}

.swiper {
  &-pagination-bullet-active {
    background: $text-color-blue;
  }

  &-button {
    &-prev {
      color: $text-color-blue;
      left: 20px;
    }

    &-next {
      color: $text-color-blue;
      right: 20px;
    }
  }
}

.hideme {
  display: none;
}

.content-shading-whitepaddingtop {
  margin-top: 60px;
}

.empty-content-tag {
  &:before {
    content: none;
  }

  &:after {
    content: none;
  }
}