﻿.five-column-block {
    &__container {
        margin: 145px 65px 75px;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        > div {
            width: 50%;

            @include tablet {
                width: 60%;
            }

            @include mobile {
                width: 100%;
            }

            &:first-child {
                max-width: 554px;

                @include tablet {
                    width: 40%;
                }

                @include mobile {
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    flex-flow: column;
                    margin: 0 auto;
                }
            }
        }

        @include mobiletablet {
            margin: 70px 28px 70px 28px;
        }

        @include mobile {
            margin: 70px 0 17px 0;
        }
    }

    &__heading {
        font-size: 35px;
        line-height: 1.43;
        margin-bottom: 40px;
        color: $text-color-blue;
        margin-top: 0;

        @include mobile {
            font-size: 25px;
            line-height: 1.36;
            text-align: center;
            margin-bottom: 35px;
        }
    }

    &__lead {
        font-size: 16px;
        line-height: 1.63;
        margin-bottom: 60px;

        @include mobile {
            text-align: center;
            margin-bottom: 35px;
        }
    }

    .btn-primary {
        @include button-primary($background-orange, $text-color-white);
        margin: 0 auto;
        font-weight: 500;
    }

    &__columns {
        @include mobile {
            margin-top: 80px;
        }

        > div {
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            @media (max-width: 1599px) {
                margin-left: 30px;
            }

            @include mobile {
                margin-left: 0;
            }

            > div {
                width: 50%;

                @include mobile {
                    width: 100%;
                    max-width: 554px;
                }

                .title-lead-and-image__container {
                    flex-flow: wrap;
                    justify-content: flex-start;
                    margin: 0;
                }

                .title-lead-and-image__icon {
                    display: block;
                    width: 100%;

                    img {
                        width: 40px;
                    }
                }

                .title-lead-and-image__content {
                    width: calc(100% - 30px);
                    min-width: auto;
                    min-width: unset;
                    max-width: auto;
                    max-width: unset;
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 70px;
                    justify-content: flex-start;

                    @include mobile {
                        width: 100%;
                        margin-bottom: 35px;
                    }

                    h3 {
                        font-size: 14px;
                        text-transform: uppercase;
                        margin: 25px 0 15px;

                        @include mobiletablet {
                            text-align: left;
                        }
                    }

                    p {
                        font-size: 14px;
                        line-height: 1.71;
                        margin: 0;
                    }

                    .btn-default {
                        @include arrow-link($text-color-blue);
                        margin: 15px 0;
                        align-items: flex-end;
                        display: flex;

                        &:after {
                            background-image: url('/src/images/ArrowBlue.svg');
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
