﻿.title-and-lead {
    background: $background-white;
    width: 100%;
    display: block;
    text-align: center;
    padding: 100px 20px 50px;

    @include mobiletablet {
        padding: 35px 20px 25px;
    }

    &__container {
        margin: 0 auto;
        max-width: 917px;

        h1 {
            font-size: 55px;
            line-height: 1.18;
            color: $text-color-blue;

            @include tablet {
                font-size: 3rem;
                line-height: 1.38;
                margin-top: 0;
            }

            @include mobile {
                font-size: 25px;
                line-height: 1.38;
                margin-top: 0;
            }
        }

        p {
            padding: 40px 20px;
            font-size: 16px;
            line-height: 1.63;

            @include mobiletablet {
                padding: 25px 0 20px;
            }
        }
    }

    &__sectionname {
        color: $text-color-orange;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        text-transform: uppercase;
    }

    &--validate-email {
            padding: 0 20px 50px;
    }
}
