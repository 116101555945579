﻿.p-searchresultpage {

    .breadcrumbs-container {
        position: relative;

        .breadcrumb {
            position: absolute;

            @media (min-width: 992px) {
                top: 160px;
                padding: 0 15px;
            }

            @media (min-width: 1200px) {
                top: 160px;
                padding: 0 8px;
            }

            @include tablet {
                top: 85px;
                padding: 0;
                margin-bottom: 0;
            }

            @include mobile {
                top: 85px;
                padding: 0 20px;
                margin-bottom: 0;
            }
        }
    }

    .title-and-lead {
        padding: 280px 20px 50px;

        @include mobiletablet {
            padding: 165px 20px 10px;
        }

        @include mobile {
            padding: 165px 20px 0px;
        }

        h1 {
            font-size: 100px;
            font-weight: bold;
            line-height: 1.12;
            letter-spacing: -3.5px;

            @include mobiletablet {
                font-size: 40px;
            }

            @include mobile {
                margin-bottom: 0;
            }
        }
    }

    .search-form {
        &__container {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            margin-top: 30px;
            margin-bottom: 120px;

            label {
                display: flex;
                align-self: center;
                font-weight: normal;

                @include mobile {
                    margin-bottom: 10px;
                }
            }

            form {
                display: flex;
                flex-flow: row;
                margin-left: 40px;

                @include mobile {
                    margin-left: 0;
                }

                .form-group {
                    margin-bottom: 0;
                }

                input {
                    border: 0;
                    border-bottom: 2px solid $border-blue;
                    border-radius: 0;
                    box-shadow: none;
                    color: $text-color-blue;
                    font-size: 30px;
                    font-weight: 500;
                    letter-spacing: normal;
                    text-align: center;
                    text-transform: uppercase;
                    height: auto;
                    padding: 0 12px;

                    @include mobiletablet {
                        font-size: 18px;
                    }
                }

                button {
                    background: transparent;
                    border: 0;
                    border-bottom: 2px solid $border-blue;

                    &:hover, &:active, &:focus {
                        outline: 0;
                        background: none;
                    }

                    &:after {
                        content: "";
                        display: inline;
                        background-image: url(/src/images/ArrowBlue.svg);
                        background-size: 15px 15px;
                        background-repeat: no-repeat;
                        padding: 2px 10px;
                    }
                }
            }
        }
    }

    #siteWrapper .bodyWrapper > .container {
        @include mobile {
            padding: 0;
        }
    }

    .search-result {
        max-width: 1400px;
        margin: 0 auto;

        .pagination {
            margin: 50px 0 80px;
        }

        h4 {
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: $text-color-blue;
            margin-bottom: 20px;
            text-transform: uppercase;

            @include mobile {
                margin-bottom: 10px;
                font-size: 12px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1.5px;
                margin-top: 0;
            }
        }


        p {
            font-size: 14px;
            line-height: 1.55;
            color: $text-color-darkgrey;

            @include mobile {
                line-height: 1.43;
            }
        }

        &__list {
            display: flex;
            flex-flow: row wrap;

            a, a:hover, a:focus {
                text-decoration: none;
                display: block;
                padding: 35px;

                @include mobile {
                    padding: 50px 35px;
                }
            }

            > div {
                width: calc(33.333% - 30px);
                margin: 0 15px 30px;
                border: 1px solid $border-pale-grey;
                box-shadow: 2px 0px 5px -1px rgba(0, 0, 0, 0.11);


                @include tablet {
                    width: calc(50% - 30px);
                }

                @include mobile {
                    width: 100%;
                    margin: 0;
                    box-shadow: none;
                    border: 0;
                    border-top: 1px solid $border-pale-grey;
                }
            }
        }
    }
}
