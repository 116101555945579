  .twocolumnrichtextblock {
    max-width: 100%;
    width: 100%;

    .twocolumnrichtext-block {
      padding-left: 20px;
      padding-right: 20px;
      max-width: 1145px;
      margin-left: auto;
      margin-right: auto;
      
      .twocolumnrichtext-content {
        display: flex;
        flex-direction: row;
        gap: 60px;
        padding-bottom: 40px;

        @include mobile {
          padding-bottom: 20px;
          flex-direction: column;
        }

        .column {
          flex: 50%;
          line-height: 26px;
          font-size: 16px;

          @include mobile {
            line-height: 30px;            
          }

          h1{
            font-size: 36px;
          }

          h2{
            font-size: 30px;
          }

          h3{
            font-size: 24px;
          }

          h4{
            font-size: 18px;
          }

          h5{
            font-size: 14px;
          }

          h6{
            font-size: 12px;
          }
          h1,h2, h3{
            margin-bottom: 10px;
            margin-top: 20px;
          }

          h4,h5,h6{
            margin-bottom: 10px;
            margin-top: 10px;
          }
          
        }
      }

      .twocolumnrichtext-img {
        text-align: center;

        img {
          max-width: 100%;
        }
      }
    }
  }
