﻿.p-bloglandingpage, .p-blogitempage {
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .child-page {
        img {
            min-width: 100%;
            max-width: 100%;
        }


        p {
            &:first-child {
                position: relative;

                .playvideo {
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    min-width: unset;
                    max-width: unset;
                }
            }
        }

        h3 {
            margin-top: 0;

            a {
                color: $text-color-blue;
                text-decoration: none;
            }
        }
    }

    .article__block {
        .column {
            width: calc(50% - 100px);
            padding: 0 50px;
            float: left;

            @include tablet {
                width: 100%;
            }

            @include mobile {
                width: 100%;
                padding: 0;
            }

            img {
                width: 100%;
                display: block;
            }

            ul {
                li {
                    font-size: 16px;
                    line-height: 1.63;
                }
            }


            ol {
                list-style: none;
                padding-left: 0;
                margin-bottom: 55px;

                @include mobile {
                    margin-bottom: 50px;
                }

                li {
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 1.5;
                    letter-spacing: 1.25px;
                    color: $text-color-grey;
                    text-transform: uppercase;
                }

                > li {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;

                    @include mobile {
                        flex-flow: column;
                        justify-content: center;
                    }

                    img {
                        width: 92px;
                        height: auto;
                        border: 1px solid $border-blue;
                        border-radius: 50%;
                        margin-right: 25px;

                        @include mobile {
                            margin-right: 0;
                        }
                    }


                    ol {
                        margin: 10px 0;

                        @include mobile {
                            margin: 20px 0 0;
                        }
                    }
                }
            }


            &--1 {
                position: relative;
                margin-bottom: 40px;
                min-height: calc(376px - 105px);

                @include tablet {
                    top: 0;
                    width: 100%;
                    margin-bottom: 40px;
                    height: calc(500px - 185px);
                }


                @include mobile {
                    top: 0;
                    width: 100%;
                    margin-bottom: 0;
                    height: calc(376px - 105px);
                }

                div {
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    position: absolute;
                    width: 100%;
                    height: calc(100% + 250px);
                    top: -250px;
                    left: -30px;

                    @media (max-width: 1300px) {
                        left: 50px;
                        width: calc(100% - 60px);
                    }

                    @media (max-width: 1200px) {
                        width: 100%;
                        left: 0;
                        height: calc(100% + 194px);
                        top: -194px;
                    }

                    @include tablet {
                        top: -185px;
                        height: calc(100% + 185px);
                    }

                    @include mobile {
                        top: -105px;
                        height: calc(100% + 105px);
                    }
                }
            }

            &--2 {
                @include tablet {
                    margin-top: 10px;
                }

                @include mobile {
                    margin-top: 10px;
                }

                h1, h2, h3, h4, h5, h6 {
                    line-height: 1.14;
                    margin-bottom: 30px;
                    font-size: 35px;

                    @include tablet {
                        font-size: 3rem;
                    }

                    @include mobile {
                        font-size: 25px;
                        line-height: 1.38;
                        text-align: center;
                    }
                }
            }

            &--3, &--4 {
                @include tablet {
                    margin-top: 40px;
                }

                h1, h2, h3, h4, h5, h6 {
                    line-height: 1.33;
                    margin-bottom: 30px;
                    font-size: 18px;


                    @include mobile {
                        text-align: left;
                        font-size: 18px;
                        font-weight: bold;
                        line-height: 1.56;
                        margin-bottom: 10px;
                        margin-top: 0;
                    }
                }

                p {
                    @include mobile {
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }

    .form-container {
        padding-bottom: 40px;

        .btn {
            padding: 10px 30px !important;
            @include button-primary($text-color-blue, $background-white);

            &:not(.EPiServerForms) {
                margin-top: 20px;
            }
        }

        .EPiServerForms {
            margin: 0;
            padding: 0;

            & > * {
                max-width: unset !important;
            }

            .Form__Element .Form__Element__Caption {
                margin: 0;
            }

            button {
                margin: 0 auto 1.5rem;
                display: flex;
            }
        }

        .article__content {
            margin: 0 auto !important;
            max-width: 600px;

            h1, h2, h3, h4, h5, h6 {
                color: $text-color-blue;
                margin-top: 0;
                margin-bottom: 10px;
            }

            h1, h2, h3 {
                line-height: 1.29;
            }

            h4, h5, h6 {
                line-height: 1.29;
            }

            .article__author {
                img {
                    display: inline-block;
                    width: 50px;
                    border-radius: 999em;
                }

                span {
                    margin-left: 5px;
                    font-weight: bold;
                }
            }
        }

        .article__block {
            padding: 0 !important;
            margin: 0 auto !important;
            max-width: 600px;
        }
    }

    .content-shading-invertedmode {
        .form-container {
            .btn {
                @include button-primary($background-white, $text-color-blue);
                color: $text-color-blue;
            }

            .EPiServerForms {
                background: transparent;

                .Form__Element .Form__Element__Caption {
                    color: #fff;
                }

                .FormChoice label {
                    color: #fff;
                }

                button {
                    border-color: #fff !important;
                    border: 2px solid #fff;
                }
            }
        }
    }
}

.p-bloglandingpage {
    .highlight-item {

        img {
            min-width: 100%;
            max-width: 100%;
        }

        &-video {
            position: relative;

            .playvideo {
                position: absolute;
                width: 50px;
                height: 50px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                min-width: unset;
                max-width: unset;
            }
        }

        &__container {
            padding-top: 100px;
            padding-bottom: 40px;

            @include mobiletablet {
                padding-top: 20px;
            }

            h3 {
                color: $text-color-blue;
            }

            p:not(.text-upper) {
                padding-top: 15px;
            }

            .btn {
                margin-top: 15px;
                border-radius: 999em;
                background: $text-color-blue;
                padding: 15px 80px;
            }
        }
    }

    .article {
        padding-top: 40px;

        &__content {
            margin: 0 auto 50px;
            max-width: 900px;

            p {
                line-height: 1.71;
                margin: 0 0 30px;

                @include mobile {
                    margin: 0 0 25px;
                }
            }

            h1, h2, h3, h4, h5, h6 {
                color: $text-color-blue;
                margin-bottom: 30px;

                @include mobile {
                    margin-bottom: 25px;
                    margin-top: 50px;
                }
            }

            h1, h2, h3 {
                line-height: 1.29;

                @include mobile {
                    font-size: 25px;
                    font-weight: bold;
                    line-height: 1.38;
                }
            }

            h4, h5, h6 {
                line-height: 1.29;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            blockquote {
                font-size: 35px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: $text-color-orange;
                max-width: 850px;
                margin: 0 auto;

                @include mobile {
                    font-size: 25px;
                    line-height: 1.38;
                }
            }
        }

        &__block {
            margin: 0 auto;
            padding: 40px 0 60px;

            &:first-child {
                padding-top: 0;
            }

            @include mobile {
                padding: 0px 0 40px;
            }

            p {
                line-height: 1.71;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            .caption {
                display: block;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.83;
                margin-top: 5px;
            }

            blockquote {
                font-size: 35px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: $text-color-orange;
                max-width: 850px;
                margin: 0 auto;

                @include mobile {
                    font-size: 25px;
                    line-height: 1.38;
                }

                + .author {
                    color: $text-color-grey;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    margin-top: 10px;
                    text-align: center;
                    display: block;
                }
            }

            .image-block {
                display: flex;
                flex-flow: row;

                > div {
                    width: 100%;
                }

                img {
                    max-width: 100%;
                }

                &.two {
                    > div {
                        width: calc(50% - 15px);
                        margin-right: 15px;

                        &:last-child {
                            margin-left: 15px;
                            margin-right: 0;
                        }

                        @include mobile {
                            width: calc(50% - 5px);
                            margin-right: 5px;

                            &:last-child {
                                margin-left: 5px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .video-block {
                height: 65vh;
                max-height: 650px;
                margin-bottom: 20px;

                .embed-responsive {
                    height: 100%;
                }

                .video-with-overlay__video {
                    z-index: 0;
                }
            }
        }
    }
}

.p-blogitempage {
    .related {
        &-articles {
            padding-top: 40px;
        }

        &-content {
            padding-bottom: 40px;
        }
    }

    .article {
        padding-top: 40px;
        position: relative;

        &__share {
            background-image: url(/Static/img/social-share-icons.png);
            background-repeat: no-repeat;
            display: inline-block;
            width: 45px;
            height: 45px;

            &__container {
                position: absolute;
                top: 0;
                right: 50px;
                width: 45px;
            }

            &__email {
                background-position: 0 0;
            }

            &__linkedin {
                background-position: 0 -155px;
                margin-top: 5px;
            }
        }

        .title-and-lead {
            &__container {
                @include mobile {
                    padding: 15px 0 20px;
                }

                p {
                    font-weight: 500;

                    @include mobile {
                        font-weight: bold;
                        padding: 15px 0 0px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__content {
            margin: 0 auto 50px;
            max-width: 900px;

            p {
                line-height: 1.71;
                margin: 0 0 30px;

                @include mobile {
                    margin: 0 0 25px;
                }
            }

            h1, h2, h3, h4, h5, h6 {
                color: $text-color-blue;
                margin-bottom: 30px;

                @include mobile {
                    margin-bottom: 25px;
                    margin-top: 50px;
                }
            }

            h1, h2, h3 {
                line-height: 1.29;

                @include mobile {
                    font-size: 25px;
                    font-weight: bold;
                    line-height: 1.38;
                }
            }

            h4, h5, h6 {
                line-height: 1.29;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            blockquote {
                font-size: 35px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: $text-color-orange;
                max-width: 850px;
                margin: 0 auto;

                @include mobile {
                    font-size: 25px;
                    line-height: 1.38;
                }
            }
        }

        &__block {
            margin: 0 auto;
            padding: 40px 0 60px;

            @include mobile {
                padding: 0px 0 40px;
            }

            p {
                line-height: 1.71;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            .caption {
                display: block;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.83;
                margin-top: 5px;
            }

            blockquote {
                font-size: 30px;
                font-style: normal;
                font-stretch: normal;
                font-weight: bold;
                line-height: 1.29;
                letter-spacing: normal;
                color: $text-color-blue;
                margin: 0 auto;
                text-align: left;

                &:before {
                    color: $text-color-blue;
                    content: open-quote;
                    line-height: 0.1em;
                    display: inline-block;
                    position: relative;
                    left: 5px;
                    top: 0;
                    font-size: 30px;
                }

                &:after {
                    color: $text-color-blue;
                    content: close-quote;
                    line-height: 0.1em;
                    display: inline-block;
                    position: relative;
                    right: 5px;
                    bottom: 0;
                    font-size: 30px;
                }


                @include mobile {
                    font-size: 25px;
                    line-height: 1.38;
                }

                + .author {
                    color: $text-color-blue;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    margin-top: 20px;
                    display: block;

                    &:before {
                        color: $text-color-blue;
                        content: "—";
                        line-height: 0.1em;
                        margin-right: 0.25em;
                    }
                }
            }

            .image-block {
                display: flex;
                flex-flow: row;

                > div {
                    width: 100%;
                }

                &.two {
                    > div {
                        width: calc(50% - 15px);
                        margin-right: 15px;

                        &:last-child {
                            margin-left: 15px;
                            margin-right: 0;
                        }

                        @include mobile {
                            width: calc(50% - 5px);
                            margin-right: 5px;

                            &:last-child {
                                margin-left: 5px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .video-block {
                height: 65vh;
                max-height: 650px;
                margin-bottom: 20px;

                .embed-responsive {
                    height: 100%;
                }

                .video-with-overlay__video {
                    z-index: 0;
                }
            }
        }
    }
}
