﻿
.titleimagerichtextblock{
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 60px;
    
    .container{
        width: 100%;
    }

    .titleimagerichtext-block {
        text-align: left;
        max-width: 1145px;
        margin-left: auto;
        margin-right: auto;

        .titleimagerichtext-block__heading{
            text-align: center;
            margin-bottom: 40px;
            color: $text-color-blue;
            
            @include mobiletablet {
                font-size: 25px;
                line-height: 25px;
                text-align: center;
            }
        }

        .titleimagerichtext-block__text{
            font-size: 16px;
            line-height: 26px;
            padding-bottom: 60px;

            @include mobile{
                padding: 0 8px;
            }

            ul{
                padding: 0;
                list-style-position: inside;

            }
            
            &.titleimagerichtext-block__two-columns{
                margin-bottom: 40px;
            }

        }
        
        .titleleadandimage-block__img{
           text-align: center;
        }

        &__centered {
            text-align: center;

            div.titleimagerichtext-block__two-columns {
                text-align: left;
                margin-left: auto;
                margin-right: auto;
            }

        }

        &__two-columns {
            @include desktop{
                -moz-column-count: 2;
                column-count: 2;
                -moz-column-gap: 95px;
                column-gap: 95px;
                max-width: 1090px;
            }
        }

        .titleleadandimage-block__img{
            img{
                max-width: 100%;
                max-height: 65vh;
            }
        }
    }
}

