﻿.p-contactuspage {
    .breadcrumbs-container {
        position: relative;

        .breadcrumb {
            position: absolute;

            @media (min-width: 992px) {
                top: 160px;
                padding: 0 15px;
            }

            @media (min-width: 1200px) {
                top: 160px;
                padding: 0 8px;
            }

            @include mobiletablet {
                top: 85px;
                padding: 0;
                margin-bottom: 0;
            }
        }
    }

    .title-and-lead {
        padding: 280px 20px 50px;

        @include mobiletablet {
            padding: 165px 20px 10px;
        }

        @include mobile {
            padding: 165px 30px 0px;
        }

        h1 {
            font-size: 100px;
            font-weight: bold;
            line-height: 1.12;
            letter-spacing: -3.5px;

            @include mobiletablet {
                font-size: 40px;
            }
        }

        &__sectionname {
            margin-bottom: 27px;
        }
    }

    .contact {
        &__locations {
            margin: 70px auto 0;
            position: relative;

            @include mobiletablet {
                margin: 50px auto 0;
                padding: 0;
            }

            .nav {
                display: flex;
                flex-flow: row;
                margin: 0 65px;
                height: 65px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
                background-color: $background-white;
                position: relative;
                z-index: 2;

                @include tablet {
                    margin: 0 20px;
                }

                @include mobile {
                    margin: 0;
                    width: 100%;
                    justify-content: flex-end;
                }

                &.open {
                    .dropdown-backdrop {
                        display: none !important;
                    }

                    @include mobile {
                        ul {
                            position: absolute;
                            width: 40%;
                            background: $background-white;
                            z-index: 22;
                            top: 65px;
                            left: 0;
                            display: block;
                            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.11);

                            li {
                                padding: 0px 20px;
                                display: flex;

                                .active {
                                    display: none;
                                }

                                a {
                                    margin: 15px 0;
                                    width: 100%;
                                    text-align: left;
                                    white-space: normal;
                                    white-space: unset;
                                }
                            }

                            @media (max-width: 320px) {
                                width: 50%;
                            }
                        }
                    }
                }
            }

            ul {
                display: flex;
                flex-flow: row;
                list-style: none;
                margin-block-start: 0;
                padding: 0 20px 0 0;
                margin-bottom: 0;

                @media (max-width: 1599px) {
                    padding: 0;
                }

                @include mobiletablet {
                    margin: 0;
                }

                @include mobile {
                    display: none;
                }


                @media (max-width: 320px) {
                    width: 50%;
                }


                li {
                    padding: 25px 35px;

                    @include mobiletablet {
                        padding: 25px 20px;
                    }


                    a {
                        font-size: 14px;
                        font-weight: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        text-align: center;
                        color: $text-color-darkgrey;
                        white-space: nowrap;

                        &:hover, &:focus, &:active {
                            text-decoration: none;
                            color: $text-color-blue;
                        }

                        &.active {
                            color: $text-color-blue;
                            font-weight: bold;
                        }
                    }
                }
            }

            .input {
                width: calc(50% + 50px);
                border-left: 1px solid $border-pale-grey;
                display: flex;
                flex-flow: row;
                padding: 10px 25px;
                align-items: center;

                @include mobiletablet {
                    width: 60%;
                }

                @include mobile {
                    display: flex;
                }

                @media (max-width: 320px) {
                    width: 50%;
                }

                input {
                    width: calc(100% - 47px);
                    border: 0;
                    margin-top: 3px;
                    color: $text-color-darkgrey;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;


                    &:hover, &:focus, &:active {
                        outline: 0;
                    }

                    &::-webkit-input-placeholder {
                        color: $text-color-darkgrey;
                        opacity: 0.39;
                        font-size: 14px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                    }

                    &::-moz-placeholder {
                        color: $text-color-darkgrey;
                        opacity: 0.39;
                        font-size: 14px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                    }

                    &:-ms-input-placeholder {
                        color: $text-color-darkgrey;
                        opacity: 0.39;
                        font-size: 14px;
                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                    }

                    &:-moz-placeholder {
                        color: p$text-color-darkgrey;
                        opacity: 0.39;
                    }
                }

                button {
                    width: 22px;
                    height: 22px;
                    background: transparent;
                    border: 0;
                    padding: 0;
                    margin: 5px 25px 0 0;

                    @include mobile {
                        margin: 5px 15px 0 0;
                    }

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    &:hover, &:focus, &:active {
                        outline: 0;
                    }

                    &.mobile {
                        display: none;

                        @include mobile {
                            display: block;
                            margin: 0;
                            width: 16px;
                            height: 16px;
                        }

                        @media (max-width: 320px) {
                            display: none;
                        }
                    }
                }
            }

            &-container {
                background: linear-gradient(180deg, white 35%, white 65%, #eff1f5 0%);
            }

            .active--mob {
                display: none;

                @include mobile {
                    display: block;
                    position: absolute;
                    z-index: 2;
                    padding: 0;
                    background: transparent;
                    border: 0;
                    color: $text-color-blue;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    white-space: nowrap;
                    box-shadow: none;
                    width: 40%;
                    height: 65px;
                    text-align: left;
                    padding-left: 20px;
                    top: 0;
                    left: 0;

                    &:hover, &:focus, &:active {
                        outline: 0;
                        border-radius: 0;
                        box-shadow: none;
                        background: none;
                        border: 0;
                        color: $text-color-blue;
                    }

                    &:after {
                        content: "\f078";
                        font-family: FontAwesome;
                        color: $text-color-blue;
                        display: inline;
                        right: 15px;
                        position: absolute;
                    }
                }

                @media (max-width: 320px) {
                    width: 50%;
                }
            }
        }

        &__content {
            background: $background-pale-grey;
        }

        &__navbar {
            display: flex;
            flex-flow: row;
            justify-content: center;
            padding: 50px 0 60px;
            border-bottom: 0;

            @include tablet {
                padding: 50px 0 35px;
            }

            @include mobile {
                padding: 50px 28px 50px;
            }

            li {
                z-index: 1;
                position: relative;

                @include mobile {
                    width: 50%;
                }

                a {
                    @include button-default($text-color-blue);
                    border-radius: 0;
                    margin-right: 0;
                    padding: 8px 35px;
                    text-align: center;



                    @include mobile {
                        padding: 8px;
                    }

                    @media (max-width: 450px) {
                        display: flex;
                        align-items: center;
                        height: 56px;
                    }
                }



                &.active {
                    a {
                        @include button-primary($background-blue, $text-color-white);
                        border-radius: 0;
                        padding: 8px 35px;

                        @include mobile {
                            padding: 8px;
                        }
                    }
                }



                &:first-child {
                    a {
                        border-radius: 30px 0 0 30px;
                        border-right: 0;
                    }

                    &.active {

                        a {
                            border-radius: 30px 0 0 30px;
                        }
                    }
                }

                &:last-child {
                    a {
                        border-radius: 0 30px 30px 0;
                        background: transparent;
                        padding: 8px 29px;
                        border-left: 0;

                        &:hover, &:active, &:focus {
                            background: transparent;
                        }

                        @include mobile {
                            padding: 8px;
                        }
                    }

                    &.active {

                        a {
                            @include button-primary($background-blue, $text-color-white);
                            border-radius: 0 30px 30px 0;
                            padding: 8px 29px;

                            @include mobile {
                                padding: 8px;
                            }
                        }
                    }
                }
            }

            &.map {
                li {
                    a {
                        background: $background-white;

                        &:hover, &:active, &:focus {
                            background: $background-white;
                        }
                    }
                }

                li.active {
                    a {
                        background: $background-blue;
                        color: $text-color-white;
                    }
                }
            }
        }

        &__tabs {
            #officemap {
                margin-top: -170px;
                z-index: 0;

                @include tablet {
                    margin-top: -145px;
                }

                @include mobile {
                    margin-top: -137px;
                }

                > div {
                    background-color: $background-white !important;
                }
            }

            #officecontactform {
                max-width: calc(674px + 56px);
                margin: 0 auto;

                > form {
                    padding: 15px 28px 65px;

                    > p {
                        font-size: 12px;
                        font-weight: normal;
                        font-style: normal;
                        line-height: 1.58;
                        color: $text-color-darkgrey;
                    }

                    .btn-group {
                        display: flex;
                        justify-content: flex-end;
                    }

                    .btn {
                        @include button-primary($background-blue, $text-color-white);
                        margin: 30px 0;
                        text-align: center;
                        padding: 8px 80px;

                        @include mobile {
                            width: 100%;
                        }
                    }

                    .form-group {
                        margin: 30px 0 0;

                        label {
                            text-transform: uppercase;
                            font-size: 10px;
                            font-weight: 500;
                            color: $text-color-blue;
                            margin-bottom: 5px;
                            width: 100%;
                        }

                        input, select, textarea {
                            border-radius: 0;
                            border: 1px solid #ececee;
                            height: 45px;
                            width: 100%;
                            max-width: 100%;
                            min-width: 100%;
                            font-size: 12px;
                            font-weight: normal;
                            line-height: normal;
                            color: $text-color-grey;
                            padding: 15px 13px;

                            &:focus {
                                outline: 0;
                                box-shadow: none;
                            }

                            &::-webkit-input-placeholder {
                                opacity: 0.36;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: normal;
                                color: $text-color-grey;
                            }

                            &::-moz-placeholder {
                                opacity: 0.36;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: normal;
                                color: $text-color-grey;
                            }

                            &:-ms-input-placeholder {
                                opacity: 0.36;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: normal;
                                color: $text-color-grey;
                            }

                            &:-moz-placeholder {
                                opacity: 0.36;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: normal;
                                color: $text-color-grey;
                            }
                        }

                        textarea {
                            height: 100%;
                            min-height: 127px;
                        }

                        select {
                            cursor: pointer;
                        }


                        span {
                            font-size: 10px;
                            color: $text-color-grey;
                        }
                    }
                }
            }

            .tab-pane {
                @include tablet {
                    padding: 0;
                }

                > ul {
                    display: flex;
                    flex-flow: row wrap;
                    list-style: none;
                    padding-left: 0;
                    margin: 0 50px 110px;


                    @include mobiletablet {
                        margin: 0px 8px 25px;
                    }

                    li {
                        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                        background: $background-white;
                        padding: 30px 15px 30px 30px;
                        margin: 15px;
                        width: calc(25% - 30px);
                        cursor: pointer;
                        display: flex;
                        flex-flow: row;
                        justify-content: space-between;
                        align-items: center;

                        @include tablet {
                            width: calc(50% - 30px);
                        }

                        @include mobile {
                            width: 100%;
                            margin: 0 0 25px;
                        }

                        h6 {
                            font-size: 16px;
                            font-weight: bold;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            display: inline;
                            color: $text-color-blue;
                        }

                        span {
                            font-size: 16px;
                            font-weight: normal;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: right;
                            display: inline;
                            color: $text-color-blue;
                        }
                    }
                }
            }
        }
    }

    .modal-container {
        padding: 36px 0 0;

        > div {

            @include mobiletablet {
                height: 100%;
            }
        }


        > div > .row {
            border-top: 1px solid $border-pale-grey;
            margin: 0;
            display: flex;
            flex-flow: row wrap;
            height: 100%;
            max-height: 85vh;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                width: 5px;
                background: $background-pale-grey;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba($background-blue,0.3);
            }

            &::-webkit-scrollbar-thumb {
                background-color: $border-blue;
                outline: 1px solid $border-blue;
            }

            @include mobiletablet {
                max-height: calc(100vh - 135px);
                height: 100%;
            }

            .col-xs-4 {
                background: $background-pale-grey;
                display: block;
                padding: 38px 35px;
                width: 40%;
                height: auto !important;


                p {
                    font-size: 12px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.58;
                    color: $text-color-darkgrey;
                    display: block;
                    max-width: 500px;
                }

                form {
                    .btn {
                        @include button-primary($background-blue, $text-color-white);
                        margin: 30px;
                        text-align: center;
                        width: calc(100% - 60px);
                    }
                }

                .form-group {
                    margin: 30px 0 0;

                    label {
                        text-transform: uppercase;
                        font-size: 10px;
                        font-weight: 500;
                        color: $text-color-blue;
                        margin-bottom: 5px;
                        width: 100%;
                    }

                    input, select, textarea {
                        border-radius: 0;
                        border: 1px solid #ececee;
                        height: 45px;
                        width: 100%;
                        max-width: 100%;
                        min-width: 100%;
                        font-size: 12px;
                        font-weight: normal;
                        line-height: normal;
                        color: $text-color-grey;
                        padding: 15px 13px;

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }

                        &::-webkit-input-placeholder {
                            opacity: 0.36;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: normal;
                            color: $text-color-grey;
                        }

                        &::-moz-placeholder {
                            opacity: 0.36;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: normal;
                            color: $text-color-grey;
                        }

                        &:-ms-input-placeholder {
                            opacity: 0.36;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: normal;
                            color: $text-color-grey;
                        }

                        &:-moz-placeholder {
                            opacity: 0.36;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: normal;
                            color: $text-color-grey;
                        }
                    }

                    textarea {
                        height: 100%;
                        min-height: 127px;
                    }


                    span {
                        font-size: 10px;
                        color: $text-color-grey;
                    }
                }

                .card {
                    .card-header {
                        display: none;

                        @include mobiletablet {
                            display: block;

                            h5 {
                                margin: 0;

                                button {
                                    padding: 0;
                                    width: 100%;
                                    text-align: left;
                                    font-size: 14px;
                                    font-weight: 500;
                                    font-style: normal;
                                    font-stretch: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    text-transform: uppercase;
                                    color: $text-color-blue;
                                    padding: 24px 20px;

                                    &:hover, &:active, &:focus {
                                        outline: 0;
                                        text-decoration: none;
                                    }

                                    &:after {
                                        font-family: 'Glyphicons Halflings';
                                        content: "\e114";
                                        float: right;
                                        transition: all 0.5s;
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                    }
                }



                @include mobiletablet {
                    position: absolute;
                    left: 0;
                    width: 100%;
                    overflow: hidden;
                    bottom: 0px;
                    height: 60px !important;
                    padding: 0;

                    .card-body {
                        display: none;
                    }

                    &.in {
                        height: 100% !important;
                        padding: 24px 20px;
                        transition-property: height, visibility;
                        transition-duration: 0.35s;
                        transition-timing-function: ease;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;

                        &::-webkit-scrollbar {
                            width: 5px;
                            background: $background-pale-grey;
                        }

                        &::-webkit-scrollbar-track {
                            -webkit-box-shadow: inset 0 0 6px rgba($background-blue,0.3);
                        }

                        &::-webkit-scrollbar-thumb {
                            background-color: $border-blue;
                            outline: 1px solid $border-blue;
                        }

                        .card-body {
                            display: block;
                            margin-top: 10px;
                            padding-right: 43px;
                        }

                        .card-header {
                            button {
                                padding: 12px 0 24px 0 !important;

                                &:after {
                                    transform: rotate(0deg) !important;
                                }
                            }
                        }
                    }
                }
            }

            .col-xs-8 {
                padding: 58px 38px 40px;
                width: 60%;

                @include mobiletablet {
                    width: 100%;
                    padding: 25px 20px 0;
                    height: calc(100vh - 175px);
                }


                p {
                    font-size: 14px;
                    line-height: 1.57;
                    color: $text-color-darkgrey;

                    span {
                        font-size: 14px;
                        line-height: 1.57;
                        color: $text-color-darkgrey;
                    }

                    @include mobile {
                        margin: 0;
                    }
                }

                strong {
                    color: $text-color-blue;
                    line-height: normal;

                    @include mobile {
                        margin-bottom: 8px;
                        display: block;
                    }
                }

                a, a:hover, a:active, a:focus {
                    margin: 20px 0 0;
                    font-size: 14px;
                    font-weight: normal; 
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.57;
                    color: $text-color-orange;
                    display: block;
                    word-break: break-all;
                }

                .col-xs-12 {
                    margin-bottom: 30px;

                    @include mobile {
                        margin-bottom: 25px;
                    }
                }

                .address {
                    &--2 {


                        p {
                            font-size: 14px;
                            line-height: 1.57;
                            color: #000000;
                            margin-bottom: 5px;

                            span {
                                font-size: 14px;
                                line-height: 1.57;
                                color: #000000;
                            }
                        }

                        a {
                            margin: 5px 0 0;

                            @include mobile {
                                margin: 0;
                            }
                        }

                        @include mobile {
                            margin-bottom: 0 !important;
                        }
                    }

                    @include mobile {
                        width: 100%;
                        margin-bottom: 25px;
                    }

                    .find {
                        @include mobile {
                            display: none;
                        }

                        &--mobile {
                            display: none;

                            @include mobile {
                                display: block;
                                margin-top: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
