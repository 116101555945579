.locationlistingblock{

    .locationBlock{
        &__title{
            h2{
                text-align: center;
                font-weight: 700;
                font-size: 44px;
                line-height: 54px;
                color:#274476;
                margin: 65px 0 97px;
                @include mobiletablet{
                    margin: 30px 0px;
                }
            }
        }
    
        &__section{
            display: flex;
            gap: 19px;
            padding: 32px 0;

            @include mobiletablet{
                flex-direction: column;
                margin: 42px 0;
                padding: 0;
             &::after{
                box-sizing: border-box;
                border-bottom: 1px solid #eff1f5;
                display: block;
                content: "";
             }

            }
        }
        &__office-title{
            width: 25vw;
            font-size: 36px;
            font-weight: 700;
            line-height: 43.96px;
            color:$text-color-blue;
            h3{
                margin-top: 0;
                font-size: 36px;
            }
            @include mobiletablet{
                width: 100%;
            }
        }
    
        &__office-container{
            display: grid;
            grid-gap: 90px;
            grid-template-rows: auto auto;
            grid-template-columns: auto auto;
            @include mobiletablet{
                grid-template-rows: auto;
                grid-template-columns: auto;
                grid-gap: 20px;
            }
        }
        &__office-item{
            max-width: 300px;
        }
        &__office-header{
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            color: #274476;
        }
        &__text-section{
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            p{
                max-width: 300px;
            }
        }
        &__links{
            display: flex;
            flex-direction: column;
    
            a, p{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
                margin: 9px 0px;
                color: $text-color-orange;
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                text-decoration: none;
                cursor: pointer;
            }
        }
        &__modal{
            border: unset;
            padding: 0;
            @include mobiletablet{
                width: 100%;
            }
            .flex-row{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #eff1f5;;
                padding: 26px 38px;
                &__modal-title{
                    font-size: 14px;
                    font-weight: 700;
                    color: $text-color-blue;
                    text-transform: uppercase;
                    padding: 0;
                    margin: 0;
                   } 
                &__modal-close{  
                    display: block;
                    border: none !important;
                    padding: 0;
                    width: 22.6px;
                    height: 22.6px;
                    background: none;

                    &::after, &::before{
                        display: inline-block;
                        content: "";
                        height: 22.6px;
                        width: 2px;
                        background-color: $background-blue;
                    }
                    &::before{
                        transform: rotate(45deg);
                    }     
                    &::after{
                        transform: rotate(-45deg);
                    }
                    &:focus {
                        outline: 0;
                      }
                }
            }
            .content-section{
                width: auto;
                &__flex-container{
                    display: flex;
                    @include mobiletablet{
                        flex-direction: column;
                    }
                }
                &__left{
                    width: 25vw;
                    padding: 58px 38px 40px;
                    p{
                        font-size: 14px;
                        line-height: 1.57;
                        color: #4a4a4a;
                    }
                    @include mobiletablet{
                        width: 100%;
                    }
                }
                &__title{
                    color: #274476 !important;
                    font-weight: bold;
                }
                &__map-link{
                    color: $text-color-orange;
                    display: flex;
                    gap: 6px;
                    align-items: center;
                }
                &__right{
                    width: 25vw;
                    background-color: $background-pale-grey;
                    @include mobiletablet{
                        width: 100%;
                    }
                    form{
                        margin:  0;
                        padding: 0;
                    }
                    .EPiServerForms{
                        background-color: unset;
                        margin: 0;
                        padding: 38px 35px;
                    }
                    .Form__MainBody{
                        margin: 0;
                        @include mobiletablet{
                            margin: 0 auto;
                        }
                    }
                    .Form__Description{
                        font-size: 12px;
                        font-weight: 400;
                        margin-bottom: 15px;
                    }
                    .EPiServerForms label, .EPiServerForms .Form__Element__Caption{
                        color:$text-color-blue;
                        font-weight: $font-header-weight;
                        font-size: 12px;
                        font-family: inherit;
                        margin: 0;
                    }
                    .Form__Element {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                    }
                    input[type="text"],input[type="email"],textarea,select{
                        border: 1px solid #EEF1F4;
                        max-height: 100px;
                    }
                    .EPiServerForms button{
                        display: block;
                        margin: 30px;
                        width: 100%;
                        background-color: $background-blue !important;
                        width: calc(100% - 60px);
                        padding: 10px 30px !important;
                    }

                    select, option{
                        background-color: $background-white;
                        color: $text-color-darker-grey;
                    }
                    select{
                        padding: 10px;
                        font-size: 14px;
                        font-family: inherit;
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background-image: url('/src/images/chevron-down-small.svg'); 
                        background-repeat: no-repeat; 
                        background-position: right 10px center; 
                        background-size: 25px; 
                    }
                }   
            }
        }
     
    }
    .arrow {    
        display: block;
        width: 16px;
        height: 16px;
        background-image: url(/src/images/ArrowOrange.svg);
    }
}
