﻿.p-globalstartpage {
    @include mobiletablet {


        @media screen and (-ms-high-contrast: none) {
            overflow-x: hidden;
        }
    }
}
.video-with-overlay {

    > div {
        width: 100%;
        height: 100vh;
        left: 0;
    }

    &--editmode {
        > div {
            height: 600px;
            max-height: 600px;
        }
    }


    &__video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: fixed;
        left: 0px;
        top: 0px;
        z-index: -1;

        @supports (-ms-ime-align:auto) {
            object-fit: none;
            height: auto !important;
            width: auto !important;
            min-width: 100vw;
            min-height: 100vh;
            position: relative !important;
        }

        @supports (-ms-ime-align:auto) and (-webkit-text-stroke:initial) {
            object-fit: none;
            height: auto !important;
            width: auto !important;
            min-width: 100vw;
            min-height: 100vh;
            position: relative !important;
        }

        @media screen and (-ms-high-contrast: none) {
            object-fit: none;
            height: auto !important;
            width: auto !important;
            min-width: 100vw;
            min-height: 100vh;
            position: relative !important;
        }
    }

    .swiper-container {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
        height: 100vh;

        .swiper-slide {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }

        .swiper-button-next, .swiper-button-prev {
            &:focus-visible {
                outline: none;
            }
        }
    }

    &__container { //den rutan med text som byts ut
        position: absolute;
        max-width: 1255px;
        top: 50%;
        left: 0;
        margin-left: 170px;
        transform: translate(0%, -50%);
        z-index: 2;

        @media (max-width: 1270px) {
            margin-left: 70px;
        }

        @include mobiletablet {
            text-align: center;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-left: 0;
            padding: 0 30px;
            width: 100%;
        }
    }

    &__heading { //texten som byts ut
        margin: 0;
        font-size: 75px;
        line-height: 1.24;
        letter-spacing: -2.62px;

        @media (max-width: 1400px) {
            font-size: 60px;
        }

        @media (max-width: 1270px) {
            font-size: 50px;
        }

        @include tablet {
            font-size: 3rem;
            letter-spacing: -0.79px;
        }

        @include mobile {
            font-size: 22px;
            letter-spacing: -0.79px;
            margin-bottom: 12px;
        }

        @media (max-width: 376px) {
            font-size: 18px;
        }
    }

    &__text {
        text-transform: uppercase;
        color: $text-color-white;
        text-shadow: 0 0 40px rgba(0, 0, 0, 0.17);
        font-size: 75px;
        line-height: 1.24;
        line-height: 1.24 !important;
        letter-spacing: -2.62px !important;
        font-weight: normal !important;

        @media (max-width: 1400px) {
            font-size: 60px;
        }

        @media (max-width: 1270px) {
            font-size: 50px;
        }

        @include tablet {
            font-size: 3rem;
            letter-spacing: -0.79px;
        }

        @include mobile {
            font-size: 22px;
            letter-spacing: -0.79px;
        }

        @media (max-width: 376px) {
            font-size: 18px;
        }
    }

    &__buttons {
        margin-top: 35px;
        display: flex;
        flex-flow: row wrap;

        @include mobiletablet {
            margin-top: 15px;
        }

        .btn {
            margin: 5px 5px 5px 0;
            height: 38px;
        }

        .btn-primary {
            @include button-default($text-color-white);
            font-weight: 500;

            @include mobiletablet {
                @include button-primary($background-white,$text-color-blue);
                font-weight: 500;
                margin: 0 auto;
            }
        }

        .btn-default, .btn-default:hover, .btn-default:focus {
            color: $text-color-white;
            background: transparent;
            border: 0;
            font-size: 14px;
            font-weight: 500;

            span {
                margin-right: 5px;
                font-size: 20px;
                vertical-align: bottom;
            }

            @include mobiletablet {
                display: none;
            }
        }
    }


    &__news {
        bottom: 35px;
        right: 40px;
        position: absolute;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.58);
        border: 1px solid $dark-blue;
        background-color: rgba($dark-blue, 0.55);
        padding: 25px 20px;
        max-width: 555px;
        z-index: 1000;

        @include mobile {
            right: 0;
            left: 0;
            margin: 0 auto;
            display: block;
            width: 90%;
            bottom: 15px;
        }

        @include mobiletablet {
            display: none;
        }

        &-link {
            font-size: 11px;
            font-weight: bold;
            color: $text-color-white;
            margin-bottom: 5px;
            display: flex;
            align-items: flex-end;
            text-transform: uppercase;

            &:hover, &focus {
                text-decoration: underline;
                color: $text-color-white;
            }
        }

        p {
            font-size: 11px;
            color: $text-color-white;
            margin-bottom: 0;
        }

        &-heading {
            font-weight: bold;
            margin-bottom: 5px !important;
            font-size: 16px !important;
        }
    }


    &__blog {
        right: 40px;
        position: absolute;
        bottom: 180px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.58);
        border: 1px solid $dark-green;
        background-color: rgba($dark-green, 0.55);
        padding: 25px 20px;
        max-width: 555px;
        min-width: 555px;
        z-index: 1000;

        @include mobile {
            right: 0;
            left: 0;
            margin: 0 auto;
            display: block;
            width: 90%;
            bottom: 15px;
        }

        @include mobiletablet {
            display: none;
        }

        p {
            font-size: 11px;
            color: $text-color-white;
            margin-bottom: 0;
        }

        &-heading {
            font-weight: bold;
            margin-bottom: 5px !important;
            font-size: 16px !important;
        }
    }

    &.overlay {
        &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0.23;
            background-color: #000000
        }
    }
}

.mosaic-block-4 {
    padding: 0 28px 135px 28px;

    @include mobile {
        padding: 0 20px 35px 20px;
    }

    &__container {
        display: flex;
        flex-flow: row;

        @include mobile {
            flex-flow: column;
        }
    }

    &__item {
        display: block;
        position: relative;


        img {
            object-fit: fill;
            height: 100%;
            width: 100%;
        }

        &:hover {

            &:before {
                content: "";
                position: absolute;
                display: block;
                border-left: 11px solid $border-blue;
                top: 0;
                left: 0;
                height: 100%;

                @include mobiletablet {
                    border-left: 5px solid $border-blue;
                }
            }
        }

        > div {
            position: absolute;
            bottom: 0;

            h4 {
                text-shadow: 0 0 20px rgba(0, 0, 0, 0.33);
                font-size: 30px;
                line-height: 1.13;
                color: $text-color-white;

                @include mobiletablet {
                    font-size: 25px;
                    line-height: 1.36;
                }

                @media (min-width: 768px) and (max-width: 1155px) {
                    font-size: 18px;
                    line-height: 1;
                }
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: $text-color-white;
                display: flex;
                align-items: center;
                margin-bottom: 0;

                &:before {
                    content: "";
                    border-bottom: 2px solid $border-white;
                    display: block;
                    margin-right: 12px;
                    width: 55px;
                }

                @include mobiletablet {
                    font-size: 12px;
                }
            }
        }

        &--aside {
            width: 40%;

            @include mobile {
                width: 100%;
                height: 50%;
            }

            > div {
                margin: 0 35px 35px;
                max-width: 314px;

                @include mobile {
                    margin: 0 17px 17px;
                    max-width: auto;
                    max-width: unset;

                    h4 {
                        margin-bottom: 15px;
                    }
                }

                @media (min-width: 768px) and (max-width: 1155px) {
                    margin: 0 35px 5px;
                }
            }
        }

        &--landscape {
            height: 65%;
            margin-bottom: 10px;

            @include mobile {
                height: 50%;
                margin-bottom: 15px;
            }

            > div {
                margin: 0 25px 25px;
                max-width: 335px;

                @include mobile {
                    margin: 0 17px 17px;
                    max-width: auto;
                    max-width: unset;

                    h4 {
                        margin-bottom: 15px;
                    }
                }

                @media (min-width: 768px) and (max-width: 1155px) {
                    margin: 0 25px 5px;
                }
            }
        }

        &--small {
            > div {
                margin: 0 25px 20px;
                max-width: 335px;

                @include mobile {
                    margin: 0 17px 17px;
                    max-width: auto;
                    max-width: unset;

                    h4 {
                        margin-bottom: 5px;
                    }
                }

                @media (min-width: 768px) and (max-width: 1155px) {
                    margin: 0 25px 5px;
                }
            }

            &--right {
                width: 50%;
                margin-left: 5px;

                @include mobile {
                    width: 100%;
                    margin-top: 15px;
                    height: 50%;
                    margin-left: 0;
                }
            }

            &--left {
                width: 50%;
                margin-right: 5px;

                @include mobile {
                    width: 100%;
                    margin-right: 0;
                    height: 50%;
                }
            }
        }
    }

    &__half {
        height: 35%;
        display: flex;
        flex-flow: row;

        @include mobile {
            flex-flow: wrap;
            height: 50%;
            margin-bottom: 15px;
        }
    }

    &__right {
        display: flex;
        flex-flow: column;
        margin-left: 10px;
        width: 60%;


        @include mobile {
            width: 100%;
            height: 100%;
            margin-left: 0;
            margin-top: 15px;
        }
    }

    &__img {

        &--desktop {
            @include mobile {
                display: none;
            }
        }

        @include mobile {
            height: auto;
        }

        &--mobile {
            display: none;

            @include mobile {
                display: block;
            }
        }
    }
}

.world-map {
    &__container {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        z-index: 1;
        position: relative;

        > div {
            width: 100%;
            height: 100vh;
            left: 0;
        }

        &:after {
            content: "";
            background: $background-blue;
            border: solid 1px #979797;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            opacity: 0.58;
            z-index: 0;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__video {
        width: 100vw;
        height: 100vh;
        -o-object-fit: cover;
        object-fit: cover;
        position: fixed;
        left: 0px;
        top: 0px;
        z-index: -1;
    }

    &__count-list {
        padding-left: 0;
        margin: 0 auto;
        margin-top: 75px;
        display: flex;
        list-style: none;
        flex-flow: row;
        justify-content: center;
        color: $text-color-white;
        width: 100%;

        li {
            text-align: center;
            margin: 0 3%;
            display: block;
        }

        span {
            font-size: 120px;
            font-weight: 500;
            display: block;

            @media (max-width: 1270px) {
                font-size: 90px;
                margin-bottom: 18px;
                line-height: 1;
            }

            @include mobile {
                font-size: 90px;
            }

            @media (max-height: 700px) {
                font-size: 70px;
            }

            @media (max-height: 600px) {
                font-size: 60px;
            }
        }

        p {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            margin-top: -15px;
        }

        @include mobiletablet {
            flex-flow: column;
            margin-top: 15px;
            justify-content: space-around;
            height: 80vh;
        }
    }


    &__heading {
        margin-top: 75px;
        margin-bottom: 0;
        padding: 0 50px;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        color: $text-color-white;

        @include mobile {
            margin-top: 70px;
        }
    }

    &__map {
        position: relative;
        z-index: 1;
        width: 100%;
        margin: 0 auto;
        margin-top: 5vh;
        max-width: 83vh;
        margin-bottom: 30px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        @include mobiletablet {
            display: none;
        }

    }
}

.video-block {
	height: 65vh;
	max-height: 650px;
	margin-bottom: 20px;

	.embed-responsive {
	  height: 100%;
	}

	.video-with-overlay__video {
	  z-index: 0;
	}
}



