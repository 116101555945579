﻿.mainimage-texteditor {
    &__img {
        img {
            width: 100%;
            height: 100%;
            object-fit: fill;
        }

        @include mobile {
            margin-bottom: 30px;
        }
    }

    &__text {
        max-width: 640px;
        float: right;

        h1, h2, h3, h4, h5, h6 {
            color: $text-color-blue;
        }

        p {
            line-height: 1.71;
        }
    }

    > div {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;
        max-width: 1370px;
        margin: 0 auto;
        padding: 100px 0 130px;

        @media (max-width: 1599px) {
            max-width: 1070px;
        }

        @media (max-width: 1199px) {
            padding: 100px 65px 130px;
            max-width: auto;
            max-width: unset;
        }

        @include tablet {
            padding: 100px 10px 130px;
        }

        @include mobile {
            padding: 30px 0;
        }

        > div {
            width: 50%;

            &.col-sm-12 {
                width: 100%;

                .mainimage-texteditor__text {
                    max-width: 900px;
                    float: none;
                    float: unset;
                    margin: 0 auto;
                }

                @include mobile {
                    padding: 0;
                }
            }

            &.col-sm-6 {
                .mainimage-texteditor__text {
                    float: none;
                    float: unset;
                    padding-left: 30px;
                }

                @include mobile {
                    padding: 0;

                    .mainimage-texteditor__text {
                        float: none;
                        float: unset;
                        padding-left: 0;
                    }
                }
            }

            @include mobile {
                width: 100%;
            }
        }
    }

    &--no-img {
        > div {
            padding: 40px 0 60px;

            @media (max-width: 1199px) {
                padding: 40px 65px 60px;
            }

            @include tablet {
                padding: 40px 10px 60px;
            }

            @include mobile {
                padding: 30px 0;
            }
        }

        .mainimage-texteditor__text {
            column-count: 2;
            max-width: 1200px !important;
            column-gap: 60px;

            @include tablet {
                column-gap: 30px;
            }

            @include mobile {
                column-count: 1;
            }
        }
    }
}
.p-globalcontentpage {
    .title-and-lead {
        p {
            font-weight: 500;
            @include mobile {
                font-weight: bold;
            }
        }
    }
}
