.twocolumnctablock {
  padding-bottom: 60px;

  & > div {
    max-width: 1145px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    .titleimagelinkblock {
      flex: calc(50% - 4px); // 50% minus half the gap.
      flex-grow: 0;
      height: 430px;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      padding: 12px;

      a {
        min-width: inherit;
      }
      
      .titleimagelinkblock{
        height: 290px;
        flex: none;
      }
    }
  }
}