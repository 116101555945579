.p-campaignpage {
  .hero-background {

    .hero-background__content {
      .hero-background__heading {
        margin-bottom: 60px;
      }
    }

    //Cta link
    .hero-background__button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 100px;

      a {
        font-weight: 700;
        background: $text-color-orange;
        border-radius: 25px;
        border: none;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 300px;

      }

      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 32px;


        a {
          min-width: inherit;
        }
      }
    }
  }

  .lead-content {
    h3 {
      color: $text-color-white;
    }
  }

  .video-block {
    padding-right: 20px;
    padding-left: 20px;
    max-width: none;
    @media (min-width: 768px) {
      padding-right: 28px;
      padding-left: 28px;
    }
    @media (min-width: 1024px) {
      padding: 90px 85px 80px;
    }

    .embed-responsive {
      padding: 37.25% 0px 0px;
      position: relative;
    }

    h3 {
      text-align: center;
    }

    span {
      display: inline-block;
      padding-top: 0.4rem;
      color: $text-color-white;
    }
  }

  .twocolumn-content {
    ul {
      list-style: outside !important;
      padding-left: 1.5rem !important;
    }
  }

  .content-shading-invertedmode {
    .titleleadcalltoaction-block__button {
      border-color: #ffffff;
      color: #ffffff;
    }
  }

  .titleleadfact-block__content {
    @media (min-width: 767px) {
      padding-right: 2rem;
    }
  }

  .imagetextblock {
    p {
      margin-top: 1rem;
      color: $text-color-white;
    }
  }

  .textandimage-block {
    float: none;
    max-width: 1200px;
    padding-right: 20px;
    padding-left: 20px;
    @media (min-width: 768px) {
      padding-right: 28px;
      padding-left: 28px;
    }
    @media (min-width: 1024px) {
      padding: 90px 85px 80px;
    }

    .textandimage-block__img {
      @media (min-width:768px){
        width: 50% !important;
        margin-right: 0 !important; 
      }
    }
  }
}