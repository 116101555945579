﻿.quote-slider-card {
	cursor: pointer;
	position: relative;
	width: 800px;
	height: 400px;

	@include mobile {
		width: 100%;
		height: 300px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__texts {		
		position: absolute;
		bottom: 24px;
		color: $text-color-blue;
		width: 280px;
		background-color: white;
		padding: 24px 14px 33px 26px;

		&.right {
			right: 24px;
		}
		&.left {
			left: 24px;
		}
		
		@include mobile {	
			padding: 8px 12px;
			bottom: 8px;

			&.right {
				right: 8px;
			}
			&.left {
				left: 8px;
			}
		}

		 &--title {		
			font-size: 14px;
			line-height: 22px;	
			font-weight: 700;
			text-transform: uppercase;
			
			@include mobile {
				font-size: 12px;
				line-height: 16px;
			}
		}

		&--sub-title {	
			font-size: 14px;
			line-height: 22px;
			margin-top: 0px;
			margin-bottom: 0px;
			font-weight: 700;
			
			@include mobile {
				font-size: 12px;
				line-height: 16px;
				margin-bottom: 0px;
			}
		}

		p {
			font-size: 15px;
			line-height: 25px;
			margin-bottom: 12px;
			
			@include mobile {
				font-size: 14px;
				line-height: 18px;
			}
		}
	}
}