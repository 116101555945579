﻿.news-item {
    padding: 0 65px 110px 65px;

    @include mobiletablet {
        padding: 0 28px 48px 28px;
        background: $background-white;
    }

    &__heading {
        display: none;

        @include mobiletablet {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            text-align: center;
            color: $text-color-blue;
            display: block;
            margin: 35px 0 17.5px;
        }
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "vertical vertical vertical . horizontal horizontal horizontal horizontal" "vertical vertical vertical . horizontal horizontal horizontal horizontal" "vertical vertical vertical . . . . ." "vertical vertical vertical . . . . ." "vertical vertical vertical . . . . .";

        @media screen and (-ms-high-contrast: none) {
            min-height: 400px;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

            @include mobiletablet {
                display: flex;
                flex-flow: column;
                justify-content: flex-start;
                margin: 17.5px 5px 0;
            }
        }

        &--portrait-right {
            grid-template-areas: ". . . . .vertical vertical vertical" ". . . . .vertical vertical vertical" ". . . . .vertical vertical vertical" "horizontal horizontal horizontal horizontal . vertical vertical vertical" "horizontal horizontal horizontal horizontal . vertical vertical vertical";

            @media screen and (-ms-high-contrast: none) {
                align-items: flex-end;

                .news-item__item--vertical {
                    width: 42% !important;

                    @include mobiletablet {
                        width: 100% !important;
                    }
                }

                .news-item__item--horizontal {
                    width: calc(58% - 10%) !important;
                    margin-right: 10% !important;
                    margin-left: 0 !important;

                    @include mobiletablet {
                        width: 100% !important;
                        margin-right: 0 !important;
                        margin-left: 0 !important;
                    }
                }

                @include mobiletablet {
                    justify-content: flex-start;
                }
            }
        }

        @include mobiletablet {
            display: flex;
            flex-flow: column;
            margin: 17.5px 5px 0;
        }
    }

    &__item {
        display: flex;
        flex-flow: column;
        text-align: right;
        position: relative;

        @media screen and (-ms-high-contrast: none) {
            flex: 0 0 auto;

            @include mobiletablet {
                flex: auto;
            }
        }

        &--vertical {
            grid-area: vertical;


            @media screen and (-ms-high-contrast: none) {
                width: 42%;


                @include mobiletablet {
                    width: 100%;
                }
            }



            img {
                object-fit: fill;
                height: 100%;
                width: 100%;
            }
        }

        &--horizontal {
            grid-area: horizontal;


            @media screen and (-ms-high-contrast: none) {
                width: calc(58% - 10%);
                margin-left: 10%;

                @include mobiletablet {
                    width: 100%;
                    margin-left: 0;
                }
            }


            img {
                object-fit: fill;
                height: 100%;
                width: 100%;
            }
        }



        @include mobiletablet {
            flex-flow: row;
            align-items: center;
            text-align: left;
            margin: 17.5px 0;
        }
    }

    &__info {
        @include mobiletablet {
            display: flex;
            flex-flow: column;
            max-width: 195px;
        }
    }

    &__img {
        position: relative;
        display: block;

        .playvideo {
            position: absolute;
            width: 80px;
            height: 80px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            min-width: unset;
            max-width: unset;
        }

        @media screen and (-ms-high-contrast: none) {
            flex-shrink: 0;
        }

        @include mobiletablet {
            height: 29.5vw;
            width: 29.5vw;
            margin-right: 17px;

            @media screen and (-ms-high-contrast: none) {
            }
        }
    }

    &__item:hover {
        text-decoration: none;

        .news-item__img {
            &:before {
                content: "";
                position: absolute;
                display: block;
                border-left: 11px solid $border-blue;
                animation-name: reducetime;
                animation: reducetime 5s;
                animation-delay: 5s;
                float: right;
                top: 0;
                left: 0;
                height: 100%;

                @include mobiletablet {
                    border-left: 5px solid $border-blue;
                }
            }

            @keyframes reducetime {
                0% {
                    width: 0;
                    opacity: 0;
                }

                100% {
                    width: 100%;
                    opacity: 1;
                }
            }
        }
    }

    &__info-heading {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        color: $text-color-blue;
        margin-top: 27px;
        margin-bottom: 5px;

        @include mobiletablet {
            margin: 12px 0;
            order: 2;
            font-size: 14px;
        }
    }

    &__date {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1.25px;
        color: $text-color-grey;
        order: 1;
        display: inline-block;

        @include mobiletablet {
            order: 1;
        }
    }

    &__category {
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        color: $text-color-grey;
        order: 3;
        display: inline-block;

        @include mobiletablet {
            order: 3;
        }
    }
}
