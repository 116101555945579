﻿.heading-block-container {
    position: relative;

    > div {
        padding: 0 20px;

        > div {
            display: flex;
            flex-direction: column;
            gap: 36px;
        }
    }
    
    > img, > video {          
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .default-color-schema {
        color: $text-color-blue; 
    }

    &__title{
        font-size: 44px;
        font-weight: 700;
    }

    &__text{
        font-size: 16px;
        line-height: 24px;
    }

    .content-middle {
        display: flex;
        justify-content: center;

        > div {
            text-align: center;
            align-items: center;
            max-width: 1032px;
        }
    }

    .content-left {
        > div {
            width: fit-content;
            max-width: 506px;
        }
    }

    .btn-primary {
        @include button-primary($background-orange, $text-color-white);
        margin: 0 auto;
        font-weight: 500;
        line-height: 24px;
    }
}
    