@layer globals {
  @font-face {
    font-family: "Gotham";
    src: url("/Static/greencalc/assets/public/fonts/Gotham-ExtraLight.woff2") format("woff2");
    font-weight: 200;
    font-style: normal;
    font-display: swap
  }

  @font-face {
    font-family: "Gotham";
    src: url("/Static/greencalc/assets/public/fonts/Gotham-Book.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap
  }
}

.engineering-campaign-container {
  font-family: Gotham, system-ui, sans-serif;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    
    @include mobiletablet {
    }
  }
 
  h1, h2, h3, p, span {
    margin: 0;
    padding: 0;
    font-family: var(--font-sans);
  }

  .content-width {
    & > div{
      width: 1240px;

      @include mobiletablet {
        width: 100%;
      }
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  .control-image-selected, .step3-control-image-selected {
    color: $text-color-blue;
    height: 16px;
    width: 16px;
  }
  .control-image-unselected, .step3-control-image-unselected {
    color: #999999;
    opacity: 0.5;
  }

  .section-1 {
    position: relative;
    z-index: -1;

    & > div {      
      width: 100%;
    }

    &__video {
      object-fit: cover;
      height: 60vh;
      width: 100%;
    }

    &__texts {   
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 50px;
      left: 0;
      color: white;

      @include mobiletablet {
        padding: 0 10px;
      }

      & > div > div{
        display: flex;
        gap: 20px;

        @include mobiletablet {
          flex-direction: column;
        }
      }

      h2 {
        font-size: 24px;
        font-weight: 300;
        padding-bottom: 42px;

        @include mobiletablet {
          padding-bottom: 12px;
        }
      }

      span {
        font-size: 280px;
        font-weight: 200;
        line-height: 200px;
        margin-right: 8px;

        @include mobiletablet {
          font-size: 120px;
          line-height: 100px;
        }
      }

      p {
        width: 272px;
      }
    }
  }

  .section-2 {
    background-color: white;

    @include mobiletablet {
      padding: 0 10px;
    }

    h1 {
      font-size: 88px;
      font-weight: 300;
      color: $text-color-blue;
      padding-top: 32px;
      padding-bottom: 15px;

      @include mobiletablet {
        font-size: 44px;
      }
    }
    
    p {
      max-width: 844px;
    }
  }

  .section-3 {
    background-color: white;
    position: relative;

    &__background {
      margin-top: 320px;
      margin-bottom: 260px;
      object-fit: cover;
      height: 35vw;
      width: 100%;
      opacity: 0.25;
        
      @include mobiletablet {
        margin-top: 120px;
        margin-bottom: 160px;
        height: 500px;
      }
    }

    &__content {     
      position: absolute;
      top: 56px;
      
      @include desktop {
        display: flex;
        flex-direction: column;
        align-items: end;

        & > div:first-child {
          width: 100%;
        }
      }
      
      @include mobiletablet {
        padding: 0 10px;
      }      

      & > div {
        display: flex;
        margin-bottom: 10px;
        
        @include mobiletablet {
          flex-direction: column;
        }

        & > div {
          padding-top: 45px;

          @include mobiletablet {
            padding-top: 0px;
          }
        }
      }

      h2 {
        font-size: 280px;
        font-weight: 200;
        color: $text-color-orange;
        padding-right: 48px;

        @include mobiletablet {
          font-size: 120px;
        }
      }

      p {
        max-width: 440px;
        margin-bottom: 31px;
      }

      a {
        padding: 7px 24px;
        background-color: $text-color-orange;
        color: white;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      &__video {
        margin-top: -60px;
        display: flex;
        justify-content: end;
        
        @include desktop {
          width: 75%;
        }

        @include mobiletablet {
          margin-top: 30px;
          align-items: center;
        }

        & > video {
          width: 100%;
          object-fit: contain;
        
          @include mobiletablet {
            width: 95vw;
          }
        }
      }
    }
  }

  .section-4 {
    background-color: white;
    padding: 0 10px;
    
    h2 {
      font-size: 44px;
      font-weight: 300;
      color: $text-color-blue;
      padding-top: 80px;
      padding-bottom: 24px;
    }
    
    p {
      max-width: 844px;
    }
  }

  .section-5 {
    margin-top: 102px;

    &__slider {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__quote-cards-container {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        width: 800px;
        height: 400px;
        position: relative;

        @include mobiletablet {
          width: 100%;
          height: 300px;
        }

        .quoteslidercardblock {
          position: absolute;

          @include mobiletablet {
            width: 100%;
          }

          &:not(:first-child){
            .quote-slider-card__texts {
              visibility: hidden;
            }

            @include mobiletablet {
              visibility: hidden;
            }
          }

          &:nth-child(1) {
            left: auto;
            z-index: 100;
          }

          @include desktop {
            &:nth-child(2) {
              left: 180px;
              z-index: 99;
              transform: scale(0.8);
            }

            &:nth-child(3) {
              left: 320px;
              z-index: 98;
              transform: scale(0.6);
            }

            &:nth-last-child(2) {
              left: -320px;
              z-index: 96;
              transform: scale(0.6);
            }

            &:last-child {
              left: -180px;
              z-index: 97;
              transform: scale(0.8);
            }            
          }
        }
      } 

      &__controls {
        display: flex;
        align-items: center;
        gap: 10px;

        #backward-quote, #forward-quote {
          margin: 8px;
        }

        & > svg {
          cursor: pointer;
        }
      }      
    }
  }
  
  .section-6 {
    margin-top: 102px;
    padding: 0 10px;
    padding-bottom: 105px;
    background-color: #ADDC91;

    &__texts {
      margin-top: 36px;
      margin-bottom: 103px;
      display: flex;
      gap: 24px;

      @include mobiletablet {
        flex-direction: column;
      }

      h2 {
        font-size: 48px;
        font-weight: 200;
        color: $text-color-blue; 
        margin-top: 80px;       
        margin-bottom: 14px;

        @include mobiletablet {
          flex-direction: column;
          margin-top: 0;   
          font-size: 32px;
        }
      }
      
      p {
        max-width: 600px;
        color: $text-color-blue;
      }

      span {
        color: $text-color-orange;
        font-size: 280px;
        font-weight: 200;

        @include mobiletablet {
          font-size: 120px;
          line-height: 80px;
        }
      }
    }

    &__steps {
      display: none;
      
      &.desktop{        
        @include desktop {
          display: flex;
          justify-content: center;
        }
      }
      &.mobile {
        @include mobiletablet {
          display: block;

          & > div.marker {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            &:not(:first-child) {
              margin-top: 40px;
            }

            & > div.section-6__steps__divider {
              height: 20px;
            }
          }
        }
      }

      #left {
        z-index: 1;
        & > div.section-6__steps__card:nth-child(1) {
          @include mobiletablet {
            margin-top: 80px;
          }
        }
        & > div.section-6__steps__card:nth-child(2) {
          margin-top: 380px;

          @include mobiletablet {
            margin-top: 900px;
          }
        }
        & > div.section-6__steps__card:nth-child(3) {
          margin-top: 480px;

          @include mobiletablet {
            margin-top: 808px;
          }
        }
      }

      #middle {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include desktop {
          padding-top: 315px;
        }

        & > div {
          display: flex;
          align-items: center;
        }

        @include mobiletablet {
          & > div.section-6__steps__divider:nth-child(2) {
            height: 645px;
          }
          & > div.section-6__steps__divider:nth-child(4) {
            height: 720px;
          }
          & > div.section-6__steps__divider:nth-child(6) {
            height: 815px;
          }
          & > div.section-6__steps__divider:nth-child(8) {
            height: 625px;
          }
          & > div.section-6__steps__divider:nth-child(10) {
            height: 560px;
          }
        }
      }
      
      #right {
        z-index: 1;
        & > div.section-6__steps__card:nth-child(1) {
          margin-top: 565px;

          @include mobiletablet {            
            margin-top: 788px;
          }
        }
        & > div.section-6__steps__card:nth-child(2) {
          margin-top: 450px;

          @include mobiletablet {
            margin-top: 995px;
          }
        }        
      }

      &__card {
        width: 540px;
        padding: 40px 30px;
        background-color: white;
        
        @include mobiletablet {
          width: 100%;
          padding: 40px 0;
        }

        h2, h3 {
          font-size: 32px;
          font-weight: 400;
          color: $text-color-blue;
          margin: 0 10px;
          margin-bottom: 24px;

          @include mobiletablet {
            margin: 0 10px;
            margin-bottom: 12px;
          }
        }

        p {
          @include mobiletablet {
            padding: 0 10px;
          }
        }

        video {
          width: 480px;
          object-fit: cover;
          margin: 10px 0;

          &.step1 {
            height: 480px;

            @include mobiletablet {
              width: 100%;
              height: auto;
            }
          }

          &.step4, &.step5 {
            height: 300px;

            @include mobiletablet {
              width: 100%;
              height: auto;
              flex-direction: column;
            }
          }
        }

        
        &__card-list {
          position: relative;
          height: 485px;

          @include mobiletablet {
            width: 100%;
            height: 465px;
          }

          img {
            height: 217px;

            @include mobiletablet {
              width: 100%;
            }
          }
        }

        &__card-list > div:nth-child(1) {
          position: absolute;
          width: 384px;
          top: 96px;
          left: 0px;
          z-index: 4;

          @include mobiletablet {
            top: 75px;
            left: 0;
            width: 100%;
            scale: 1;
          }
        }
        &__card-list > div:nth-child(2) {
          position: absolute;
          width: 384px;
          top: 64px;
          left: 32px;
          z-index: 3;

          @include mobiletablet {
            top: 50px;
            left: 0;
            width: 100%;
            scale: 0.9 1;
          }
        }
        &__card-list > div:nth-child(3) {
          position: absolute;
          width: 384px;
          top: 32px;
          left: 64px;
          z-index: 2;

          @include mobiletablet {
            top: 25px;
            left: 0;
            width: 100%;
            scale: 0.8 1;
          }
        }
        &__card-list > div:nth-child(4) {
          position: absolute;
          width: 384px;
          top: 0;
          left: 96px;
          z-index: 1;

          @include mobiletablet {
            top: 0;
            left: 0;
            width: 100%;
            scale: 0.7 1;
          }
        }

        &__link {
          display: flex;
          align-items: center;
          gap: 8px;
          color: $text-color-orange;
          margin-top: 24px;
        
          @include mobiletablet {
            padding: 0 10px;
          }
          
          a {
            color: $text-color-orange;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }

        &__switching-images {
          position: relative;
          width: 480px;
          height: 342px;

          @include mobiletablet {
            width: 100%;
            height: 342px;
          }
          
          & > div {
            position: absolute;         
            width: 100%;

            &:not(:first-child){
              visibility: hidden;
            }
            
            img {
              width: 480px;
              height: 300px;
              object-fit: cover;

              @include mobiletablet {
                width: 100%;
              }
            }

            p {
              background-color: white;
              width: 100%;
              text-align: center;
              color: $text-color-blue;
              font-size: 16px;
              line-height: 26px;
              margin-top: 16px;
            }
          }
        }

        &__switching-controls {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          margin: 16px 0;

          & > svg {
            cursor: pointer;
          }
        }
      }

      &__connector {
        width: 48px;
        border-top: 2px solid $text-color-blue;

        @include mobiletablet {
          display: none;
        }
      }

      &__empty-connector {
        width: 48px;
      }

      &__divider {
        height: 480px;
        border-right: 2px solid $text-color-blue;
      }

      &__step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 62px;
        width: 62px;
        border: 2px solid $text-color-blue;
        border-radius: 50%;

        & > span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          color: white;
          background-color: $text-color-blue;
          height: 44px;
          width: 44px;
          border-radius: 50%;
        }
      }
    }

    #end {
      background-color: white;
      padding: 80px 100px;
      text-align: center;
        
      @include mobiletablet {
        padding: 40px;
        margin-top: 60px;
      }
      
      h2, h3 {
        color: $text-color-blue;
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 40px;
        
        @include mobiletablet {
          margin-bottom: 20px;
        }
      }

      .end-images {
        height: 300px;
        display: flex;
        justify-content: center; 
        gap: 80px;
        
        @include mobiletablet {
          flex-direction: column;
          width: 100%;
          height: auto;
          gap: 24px;
          padding: 10px 10px;
        }
      }
    }
  }

  .section-7, .section-8 {
    margin-top: 120px;

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      @include mobiletablet {
        flex-direction: column;
        gap: 24px;
        margin: 0 10px;
      }
    }

    img {
      width: 610px;
      height: 459px;
      object-fit: cover;
      
      @include mobiletablet {
        width: 100%;
      }
    }

    &__texts {
      max-width: 525px;
      display: flex;
      flex-direction: column;
      gap: 24px;   
      
      h2 {
        color: $text-color-blue;
        font-size: 36px;
      }

      a {
        padding: 7px 24px;
        background-color: $text-color-orange;
        color: white;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .section-9 {
    margin-top: 140px;
    padding-top: 60px;
    background-color: #EEF1F4;
  }
}