.bptl-bodyWrapper {
  background-color: #f0eeef;
  display: flex;
  justify-content: center;
  height:auto;
  min-height:fit-content;
}
//header
.bptl-header {
  width: 100%;
  .navbar {
      background-color: $background-white;
      max-height: 113px;
      height: 100%;
  } 
  .navbar-fixed-top{
      box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.75);
      -webkit-box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.75);
  } 
  &__logo {
    height: 56px;
    width: 129px;
    padding: 0;
    @include desktop{
      margin-left: 0 !important;
    }

    img {
        width: 100%;
    }

    &--white {
        display: none;
    }
    &--blue {
        display: block;
        pointer-events: none;
    }
  }

  &__search-icon {
      color: $text-color-white;
      font-size: 0;
      margin: 0 20px 0;
      background-image: url('/src/images/SearchWhite.svg');
      background-size: cover;
      background-repeat: no-repeat;
      width: 22px;
      height: 22px;


      &:hover, &:focus, &:active {
          color: $text-color-white;
      }

      @include mobiletablet {
          width: 27px;
          height: 27px;
      }
  }
  &__heart-icon{
      margin: 0 20px 0;
      background-image: url('/src/images/bptl-heart.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 25px;
      position: relative;
      top: 5px;
  }

  .navbar-header {
      @include mobiletablet {
          width: 50%;
          margin-right: 0;
          margin-left: 0;
      }

      @media (min-width: 1200px) {
          margin-right: 115px;
      }
  }

  > nav {
      background: transparent;
      border: 0;
      height: 50px;
      margin-bottom: 0;

      @include desktop {
          padding: 0 0 0 15px;
      }

      .container {
          display: flex;
          align-items: center;
          overflow: hidden;
          height: 100%;
          @include mobiletablet {
              justify-content: space-between;
          }
      }

      .nav {
          li {
              a {
                  color: $text-color-white;
                  font-size: 15px;
                  font-weight: bold;

                  &:hover, &:focus {
                      color: $text-color-white;
                  }
              }
          }

          &.navbar-right {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              width: 100%;
              margin: 0;

              @media (min-width: 768px) {
                  margin-right: 0;
              }


              .bptl-menu-button {
                  background: transparent;
                  border: 0;
                  margin-right: 10px;

                  @include mobiletablet {
                      margin-right: 0;
                      padding: 0;
                  }

                  &:focus {
                      outline: 0;
                  }
                  //Hamburger menu icon
                  .bptl-menu-icon {
                      width: 30px;
                      height: 20px;
                      position: relative;
                      margin: 0px;
                      -webkit-transform: rotate(0deg);
                      -moz-transform: rotate(0deg);
                      -o-transform: rotate(0deg);
                      transform: rotate(0deg);
                      -webkit-transition: .5s ease-in-out;
                      -moz-transition: .5s ease-in-out;
                      -o-transition: .5s ease-in-out;
                      transition: .5s ease-in-out;
                      cursor: pointer;
                  }

                  .bptl-menu-icon span {
                      display: block;
                      position: absolute;
                      height: 2px;
                      width: 100%;
                      border-radius: 9px;
                      opacity: 1;
                      left: 0;
                      -webkit-transform: rotate(0deg);
                      -moz-transform: rotate(0deg);
                      -o-transform: rotate(0deg);
                      transform: rotate(0deg);
                      -webkit-transition: .25s ease-in-out;
                      -moz-transition: .25s ease-in-out;
                      -o-transition: .25s ease-in-out;
                      transition: .25s ease-in-out;

                      @include mobiletablet {
                          height: 3px;
                      }
                  }


                  .bptl-menu-icon span {
                      background: $text-color-black;
                  }

                  .bptl-menu-icon span:nth-child(1) {
                      top: 0px;
                      -webkit-transform-origin: left center;
                      -moz-transform-origin: left center;
                      -o-transform-origin: left center;
                      transform-origin: left center;
                  }

                  .bptl-menu-icon span:nth-child(2) {
                      top: 10px;
                      -webkit-transform-origin: left center;
                      -moz-transform-origin: left center;
                      -o-transform-origin: left center;
                      transform-origin: left center;
                  }

                  .bptl-menu-icon span:nth-child(3) {
                      top: 20px;
                      -webkit-transform-origin: left center;
                      -moz-transform-origin: left center;
                      -o-transform-origin: left center;
                      transform-origin: left center;
                  }

                  .bptl-menu-icon.bptl-open span:nth-child(1) {
                      -webkit-transform: rotate(45deg);
                      -moz-transform: rotate(45deg);
                      -o-transform: rotate(45deg);
                      transform: rotate(45deg);
                      top: 0px;
                      left: 8px;
                  }

                  .bptl-menu-icon.bptl-open span:nth-child(2) {
                      width: 0%;
                      opacity: 0;
                  }

                  .bptl-menu-icon.bptl-open span:nth-child(3) {
                      -webkit-transform: rotate(-45deg);
                      -moz-transform: rotate(-45deg);
                      -o-transform: rotate(-45deg);
                      transform: rotate(-45deg);
                      top: 21px;
                      left: 8px;
                  }
              }
          }
      }

  }
}
.bptl-side-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  background-color: $background-blue;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  padding-top: 113px;
  &__choose-language{
      color: $text-color-white;
      padding: 8px 8px 8px 20px;
      margin: 68px 0 40px;
      font-size: 18px;
      font-weight: bold;
      p{
        margin: 0;
      }
      
  }
  ul{ 
      padding: 0;
  }
  li{
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 40px;
  }
  &__menu-link{
      padding: 8px 8px 8px 20px;
      text-decoration: none;
      font-size: 18px;
      font-weight: bold;
      color: $text-color-white;
      display: block;
      transition: 0.3s;
      display: flex;
      align-items: center;
      width: auto;
  }
  &__menu-link:hover{
      color:$text-color-grey;
      text-decoration: none;
  }
  &__arrow-icon{
      width: auto;
      height: 20px;
  }
  &__arrow-icon:after{
      content: "";
      display: block;
      background-image: url('/src/images/bptl-arrow.svg');
      background-size: 20px 20px;
      background-repeat: no-repeat;
      padding: 6px;
      margin-left: 9px;
      height: 100%;
      width: 20px;
  }
}
.bptl-posts-container {
  background-color: white;
  max-width: 607px;
  width: 100%;
  padding:113px 0 0;
  @include mobile{
    padding: 95px 0 0;
  }
}
//Top post pinned to top
.bptl-start-block {
  background-color: white;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #bcbcbc;
  max-width: 100%;
  margin: 42px 0 10px;
  p {
    line-height: 21px;
    margin: 0;
  }
  &__title {
    font-size: 21px;
    line-height: 28px;
    text-align: left;
    margin: 0 0 21px;
    color: #144E8C;
  }
  &__video {
    video {
      width: 100%;
      object-fit: cover;
      @include mobile {
        max-height: 392px;
      }
    }
  }
  &__lower-container {
    margin-top: 10px;
    @include mobile {
      padding: 0 15px;
    }
  }
  .bptl-video-block {
    max-height: 392px;
  }
}
//Img block
.bptlimageblock {
  display: flex;
  justify-content: center;
  height: auto;
  img {
    max-width: 100%;
    object-fit: contain;
    @include mobile {
      width: 100%;
      height: auto;
    }
  }
}
//vimeo video container
.bptl-embed-responsive{
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; overflow: hidden; 
  max-width: 100%; 
}
.bptl-embed-responsive iframe, 
.bptl-embed-responsive object, 
.bptl-embed-responsive embed { 
  position: absolute; 
  top: 0; left: 0; 
  width: 100%; 
  height: 100%; 
}

.bptl-video-block {
	height: auto;
	max-height: 650px;
	margin-bottom: 20px;

	.embed-responsive {
	  height: 100%;
	}

	.video-with-overlay__video {
	  z-index: 0;
	}
}

//Single post
.bptl-post-block {
  display: flex;
  justify-content: left;
  background-color: white;
  margin-bottom: 10px;
  max-width: 100%;
  &__container {
    border-bottom: 1px solid #bcbcbc;
  }
  form{
    margin-top: 13px;
  }
  p {
    line-height: 21px;
    margin: 0;
  }
  &__title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 5px;
    text-align: left;
    color: #144E8C;
  }
  &__location {
    display: flex;
    margin-top: 16px;
    .fa-map-marker {
      color: #FE8200;
      font-size: 20px;
    }
  }
  &__location-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    a {
      color: #FE8200;
      font-weight: bold;
    }
    a:hover {
      text-decoration: none;
    }
  }
  &__img {
    img {
      max-width: 100%;
      max-height: 565px;
      object-fit: contain;
    }
  }
  &__video {
    video {
      width: 100%;
    }
  }
}
//Location list page
.bptl-location-list{
  width: 100%;
}
.bptl-location-title{
  font-size: 21px;
  color: #144e8c;
  text-align: center;
}
.bptl-location-go-back{
  padding: 40px 0 20px;
  font-size: 16px;
  height: auto;
  &__icon{
    color: white;
    background-color:#144e8c;
    border-radius: 3px;
    padding: 10px;
    margin-left: 15px;
  }
  &__icon:hover{
    text-decoration: none;
    color: white;
    background-color: #144e8cf3;
    cursor: pointer;
  }
  &__icon:before{
    content: "";
    display: inline-flex;
    background-image: url('/src/images/bptl-arrow.svg');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    padding: 6px;
    margin-left: 9px;
    height: 20px;
    width: 20px;
    transform: rotate(180deg);
    margin-right: 10px;
  }
}
//Form
.p-bptlpublishingpage{
  background-color: #f0eeef;
}
.bptl-form-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.bptl-form{
  padding-top: 113px;
  display: flex;
  flex-direction: column;
  padding: 113px  20px 0 20px;
  text-align: left;
  height: 100%;
  width: 600px;
  background-color: white;
  &__intro{
    margin-top: 10px;
    h1 {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 5px;
      text-align: left;
      color:  #144E8C;
    }
  }
  &__container{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 50px;
  }
 label{
  display: flex;
  // flex-direction: column;
  margin-bottom: 0px;
  color: #274476;
  font-weight: 500;
 }
 input, select{
  margin-bottom: 20px;
  color: #9b9b9b;
  font-size: 12px;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #e9ecf1;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
 }
 textarea{
  margin-bottom: 20px;
  color: #9b9b9b;
  font-size: 12px;
  padding: 6px 12px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #e9ecf1;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
 }
 &__img-label,
 &__video-label{
  background-color: #144E8C;
  border-radius: 20px;
  color: white;
  max-width: fit-content;
  padding: 6px 12px;
  color: white !important;
  font-weight: 500;
  font-size: 14px;
 }
 &__button-container{
  &__media-header{
    color: #274476;
    font-weight: 500;
    max-width: 100%;
    margin-bottom: 0px;
    max-width: 450px;
  }
  &__media-text{
    font-size: 12px;
    font-weight: 500;
    max-width: 450px;
  }
  &__flex{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0;
    align-items: center;
    label:hover{
      cursor: pointer;
      background-color: #103e70;
    }
  }
  .uploaded-media-container {
    margin: 20px 0;
  }
  .uploaded-media {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-weight: 500;
  }
  .uploaded-media img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
  }
 }
  &__input {
    margin-bottom: 10px;
  }
  &__submit {
    background-color: #144E8C;
    border-radius: 20px;
    color: white;
    width: 242px;
    margin-right: auto;
    margin-left: auto;
    padding: 8px 30px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
  }
  &__submit:hover{
    background-color: #103e70 !important;
    color: white !important;
  }
  &__location-input{
    option{
      color: #555555;
      font-size: 12px;
    }
  }
 input[type=file]{
  opacity: 0;
  position: absolute;
  z-index: -1;
  }
  &__label-text{
    display: flex;
    flex-direction: row;
  }
  &__success{
    color:  $text-color-blue;
    text-align: center;
    display: none;
  }
  .red-asterix{
    color: #ff0000;
  }
  .required::after{
    content: "*";
    font-weight: bold;
    color: red; 
   }
}

.Form__Element__ValidationError{
  color: #ff0000;
  position: relative;
  bottom: 17px;
}

//general stuff
//google translate
.VIpgJd-ZVi9od-ORHb-OEVmcd{
 // display: none; if we want to hide google translate bar
}
//disqus
.disqus-button--open, .disqus-button--close{
  background-color: #144E8C;
  color: white;
  border-radius: 50px;
}
.disqus-button--open:hover, 
.disqus-button--close:hover{
  background-color: #103e70;
  color: white !important;
} 

.disqus-button-container{
  margin: 20px 0 15px;
}
#google_translate_element select{
  background-color:$text-color-white;
  color:$text-color-blue;
  border: none;
  border-radius:3px;
  padding:6px 8px;
}
.bptl-block__thumb {
  font-weight: bold;
  cursor: pointer;
  max-width: fit-content;
  margin: 20px 0 9px;
  display: flex;
  align-items: center;
  gap: 10px;
  button{
    background-color: transparent;
    border: none;
  }
}
.bptl-thumb-icon, .bptl-green-thumb-icon {
  background-size: 25px 21px;
  background-repeat: no-repeat;
  background-position: 0px 1px;
  background-position: center center;
  padding: 20px 0 20px 30px;
  margin: 0;
}
.bptl-thumb-icon-unlike, .bptl-green-thumb-icon-unlike {
  background-size: 25px 21px;
  background-repeat: no-repeat;
  background-position: 0px 1px;
  background-position: center center;
  padding: 20px 0 20px 30px;
  margin: 0;
}
.bptl-thumb-icon {
  background-image: url("/src/images/bptl-thumb.svg");
}
.bptl-green-thumb-icon {
  background-image: url("/src/images/bptl-thumb-green.svg");
}

.bptl-thumb-icon-unlike {
  background-image: url("/src/images/bptl-thumb.svg") !important;
}
.bptl-green-thumb-icon-unlike {
  background-image: url("/src/images/bptl-thumb-green.svg");
}
.bptl-block__show-more {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 5px 0 20px;
  max-width: fit-content;
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    cursor: pointer;
    margin-right: 10px;
  }
}
.bptl-padding {
  padding: 0 18px;
  @include mobile{
    padding:0 5px;
  }
}
//Expand and collapse text in posts
[data-expandable] [data-expand-text] {
  --line-height: 1.5;
  --lines-to-show: 6;
  --bg-color: white;
  position: relative;
  box-sizing: border-box;
  line-height: var(--line-height);
  overflow: hidden;
  max-height: calc(var(--line-height) * var(--lines-to-show) * 1em);
  transition: max-height 0.3s ease-in-out;
}
[data-overflow="false"] [data-expand-button],
[data-overflow="false"] .fa-plus {
  display: none;
}
[data-expandable] [data-expand-text]::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, transparent, var(--bg-color));
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
[data-overflow="false"] [data-expand-text]::before,
[data-expandable].expanded [data-expand-text]::before {
  opacity: 0;
}

//Slideshows
.bptl-swiper-container {
  max-width: 565px;
  height: 564px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  img {
    object-fit: contain;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-color: white;
    border-radius: 50%;
  }
}
.bptl-swiper-wrapper {
  overflow-x: auto;
  display: flex;
}
.carousel-item {
  min-width: 100%;
}

.bptl-chevron-left-icon {
  background-image: url("/src/images/bptl-chevron-left.svg");
  background-size: 10px 30px;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 10px 0 20px 30px;
  margin: 0;
}
.bptl-chevron-right-icon {
  background-image: url("/src/images/bptl-chevron-right.svg");
  background-size: 10px 30px;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 10px 0 20px 30px;
  margin: 0;
}
.swiper-next,
.swiper-prev {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-top: -22px;
  color: black;
  font-weight: bold;
  font-size: 14px;
  transition: 0.6s ease;
  user-select: none;
  background-color: white;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-next {
  right: 27px;
}
.swiper-prev {
  left: 27px;
}
.swiper-dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #d9d9d9;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  pointer-events: none;
}
.active-bptl,
.swiper-dot:hover {
  background-color: #717171;
}

.bptl-swiper-pagination {
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  border-bottom: 1px solid #bcbcbc;
  padding: 10px 0;
  margin-right: auto;
  margin-left: auto;
  max-width: 566px;
  height: 35px;
  margin-bottom: 10px;
  @include mobile{
    max-width: 344px;
    margin-right: auto;
    margin-left: auto;
  }
  @media (max-width: 600px) {
    max-width: 344px;
    margin-right: auto;
    margin-left: auto;
  }
}

.gallery-scroller {
  color: white;
  position: relative;
  height: 582px;
  width: 100%;
  @include mobile{
    height: 405px;
  }
}

.gallery-scroller .scroller {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
}

.gallery-scroller .scroller.snap {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.gallery-scroller .scroller {
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  z-index: 1;
}

.gallery-scroller .scroller::-webkit-scrollbar {
  display: none;
}

.gallery-scroller .scroller > a {
  display: inline-flex;
  min-width: 100%;
  height: 100%;
  vertical-align: top;
  scroll-snap-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: none;
}

.gallery-scroller .scroller > a img {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
}

.gallery-scroller .content button {
  background-color: white;
  text-transform: uppercase;
  padding: 11px 15px;
  border-radius: 5px;
  border: 0;
  margin: 0;
  cursor: pointer;
}
/* circles */
.gallery-scroller .pagination .circles {
  position: absolute;
  z-index: 8;
  bottom: -25px;
  display: flex;
  gap: 14px;
  left: 50%;
  transform: translateX(-50%);
}
.gallery-scroller .pagination .circles > div {
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  content: "";
  width: 11px;
  height: 11px;
  border: 1px solid white;
  background-color: #d9d9d9;
  border-radius: 50%;
  cursor: pointer;
}

.gallery-scroller .pagination .circles > div.active-bptl {
  background-color: #4a4a4a;
}

/* // circles */

/* thumbnails */
.gallery-scroller .pagination .thumbnails {
  position: absolute;
  z-index: 999;
  bottom: 0;
  display: flex;
  gap: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 22px;
}

.gallery-scroller .pagination .thumbnails > .thumbnail {
  transition-property: border-color;
  transition-duration: 0.5s;
  content: "";
  border: 2px solid black;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 50px;
  height: 50px;
}

.gallery-scroller .pagination .thumbnails > .thumbnail.active-bptl {
  border-color: white;
}

.gallery-scroller .pagination .thumbnails > .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* // thumbnails */

.gallery-scroller .pagination .thumbnails > div.active-bptl {
  background-color: white;
}

.gallery-scroller.gallery-scroller--tint .scroller > div::after {
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
}

.gallery-scroller .content {
  text-align: center;
  max-width: 70%;
}

@media only screen and (max-width: 500px) {
  .gallery-scroller .content {
    width: 60%;
  }

  .gallery-scroller .btn.prev {
    left: 20px !important;
  }

  .gallery-scroller .btn.next {
    right: 20px !important;
  }
}

/*
skin2
*/
.gallery-scroller .pagination--skin2 .btn.prev {
  width: 34px;
  height: 34px;
  left: 43px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.083 12.792L12.792 20L24.083 27.208L24.083 12.792ZM20 3.333C22.278 3.333 24.4307 3.77067 26.458 4.646C28.486 5.52067 30.257 6.715 31.771 8.229C33.285 9.743 34.4793 11.514 35.354 13.542C36.2293 15.5693 36.667 17.722 36.667 20C36.667 22.3053 36.2293 24.472 35.354 26.5C34.4793 28.528 33.285 30.292 31.771 31.792C30.257 33.292 28.486 34.4793 26.458 35.354C24.4307 36.2293 22.278 36.667 20 36.667C17.6947 36.667 15.528 36.2293 13.5 35.354C11.472 34.4793 9.70799 33.292 8.20799 31.792C6.70799 30.292 5.52066 28.528 4.64599 26.5C3.77066 24.472 3.33299 22.3053 3.33299 20C3.33299 17.722 3.77066 15.5693 4.64599 13.542C5.52066 11.514 6.70799 9.743 8.208 8.229C9.708 6.715 11.472 5.52066 13.5 4.646C15.528 3.77067 17.6947 3.333 20 3.333Z' fill='white'/%3E%3C/svg%3E%0A");
}

.gallery-scroller .pagination--skin2 .btn.next {
  width: 34px;
  height: 34px;
  right: 43px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='34px' height='34px' viewBox='0 0 34 34' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cpath d='M12.583992,23.875 L23.874992,16.667 L12.583992,9.459 L12.583992,23.875 Z M16.666992,33.334 C14.388992,33.334 12.236292,32.8963 10.208992,32.021 C8.181002,31.1463 6.410002,29.952 4.896002,28.438 C3.382002,26.924 2.187662,25.153 1.313002,23.125 C0.437666,21.0977 4.99600361e-16,18.945 4.99600361e-16,16.667 C4.99600361e-16,14.3617 0.437666,12.195 1.313002,10.167 C2.187662,8.139 3.382002,6.375 4.896002,4.875 C6.410002,3.375 8.181002,2.18767 10.208992,1.313 C12.236292,0.437667 14.388992,1.60982339e-15 16.666992,1.60982339e-15 C18.972292,1.60982339e-15 21.138992,0.437667 23.166992,1.313 C25.194992,2.18767 26.958992,3.375 28.458992,4.875 C29.958992,6.375 31.146292,8.139 32.020992,10.167 C32.896292,12.195 33.333992,14.3617 33.333992,16.667 C33.333992,18.945 32.896292,21.0977 32.020992,23.125 C31.146292,25.153 29.958992,26.924 28.458992,28.438 C26.958992,29.952 25.194992,31.1463 23.166992,32.021 C21.138992,32.8963 18.972292,33.334 16.666992,33.334 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.gallery-scroller .pagination--skin2 .thumbnails {
  position: relative;
  left: 0;
  transform: none;
  justify-content: center;
  margin-top: 10px;
}

.gallery-scroller .pagination--skin2 .thumbnails .thumbnail {
  border: 2px solid transparent;
}

.gallery-scroller .pagination--skin2 .thumbnails .thumbnail.active-bptl {
  border: 2px solid black;
}