.multipleimageblock {
  .multiple-image-block-container {
    display: flex;
    flex-direction: row;
    max-width: 1145px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 60px 20px;
    gap: 32px;

    &.content-right {
      flex-direction: row-reverse;
    }

    .column {
      flex: calc(50% - 16px);
    }

    .multiple-image {
      img {
        width: 100%;
        object-fit: inherit;
      }
    }

    .multiple-content {
      margin-bottom: 30px;
      line-height: 26px;
      font-size: 14px;

      h1 {
        color: $text-color-blue;
        margin-bottom: 30px;
      }
    }

    .multiple-images-content {
      list-style: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 0;
      gap: 20px;

      .image-small {
        max-height: 90px;
        width: 120px;
        color: $text-color-black;
        margin-bottom: 40px;
        text-align: center;
        font-size: 12px;

        &.selected {
          border: 4px solid $text-color-blue;
        }

        img {
          width: 100%;
          object-fit: contain;
          height: 100%;
          padding-bottom: 10px;
          
        }
      }
    }
  }

  @include mobile {
    font-size: 16px;

    .multiple-image-block-container {
      flex-direction: column-reverse;

      .image-small {
        flex: calc(50% - 10px);
        flex-grow: 0;
      }
    }
  }
}
