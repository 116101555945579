﻿.footer {
    background: $background-white;
    border-top: 3px solid $border-blue;

    @include mobiletablet {
        border-top: 4px solid $border-blue;
    }

    .container {
        > .row {
            padding: 70px 80px 110px;
            width: 100%;

            @include mobiletablet {
                display: flex;
                flex-flow: column;
                padding: 50px 8px 75px;
                margin: 0;
            }
        }
    }

    &__section {
        &--2 {
            color: $text-color-grey;
            font-size: 11px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1.38px;

            ul {
                display: flex;
                justify-content: center;
                flex-flow: row wrap;
                margin-bottom: 0;
                padding: 0 30px 25px;

                @include mobiletablet {
                    flex-flow: column;
                    padding: 0 28px 74px;
                }


                li {
                    position: relative;

                    &:before {
                        content: "\2022";
                        left: -2px;
                        position: absolute;
                    }

                    &:first-of-type {
                        &:before {
                            content: "";
                        }
                    }

                    @include mobiletablet {
                        &:before {
                            display: none;
                        }
                    }
                }

                p {
                    margin: 0;

                    @include mobiletablet {
                        font-size: 12px;
                        line-height: 1.75;
                        letter-spacing: 1.5px;
                        margin-bottom: 28px;
                    }
                }

                a {
                    color: $text-color-grey;
                    font-size: 11px;
                    font-weight: 500;
                    text-transform: uppercase;
                    padding: 0 2px;

                    @include mobiletablet {
                        font-size: 12px;
                        line-height: 1.75;
                        letter-spacing: 1.5px;
                    }
                }
            }

            .footer__logo {
                &--mobile {
                    display: none;

                    @include mobiletablet {
                        display: block;
                        text-align: center;
                        margin-bottom: 10px;
                        margin-right: 0;

                        img {
                            width: 96px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }

    &__column {
        align-items: flex-start;
        padding-left: 0;

        @include mobiletablet {
            display: flex;
            flex-flow: column;
            width: 100%;

            > div {
                padding: 0;
            }
        }

        &--1 {
            display: flex;

            @media (max-width: 991px) {
                flex-flow: wrap;
            }

            @include mobile {
                flex-flow: wrap;
            }

            .col-sm-9 {
                min-width: calc(209px + 30px);

                @media (max-width: 991px) {
                    width: 100%;
                    min-width: auto;
                    min-width: unset;
                    padding: 0;
                }
            }

            ul {
                @include mobiletablet {
                    margin-bottom: 28px;
                }

                li {
                    a {
                        color: $text-color-blue;
                        font-size: 22px;
                        line-height: 1.45;
                        font-weight: bold;

                        &:hover, &:focus {
                            text-decoration: none;
                        }

                        @include mobiletablet {
                            font-size: 18px;
                            line-height: 1.78;
                        }
                    }
                }
            }
        }

        &--2 {
            a {
                @include arrow-link($text-color-blue);
                color: $text-color-blue;
                font-size: 12px;
                display: flex;
                font-weight: bold;


                &:after {
                    margin-left: 5px;
                    display: inline;
                    background-image: url('/src/images/ArrowBlue.svg');
                    margin-top: 3px;
                }
            }

            p {
                @include mobiletablet {
                    display: none;
                }
            }

            h5 {
                @include mobiletablet {
                    margin-bottom: 0;
                    max-width: 90%;
                }
            }

            @include mobiletablet {
                flex-flow: row;
                justify-content: space-between;
                padding: 25px 0;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    border-top: 1px solid $border-pale-grey;
                    position: absolute;
                    width: 100%;
                    width: calc(100% + 56px);
                    top: 0;
                    left: -28px;
                }

                &:after {
                    content: "";
                    display: block;
                    border-bottom: 1px solid $border-pale-grey;
                    position: absolute;
                    width: 100%;
                    width: calc(100% + 56px);
                    bottom: 0;
                    left: -28px;
                }

                h5 {
                    position: absolute;
                    top: 18px;
                }

                .arrow-right {
                    font-size: 0;
                    width: 100%;
                    display: block;
                    height: 80%;
                    top: 10%;
                    position: absolute;
                    display: flex;
                    vertical-align: middle;
                    align-items: center;

                    &:after {
                        margin-left: 0;
                        margin-top: 0;
                        background-size: cover;
                        padding: 0;
                        width: 16px;
                        height: 16px;
                        align-self: center;
                        position: absolute;
                        display: block;
                        right: 0;
                    }
                }
            }
        }

        &--3 {
            a {
                @include arrow-link($text-color-blue);
                color: $text-color-blue;
                font-size: 12px;
                display: flex;
                font-weight: bold;


                &:after {
                    margin-left: 5px;
                    display: inline;
                    background-image: url('/src/images/ArrowBlue.svg');
                    margin-top: 3px;
                }
            }

            p {
                @include mobiletablet {
                    display: none;
                }
            }

            h5 {
                @include mobiletablet {
                    margin-bottom: 0;
                    max-width: 90%;
                }
            }

            @include mobiletablet {
                flex-flow: row;
                justify-content: space-between;
                padding: 25px 0;
                position: relative;


                &:after {
                    content: "";
                    display: block;
                    border-bottom: 1px solid $border-pale-grey;
                    position: absolute;
                    width: 100%;
                    width: calc(100% + 56px);
                    bottom: 0;
                    left: -28px;
                }

                h5 {
                    position: absolute;
                    top: 18px;
                }

                .arrow-right {
                    font-size: 0;
                    width: 100%;
                    display: block;
                    height: 80%;
                    top: 10%;
                    position: absolute;
                    display: flex;
                    vertical-align: middle;
                    align-items: center;

                    &:after {
                        margin-left: 0;
                        margin-top: 0;
                        background-size: cover;
                        padding: 0;
                        width: 16px;
                        height: 16px;
                        align-self: center;
                        position: absolute;
                        display: block;
                        right: 0;
                    }
                }

                > div {
                    display: none;
                }
            }

            .col-md-12 {
                padding: 0;
                margin-top: 45px;

                ul {
                    li {
                        a {
                            line-height: 1.83;
                        }
                    }
                }
            }
        }

        &--4 {
            display: none;

            @include mobiletablet {
                display: block;
                padding: 0;
                margin: 50px 0 20px;

                ul {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    margin-bottom: 0;

                    li {
                        margin: 0 25px 25px;

                        img {
                            height: auto;
                            width: 30px;
                            align-content: center;
                        }
                    }
                }
            }
        }

        h5 {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.5px;
            color: $text-color-blue;
            margin-top: 0;
        }

        p {
            font-size: 12px;
            line-height: 1.83;
        }
    }

    &__social-icons {
    }

    .social {
        @include mobiletablet {
            a, a:hover, a:focus {
                font-size: 0;
                text-decoration: none;

                &:before {
                    display: block;
                    color: $text-color-blue;
                    font-size: 30px;
                    font-family: "FontAwesome";
                }
            }



            &-linkedin {

                a:before {
                    content: "\f08c";
                }
            }

            &-instagram {


                a:before {
                    content: "\f16d";
                }
            }

            &-facebook {
                a:before {
                    content: "\f082";
                }
            }

            &-youtube {

                a:before {
                    content: "\f167";
                }
            }
        }
    }

    &__logo {
        margin-right: 25px;

        img {
            width: 80px;
        }

        @include mobiletablet {
            display: none;
        }
    }
}
