﻿.breadcrumb {
    background: transparent;
    padding: 0;
    z-index: 2;

    li {
        text-transform: uppercase;
        font-size: 10px;
        color: $text-color-blue;
        opacity: 0.47;

        @include mobiletablet {
            font-size: 8px;
        }


        a, a:hover, a:focus {
            color: $text-color-blue;

            @include mobiletablet {
                font-size: 8px;
            }
        }

        + li:before {
            padding: 0px 0px 0 3px;
            color: $text-color-blue;
        }
    }

    > .active {
        color: $text-color-blue;
    }
}
