﻿.p-productpage {
  .breadcrumb {


    @media (min-width: 992px) {
      margin-top: 155px;
      padding: 0 15px;
    }

    @media (min-width: 1200px) {
      padding: 0 8px;
    }

    @include mobiletablet {
      padding: 0;
      margin-top: 95px;
    }
  }

  .title-and-lead {
    padding: 100px 20px 0;

    @include mobiletablet {
      padding: 50px 20px 10px;
    }

    h1 {
      font-size: 100px;
      margin-bottom: 50px;

      @include mobiletablet {
        font-size: 40px;
        margin: 5px 0;
      }
    }

    h3 {
      @include mobiletablet {
        margin: 0 0 15px;
      }
    }

    p {
      font-weight: 500;

      @include mobile {
        font-weight: bold;
      }
    }
  }

  .content-shading-seventyfrombottom {
    @include mobile {
      background: linear-gradient(180deg, white 30%, white 30%, #eff1f5 0%);
    }
  }

  .content-shading-thirtyfromtop {
    @include mobiletablet {
      background: linear-gradient(0deg, rgba(255, 255, 153, 0) 0, rgba(41, 137, 216, 0) 0%, #eff1f5 0, #eff1f5);
    }
  }

  .video-block {
    padding-right: 20px;
    padding-left: 20px;
    max-width: none;
    @media (min-width: 768px) {
      padding-right: 28px;
      padding-left: 28px;
    }
    @media (min-width: 1024px) {
      padding: 90px 85px 80px;
    }

    .embed-responsive {
      padding: 37.25% 0px 0px;
      position: relative;
    }

    h3 {
      text-align: center;
    }

    span {
      display: inline-block;
      padding-top: 0.4rem;
      color: $text-color-white;
    }
  }
}
