﻿.title-lead-and-image {
    overflow: hidden;

    &__container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;

        @include mobiletablet {
            flex-flow: wrap;
            justify-content: center;
            align-items: normal;
            margin: 20px 28px 70px;
        }
    }

    &__content {
        margin-right: 133px;
        margin-left: 110px;
        min-width: 557px;
        max-width: 557px;

        @media (max-width: 1599px) {
            margin-right: 8%;
        }

        @media (max-width: 1300px) {
            margin-left: 60px;
        }

        @media (max-width: 1199px) {
            margin-left: 60px;
            margin-right: 15%;
            min-width: 440px;
            max-width: 440px;
        }

        @include mobiletablet {
            min-width: auto;
            min-width: unset;
            width: 100%;
            margin: 0;
            display: flex;
            flex-flow: wrap;
            justify-content: center;
        }
    }

    h3 {
        font-size: 35px;
        line-height: 1.43;
        color: $text-color-blue;

        @include mobiletablet {
            font-size: 25px;
            line-height: 1.36;
            text-align: center;
        }
    }

    .btn-default {
        @include button-default($text-color-blue);
        margin: 45px 0 30px;

        @include mobiletablet {
            margin: 35px 0;
        }

        &:after {
            display: none;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.63;
        margin: 50px 0 6px;

        @include mobiletablet {
            margin: 20px 0 0;
        }
    }

    &__img {
        width: 50vw;

        @media (max-width: 1599px) {
            max-width: 50vw;
        }

        @include mobiletablet {
            max-width: 100%;
            max-width: unset;
            width: 100%;
        }

        img {
            object-fit: fill;
            height: 100%;
            width: 100%;


            @include mobiletablet {
                max-width: auto;
                max-width: unset;
            }
        }
    }
}
