﻿.product-multiple-ar-block {
    padding: 96px 65px 108px;

    @include tablet {
        padding: 50px 28px 100px;
    }

    @include mobile {
        padding: 50px 20px 100px;
    }


    ul {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-top: 80px;

        @include mobile {
            margin-top: 50px;
        }

        li {
            display: flex;
            flex-flow: column;
            background: $background-white;
            border: 1px solid $border-pale-grey;
            box-shadow: 2px 0px 5px -1px rgba(0, 0, 0, 0.11);
            width: 25%;

            @include mobile {
                width: 50%;
                box-shadow: none;
            }

            a {
                position: relative;
                height: 100%;
                width: 100%;

                h6 {
                    font-size: 17px;
                    font-weight: bold;
                    line-height: 1.88;
                    text-align: center;
                    color: $text-color-blue;
                    margin: 0 auto;
                    width: 100%;
                    padding: 15px;
                    position: absolute;
                    bottom: 0;

                    @include mobile {
                        padding: 5px;
                        font-size: 10px;
                        line-height: 2.8;
                    }
                }

                img {
                    width: 100%;
                    padding: 8rem;

                    @media (max-width: 1200px) {
                        padding: 6rem;
                    }

                    @include mobile {
                        padding: 3.5rem;
                    }
                }

                @supports (-webkit-overflow-scrolling: touch ) {
                    &:before {
                        content: "";
                        background: url('/src/images/ARKit_Glyph.svg');
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                        width: 80px;
                        height: 32px;
                        position: absolute;
                        left: 0px;
                        top: 20px;

                        @include mobiletablet {
                            left: 0px;
                            top: 5px;
                            width: 65px;
                            height: 25px;
                        }
                    }
              }
            }
        }
    }

    &__intro {
        text-align: center;
        max-width: 950px;
        margin: 0 auto;
        display: block;
    }

    &__experience {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        max-width: 50%;
        margin: 0 auto;
        position: relative;
        margin-top: 70px;

        @include mobile {
            max-width: 100%;
            margin-top: 25px;
        }

        > div {
            width: 100%;
            padding-left: 100px;

            @include mobile {
                padding-left: 0;
            }
        }

        &-heading {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.5px;
            color: $text-color-blue;
            width: 100%;

            @include mobile {
                line-height: 1.5;
                text-transform: uppercase;
                text-align: center;
                max-width: 97%;
                margin: 0 auto;
            }
        }

        &-lead {
            font-size: 13px;
            font-weight: normal;
            line-height: 1.46;
            color: $text-color-darkgrey;
            width: 100%;

            @include mobile {
                text-align: center;
                margin-top: 5px;
            }
        }

        &-icon {
            display: block;
            width: 51px;

            @include mobile {
                height: 51px;
                margin-bottom: 10px;
            }

            &:before {
                content: "";
                background: url('/src/images/productAr.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 51px;
                height: 51px;
                position: absolute;
                left: 40px;

                @include mobile {
                    left: initial;
                    left: unset;
                }
            }
        }
    }


    &__heading {
        font-size: 35px;
        font-weight: bold;
        line-height: 1.29;
        color: $text-color-blue;
        margin-bottom: 0;

        @include mobile {
            font-size: 25px;
            font-weight: bold;
            line-height: 1.38;
            max-width: 94%;
            margin: 0 auto;
        }
    }

    &__lead {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.63;
        margin-top: 30px;

        @include mobile {
            font-size: 14px;
            line-height: 1.71;
            margin-top: 25px;
        }
    }
}
