﻿.cta-section {
    display: flex;
    gap: 80px;
    padding: 80px 20px;
    justify-content: space-between;

    @include mobiletablet {
        flex-direction: column !important;
        align-items: center;
    }

    .text-section {
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: start;
        width: 100%;

        font-size: 16px;
        line-height: 26px;

        &__title {
            color: $text-color-blue;
        }

        &__media {
            width: 100%;

            @include desktop {
                max-width: 50vw;
            }
            
            > img, > video {
                width: 100%;
                object-fit: cover;
            }
        }

        &__caption-container {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
        }

        &__caption-title, &__caption-text {
            font-size: 14px;
            margin: 0;
        }

        &__caption-title {
            color: $text-color-blue;
            font-weight:$font-header-weight;
        } 

        &__caption-text {
            color: $text-color-darker-grey;
            font-weight:$font-body-weight;
        }

        @include mobiletablet {
            align-items: center;
        }

        &__cards > div {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .card-block {
            > div {
                > h4, > p {
                    font-size: 16px;
                    line-height: 26px;                    
                }
            }
        }
    }
    
    .btn-primary {
        @include button-primary($background-orange, $text-color-white);
        margin: 0 auto;
        font-weight: 500;
        line-height: 24px;
    }
}