﻿header {
	z-index: 999;
	position: sticky;
	top: -100px;
	transition-property: all;
	transition-duration: 0.2s;
	transition-timing-function: ease-out;

	&.scrolled {
		top: 0;
		-webkit-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
		-moz-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
		box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
	}

	&:hover {
		top: 0;
	}
}

.nav__header {
	color: black;
	position: relative;
	background-color: #fff;
	color: #000;
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
}

.nav__header ul {
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 0;
	list-style: none;
}

.nav__col {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.nav__header .expand {
	flex: 1;
}

.nav__header .mobile__lang {
	display: none;
	svg {
		display: none;
	}
}

.lvl-1 {
	height: 100%;
	display: flex;
	gap: 0px;
}

.lvl-1 > li {
	height: 100%;
}

.lvl-1 > li > a {
	font-weight: 700;
	display: flex;
	height: 100%;
	align-items: center;
	text-decoration: none;
	padding: 20px 2px;
}

.lvl-1 > li > a span {
	color: #000;
	padding: 9px 14px;
	transition: background-color 0.1s linear;
}

.lvl-1 > li.active .lvl-2,
.lvl-1 > li:hover .lvl-2 {
	opacity: 1;
	pointer-events: auto;
}

.lvl-2 {
	position: absolute;
	pointer-events: none;
	left: 0;
	width: 100%;
	opacity: 0;
	transition: visibility 0s, opacity 0.1s linear;
	background-color: #cacaca;
	z-index: 12;
}

.lvl-2 > .items {	
	display: flex;
	flex-wrap: wrap;
	gap: 40px 8px;
	justify-content: center;
	align-items: stretch;
	text-align: left;
	padding: 20px;
}

.lvl-2 > .items.col-3 {
	grid-template-columns: repeat(3, 0fr);
	display: grid;
}

.lvl-2 .content {
	display: flex;
	gap: 80px;
}

.lvl-2 h3 {
	padding: 0;
	margin: 0;
	font-size: 14px;
	margin-bottom: 20px;
	text-transform: uppercase;
	color: $text-color-blue;
}

.lvl-2 h4 {
	margin: 0;
	padding: 0;
	font-size: 12px;
	margin-bottom: 10px;
	font-weight: 300;
}

.lvl-2 h4:empty {
	display: none;
}

.lvl-2 .items > .item {
	background-color: white;
	padding: 25px;
}

.lvl-2 .items > .item ul {
	display: flex;
	flex-direction: column;
}

#weglotLangList {
	ul {
		columns: 2 !important;
		display: block !important;
		@include mobiletablet {
			display: flex !important;
			flex-direction: column !important;
		}
	}
}

.lvl-2 .items > .item a {
	text-decoration: none;
	padding: 8px 0;
	display: block;
}

.lvl-2 .item__col > .item:first-child a {
	padding-top: 0;
}

.nav__header .btn__search {
	margin-top: 4px;
}

.nav__col.options {
	display: flex;
	gap: 25px;
}

.nav__col.options .lvl-1__lang {
	margin-left: -20px;
	cursor: pointer;
}

.nav__col.options .lvl-1__lang > li span {
	padding-left: 15px;
	padding-right: 15px;
	font-weight: 500;
}

.nav__col.options .lvl-1__lang > li svg {
	position: relative;
	top: -1px;
	left: 5px;
}

.nav__col.options .lvl-1__lang > li:first-child span {
	text-transform: capitalize;
}

.nav__col.options .lvl-1__lang .lvl-2 {
	left: auto;
	right: 0;
	width: auto;
}

.nav__header .btn_open-mobile-menu {
	display: none;
}

.nav__header .btn_close-mobile-menu {
	display: none;
}

.nav__header .back {
	display: none;
}

/*
    Theme
*/
header {
	background-color: #fff;
}

.theme--blue .header__logo {
	width: 125px;
}

.theme--blue .header__logo img {
	width: 100%;
}

.theme--blue .nav__header {
	color: #274476;
}

.theme--blue .lvl-1 > li > a span {
	color: #274476;
	border-radius: 20px;
}

.theme--blue .lvl-1 > li:hover > a span,
.theme--blue .lvl-1 > li.active > a span {
	background-color: $text-color-whiteish;
}

.theme--blue .lvl-2 {
	background-color: #eef1f4;
	-webkit-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
	-moz-box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
	box-shadow: 0px 10px 5px -5px rgba(0, 0, 0, 0.14);
}

.theme--blue .lvl-2 .items.theme--lead {
	max-width: 1500px;
	margin: 0 auto;
}

.theme--blue .lvl-2 .items.theme--lead .item {
	flex: 1 0 21%;
	min-width: 230px;
	padding: 0;
	color: #274476;
	text-decoration: none;
}

.theme--blue .lvl-2 .items.theme--lead .item img {
	width: 100%;
	height: 180px;
	display: block;
	object-fit: cover;
}

.theme--blue .lvl-2 .items.theme--lead .box {
	padding: 20px 25px;
}

.theme--blue .lvl-2 .items.theme--lead h3 {
	margin-bottom: 0;
}

.theme--blue .lvl-2 .items.theme--lead h3:empty {
	display: none;
}

.theme--blue .lvl-2 .items.theme--lead .box .text {
	margin-top: 10px;
	color: $text-color-black;
}

.theme--blue .lvl-2 .items.theme--lead .box .text:empty {
	margin-top: 0;
}

.theme--blue .button {
	a {
		padding: 7px 24px;
		background: #274476;
		border-radius: 20px;
		color: white;
		display: inline-block;
	}
}

.header.active .nav__header .header__logo {
	filter: brightness(0) invert(1);
}

/*
    Mobile
*/
@include mobiletablet {
	header {
		position: fixed;
		z-index: 12;
		width: 100%;
		top: 0;
	}

	body.lvl-1-open #CookiebotWidget {
		display: none;
	}

	body.lvl-1-open .nav__header {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto 1fr auto;
		background: #eef1f4;
		padding: 0;
		height: 100vh;
		height: 100dvh;
	}

	.nav__header {
		padding: 0px;
	}

	.nav__header .lvl-1 > li.active .lvl-2 {
		opacity: 1;
		pointer-events: auto;
	}

	.nav__header .header__logo {
		margin-left: 17px;
		width: 77px;
	}

	.nav__col.options {
		gap: 17px;
	}

	.nav__header .nav__col.expand {
		display: none;
	}

	body.lvl-1-open .nav__header .nav__col.expand {
		display: flex;
	}

	.nav__col.options .lvl-1__lang {
		display: none;
	}

	.nav__header > .nav__col:first-child {
		justify-content: left;
	}

	.nav__header > .nav__col:last-child {
		justify-content: right;
	}

	.nav__header > .nav__col:nth-child(1) {
		grid-column: 1 / 2;
		grid-row: 1;
	}

	.nav__header > .nav__col:nth-child(2) {
		grid-column: 1 / -1;
		grid-row: 2;
		overflow: auto;
		overscroll-behavior: contain;
	}

	.nav__header > .nav__col:nth-child(3) {
		grid-column: 2 / 3;
		grid-row: 1;
	}

	.nav__header.lvl-2-open > .nav__col:nth-child(1),
	.nav__header.lvl-2-open > .nav__col:nth-child(3) {
		height: 0;
	}

	.nav__header > .nav__col.expand .lvl-1 {
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.nav__header > .nav__col .lvl-1 > li {
		height: auto;
	}

	.header.active .nav__header .nav__col:not(.expand) {
		background-color: #274476;
	}

	.header.active .nav__header .header__logo {
		filter: brightness(0) invert(1);
	}

	.header.active .nav__header .options > * {
		display: none;
	}

	.nav__header .btn_open-mobile-menu {
		display: block;
		padding: 17px;
		padding-top: 20px;
		padding-bottom: 15px;
		padding-left: 0px;
		color: white;
	}

	.header.active .nav__header .btn_close-mobile-menu {
		cursor: pointer;
		display: block;
		padding: 17px;
		color: white;
	}

	.header.active .nav__header .btn_close-mobile-menu svg {
		position: relative;
		top: 3px;
	}

	.nav__header .lvl-1 > li.active > a {
		position: sticky;
		top: 0;
		background-color: $text-color-whiteish;
	}

	.nav__header .lvl-1 > li:hover .lvl-2 {
		display: none;
	}

	.nav__header .lvl-1 > li.active:hover .lvl-2 {
		display: block;
	}

	.lvl-2 > .items {
		flex-direction: column;
		padding: 16px;
	}

	.lvl-1 > li.active {
		width: 100%;
		height: auto;
		background-color: #eef1f4;
	}

	.nav__header > .nav__col .lvl-1 > li:last-child {
		padding-bottom: 20px;
	}

	.nav__header.lvl-2-open .nav__col .lvl-1 > li:not(.active) {
		display: none;
	}

	.lvl-1 > li.active .lvl-2 .content {
		flex-direction: column;
		gap: 27px;
		width: 100%;
	}

	.lvl-1 > li > a {
		position: relative;
		height: auto;
	}

	.lvl-1 > li > a span::after {
		content: "";
		display: inline-block;
		background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.352252 0.477252C0.571922 0.257582 0.928078 0.257583 1.14775 0.477252L6.50746 5.83697C6.50756 5.83706 6.50765 5.83716 6.50775 5.83725C6.59476 5.92421 6.6638 6.02747 6.71093 6.14112C6.7581 6.25489 6.78238 6.37684 6.78238 6.5C6.78238 6.62316 6.7581 6.74511 6.71093 6.85888C6.66375 6.97266 6.59461 7.07601 6.50746 7.16303L6.11 6.765L6.50775 7.16275L6.50746 7.16303L1.14775 12.5227C0.928078 12.7424 0.571922 12.7424 0.352252 12.5227C0.132583 12.3031 0.132582 11.9469 0.352252 11.7273L5.5795 6.5L5.71225 6.63275C5.6949 6.61535 5.68113 6.59472 5.67171 6.57201C5.66225 6.54918 5.65738 6.52471 5.65738 6.5C5.65738 6.47529 5.66225 6.45082 5.67171 6.42799C5.68113 6.40528 5.6949 6.38465 5.71225 6.36725L5.5795 6.5L0.352252 1.27275C0.132582 1.05308 0.132583 0.696922 0.352252 0.477252Z' fill='%23274476'/%3E%3C/svg%3E%0A");
		width: 7px;
		height: 13px;
		position: absolute;
		right: 19px;
	}

	.lvl-1 > li > a span {
		padding: 13px;
		padding-left: 17px;
		width: 100%;
		text-align: left;
	}

	.lvl-1 > li:first-child > a > span {
		padding-top: 22px;
	}

	.lvl-1 > li.active > a {
		padding: 24px 16px;
	}

	.lvl-1 > li.active > a span::after {
		display: none;
	}

	.lvl-1 > li.active > a span {
		padding: 0;
		font-weight: 700;
	}

	.lvl-1 > li.active > a span::before {
		content: "";
		display: inline-block;
		background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1405_6904)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.52275 0.477252C8.74242 0.696922 8.74242 1.05308 8.52275 1.27275L3.29549 6.5L8.52275 11.7273C8.74242 11.9469 8.74242 12.3031 8.52275 12.5227C8.30308 12.7424 7.94692 12.7424 7.72725 12.5227L2.36753 7.16303C2.36751 7.163 2.36748 7.16297 2.36745 7.16294C2.36738 7.16288 2.36732 7.16281 2.36725 7.16275C2.28024 7.07578 2.2112 6.97253 2.16407 6.85888C2.1169 6.74511 2.09262 6.62316 2.09262 6.5C2.09262 6.37684 2.1169 6.25489 2.16407 6.14112C2.2112 6.02747 2.28024 5.92422 2.36725 5.83725C2.36735 5.83716 2.36744 5.83706 2.36753 5.83697L7.72725 0.477252C7.94692 0.257583 8.30308 0.257583 8.52275 0.477252ZM3.16275 6.63275C3.16271 6.63279 3.16267 6.63282 3.16263 6.63286L3.16275 6.63275Z' fill='%23274476'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1405_6904'%3E%3Crect width='12' height='12' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
		width: 12px;
		height: 12px;
		margin-right: 16px;
	}

	.lvl-1 > li.active .lvl-2 {
		position: inherit;
	}

	.lvl-1 > li.active .lvl-2 > .items {
		padding: 0 16px;
	}

	.lvl-1 > li > a {
		padding: 0;
	}

	.theme--blue .lvl-2 .items.theme--lead .item {
		width: 100%;
	}

	.lvl-2 > .items.col-3 {
		display: flex;
	}

	.nav__header .btn__search {
		margin-top: 4px;
	}

	.nav__header.header {
		overflow: auto;
		overscroll-behavior: contain;
	}

	.theme--blue .lvl-2 {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}

	.nav__header .mobile__lang {
		display: block;
		& > a {
			cursor: pointer;
		}
	}

	.nav__header .mobile__lang > a span {
		text-transform: capitalize;
	}
}
