﻿.p-industrypage {


    .title-and-lead {
        @include mobile {
            padding: 50px 20px 25px;
        }
    }

    .title-and-lead__container p {
        @include mobile {
            font-weight: bold;
        }
    }

    .content {
        &-part {
            @include container-fixed;

            @media (min-width: $screen-sm-min) {
                width: $container-sm;
            }

            @media (min-width: $screen-md-min) {
                width: $container-md;
            }

            @media (min-width: $screen-lg-min) {
                width: $container-lg;
            }


            &--1 {
                column-count: 2;
                column-gap: 95px;
                max-width: 1090px;

                > p {
                    font-size: 16px;
                    line-height: 1.63;
                }

                @media (max-width: 1200px) {
                    column-gap: 0;

                    > p {
                        padding: 0 50px 10px;
                    }
                }

                @include mobile {
                    column-count: 1;
                    column-gap: normal;

                    > p {
                        padding: 0 0 20px;
                        line-height: 1.71;
                    }
                }
            }

            &--2, &--3 {
                padding: 80px 0;

                @include tablet {
                    padding: 80px 0 40px;
                }

                @include mobile {
                    padding: 50px 0;
                }

                > h1, h2, h3, h4, h5, h6 {
                    color: $text-color-blue;
                    font-size: 55px;
                    font-weight: bold;
                    line-height: 1.15;
                    letter-spacing: normal;
                    text-align: center;

                    @include tablet {
                        font-size: 3rem;
                    }

                    @include mobile {
                        font-size: 25px;
                        line-height: 1.38;
                        margin: 0;
                    }
                }
            }
        }

        &-area {
            article {
                max-width: 1090px;
            }

            .image-block {
                display: flex;
                flex-flow: row;

                > div {
                    width: 100%;
                    text-align: center;

                    img {
                        max-width: 100%;
                    }
                }

                &.two {
                    > div {
                        width: calc(50% - 15px);
                        margin-right: 15px;

                        &:last-child {
                            margin-left: 15px;
                            margin-right: 0;
                        }

                        @include mobile {
                            width: calc(50% - 5px);
                            margin-right: 5px;

                            &:last-child {
                                margin-left: 5px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .column {

                p {
                    font-size: 16px;
                    line-height: 1.63;

                    @include mobile {
                        font-size: 14px;
                        line-height: 1.71;
                    }
                }

                h1, h2, h3, h4, h5, h6 {
                    color: $text-color-blue;
                }


                .section-title {
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 1.5px;
                    color: $text-color-orange;
                    text-transform: uppercase;
                    margin-bottom: 25px;

                    @include mobile {
                        text-align: center;
                        margin: 50px 0 15px;
                    }
                }

                blockquote {
                    font-size: 16px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    text-align: left;
                    color: $text-color-blue;
                    border: 0;
                    padding: 0;
                    margin: 25px 0;

                    @include mobile {
                        font-size: 25px;
                        font-weight: 300;
                        line-height: 1.38;
                        text-align: center;
                        color: $text-color-orange;
                        margin: 50px 0 25px;
                    }

                    &:before {
                        content: none
                    }

                    &:after {
                        content: none;
                    }
                }
            }

            a {
                color: $text-color-orange;
            }

            &--1 {
                margin-top: 300px;

                @media (max-width: 1200px) {
                    margin-top: 250px;
                }


                @include mobiletablet {
                    margin-top: 140px;
                }

                article {
                    padding: 90px 0;

                    @media (max-width: 1200px) {
                        padding: 90px 20px;
                    }

                    @include mobile {
                        padding: 0 20px;
                    }

                    > div {
                        display: flex;
                        flex-flow: wrap;
                        margin: 0 -50px;

                        @media (max-width: 1200px) {
                            margin: 0;
                        }
                    }
                }

                .column {
                    width: 50%;
                    padding: 0 50px;

                    @include tablet {
                        width: 100%;
                    }

                    @include mobile {
                        width: 100%;
                        padding: 0;
                    }

                    img {
                        width: 100%;
                        display: block;
                    }

                    ul {
                        li {
                            font-size: 16px;
                            line-height: 1.63;
                        }
                    }


                    ol {
                        list-style: none;
                        padding-left: 0;
                        margin-bottom: 55px;

                        @include mobile {
                            margin-bottom: 50px;
                        }

                        li {
                            font-size: 10px;
                            font-weight: 500;
                            line-height: 1.5;
                            letter-spacing: 1.25px;
                            color: $text-color-grey;
                            text-transform: uppercase;
                        }

                        > li {
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;

                            @include mobile {
                                flex-flow: column;
                                justify-content: center;
                            }

                            img {
                                width: 92px;
                                height: auto;
                                border: 1px solid $border-blue;
                                border-radius: 50%;
                                margin-right: 25px;

                                @include mobile {
                                    margin-right: 0;
                                }
                            }



                            ol {
                                margin: 10px 0;

                                @include mobile {
                                    margin: 20px 0 0;
                                }
                            }
                        }
                    }


                    &--1 {
                        position: relative;
                        margin-bottom: 140px;
                        min-height: calc(376px - 105px);

                        @include tablet {
                            top: 0;
                            width: 100%;
                            margin-bottom: 40px;
                            height: calc(500px - 185px);
                        }


                        @include mobile {
                            top: 0;
                            width: 100%;
                            margin-bottom: 0;
                            height: calc(376px - 105px);
                        }

                        div {
                            background-position: center;
                            background-size: cover;
                            background-repeat: no-repeat;
                            position: absolute;
                            width: 100%;
                            height: calc(100% + 250px);
                            top: -250px;
                            left: -30px;

                            @media (max-width: 1300px) {
                                left: 50px;
                                width: calc(100% - 60px);
                            }

                            @media (max-width: 1200px) {
                                width: 100%;
                                left: 0;
                                height: calc(100% + 194px);
                                top: -194px;
                            }

                            @include tablet {
                                top: -185px;
                                height: calc(100% + 185px);
                            }

                            @include mobile {
                                top: -105px;
                                height: calc(100% + 105px);
                            }
                        }
                    }

                    &--2 {
                        @include tablet {
                            margin-top: 10px;
                        }

                        @include mobile {
                            margin-top: 10px;
                        }

                        h1, h2, h3, h4, h5, h6 {
                            line-height: 1.14;
                            margin-bottom: 30px;
                            font-size: 35px;

                            @include tablet {
                                font-size: 3rem;
                            }

                            @include mobile {
                                font-size: 25px;
                                line-height: 1.38;
                                text-align: center;
                            }
                        }
                    }

                    &--3, &--4 {
                        @include tablet {
                            margin-top: 40px;
                        }

                        h1, h2, h3, h4, h5, h6 {
                            line-height: 1.33;
                            margin-bottom: 30px;
                            font-size: 18px;


                            @include mobile {
                                text-align: left;
                                font-size: 18px;
                                font-weight: bold;
                                line-height: 1.56;
                                margin-bottom: 10px;
                                margin-top: 0;
                            }
                        }

                        p {
                            @include mobile {
                                margin-bottom: 50px;
                            }
                        }
                    }
                }
            }

            &--2, &--3 {
                article {
                    padding: 50px 0 180px;

                    @include tablet {
                        padding: 50px 0;
                    }

                    @include mobile {
                        padding: 0;
                    }

                    > div {
                        display: flex;
                        flex-flow: row wrap;
                        align-items: flex-end;
                        justify-content: space-between;

                        @media (max-width: 1200px) {
                            padding: 0 50px;
                        }

                        @include mobile {
                            padding: 0 20px;
                        }
                    }
                }

                .column {
                    ul {
                        list-style: none;
                        padding-left: 0;

                        li {
                            margin: 15px 0 15px 15px;
                            font-size: 16px;

                            @include mobile {
                                font-size: 14px;
                            }

                            &:before {
                                content: '\f105';
                                font-family: "FontAwesome";
                                display: inline-block;
                                margin-left: -1em;
                                width: 1em;
                                color: $text-color-orange;
                            }
                        }
                    }




                    img {
                        width: 100%;
                        display: block;
                    }

                    h2, h2, h3, h4, h5, h6 {
                        line-height: 1.31;
                        font-size: 35px;

                        @include tablet {
                            font-size: 3rem;
                        }

                        @include mobile {
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 1.56;
                            margin: 10px 0 15px;
                        }
                    }


                    .section-title {
                        @include mobile {
                            margin: 0;
                            text-align: left;
                        }
                    }

                    &--1 {
                        width: calc(50% - 25px);
                        margin-right: 25px;

                        @include tablet {
                            margin-right: 0;
                            width: 100%;
                            margin-top: 50px;

                            img {
                                margin: 0 auto;
                                max-width: 558px;
                            }
                        }

                        @include mobiletablet {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 50px;
                            order: 2;
                        }
                    }

                    &--2 {
                        max-width: 470px;
                        width: 50%;

                        @include tablet {
                            max-width: auto;
                            max-width: unset;
                            width: 100%;
                        }

                        @include mobile {
                            width: 100%;
                            margin-bottom: 50px;
                        }
                    }

                    &--img-right {
                        order: 2;
                        margin-left: 25px;
                        margin-right: 0;

                        @include tablet {
                            margin-left: 0;
                        }

                        @include mobile {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
}
