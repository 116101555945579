.slidercontainerblock {
    padding: 80px 0;
    .swiper-button-next,.swiper-button-prev {
        color: white;
        right: 20px;
        background-color: #274476;
        padding: 10px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
    .swiper-button-next::after,.swiper-button-prev::after{
        font-size: 20px !important;
    }
    .swiper-container {
        min-height: 30vh;
    }
    .swiper-wrapper {
        height: unset;
    }
    .swiper-pagination-progressbar {
        top: unset;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        max-width: 789px;
        background: #C3D7EE;
        --swiper-theme-color: #274476;
        height: 8px;
        border-radius: 8px;
    }
    .swiper-pagination-progressbar-fill{
        border-radius: 8px;
    }

    .card-block h4 {
        text-transform: uppercase;
    }
}