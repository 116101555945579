﻿.p-articleitempage, .p-standardcontentpage {
    .article {
        padding: 240px 59px 0;

        @include mobiletablet {
            padding: 150px 20px 0;
        }

        .breadcrumb {
            @media (min-width: 992px) {
                margin: -100px 0 50px -25px;
            }

            @media (min-width: 1200px) {
                margin: -100px 0 50px -50px;
            }

            @include mobiletablet {
                margin: -50px 0 50px 0;
            }
        }

        .hero-background {
            &__heading {
                max-width: 900px;
                margin: 0 auto;
            }

            &__page-name {
                margin-bottom: 30px;
            }

            &__container {
                .embed-responsive {
                    height: 70vh;
                }
            }

            + .title-and-lead {
                padding: 90px 20px 50px;

                @include mobile {
                    padding: 50px 0 50px;
                }
            }
        }

        .title-and-lead {
            &__container {
                @include mobile {
                    padding: 15px 0 20px;
                }

                p {
                    font-weight: 500;

                    @include mobile {
                        font-weight: bold;
                        padding: 15px 0 0px;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__content {
            margin: 0 auto 50px;
            max-width: 900px;

            p {
                line-height: 1.71;
                margin: 0 0 30px;

                @include mobile {
                    margin: 0 0 25px;
                }
            }

            h1, h2, h3, h4, h5, h6 {
                color: $text-color-blue;
                margin-bottom: 30px;

                @include mobile {
                    margin-bottom: 25px;
                    margin-top: 50px;
                }
            }

            h1, h2, h3 {
                line-height: 1.29;

                @include mobile {
                    font-size: 25px;
                    font-weight: bold;
                    line-height: 1.38;
                }
            }

            h4, h5, h6 {
                line-height: 1.29;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            blockquote {
                font-size: 35px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: $text-color-orange;
                max-width: 850px;
                margin: 0 auto;

                @include mobile {
                    font-size: 25px;
                    line-height: 1.38;
                }
            }
        }

        &__block {
            margin: 0 auto;
            max-width: 1145px;
            padding: 40px 0 60px;

            @include mobile {
                padding: 0px 0 40px;
            }

            p {
                line-height: 1.71;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            .caption {
                display: block;
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.83;
                margin-top: 5px;
            }

            blockquote {
                font-size: 35px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.29;
                letter-spacing: normal;
                text-align: center;
                color: $text-color-orange;
                max-width: 850px;
                margin: 0 auto;

                @include mobile {
                    font-size: 25px;
                    line-height: 1.38;
                }

                + .author {
                    color: $text-color-grey;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    margin-top: 10px;
                    text-align: center;
                    display: block;
                }
            }

            .image-block {
                display: flex;
                flex-flow: row;

                > div {
                    width: 100%;
                }

                &.two {
                    > div {
                        width: calc(50% - 15px);
                        margin-right: 15px;

                        &:last-child {
                            margin-left: 15px;
                            margin-right: 0;
                        }

                        @include mobile {
                            width: calc(50% - 5px);
                            margin-right: 5px;

                            &:last-child {
                                margin-left: 5px;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .video-block {
                height: 65vh;
                max-height: 650px;
                margin-bottom: 20px;

                .embed-responsive {
                    height: 100%;
                }

                .video-with-overlay__video {
                    z-index: 0;
                }
            }

            > div {
                > div {
                    margin: 0 0 90px 0;

                    @include mobiletablet {
                        margin: 0 0 50px 0;
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        &.no-hero-bg {
            padding: 113px 59px 0;

            @include mobiletablet {
                padding: 55px 20px 0;
            }

            .breadcrumb {
                @media (min-width: 992px) {
                    margin: 30px 0 0 -25px;
                }

                @media (min-width: 1200px) {
                    margin: 30px 0 0 -50px;
                }

                @include mobiletablet {
                    margin: 30px 0 0 0;
                }
            }

            .title-and-lead {
                padding-top: 0;

                @include mobiletablet {
                    padding: 35px 0px 50px;
                }

                h1 {
                    font-size: 100px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.1;

                    @include mobile {
                        line-height: normal;
                        font-size: 40px;
                    }
                }

                &__sectionname {
                    line-height: 1.83;

                    @include mobile {
                        letter-spacing: 1.5px;
                    }
                }

                &__container {
                    padding: 40px 0 0;
                    margin-bottom: 0;

                    p {
                        padding: 40px 20px 0;
                        margin-bottom: 0;

                        @include mobiletablet {
                            padding: 15px 0px 0;
                        }
                    }
                }
            }
        }
    }

    .news-signup {
        background: $background-pale-grey;
        margin-top: 2px;
        padding: 70px 0;

        & > .container {
            & > div {
                width: 100%;
            }

            .formcontainerblock {
                width: 100%;

                .EPiServerForms {
                    margin: 0;
                    padding: 0;
                }
            }
        }

        @include mobile {
            margin-top: 0;
        }
    }
}

.recent-news {
    background-color: $background-pale-grey;
    padding: 75px 0 60px;
    margin-top: 100px;

    @include mobile {
        padding: 50px 30px;
        margin-top: 0;
    }

    &__heading {
        color: $text-color-orange;
        text-transform: uppercase;
        text-align: center;
        padding: 0 20px 50px;
        margin: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: normal;

        @include mobile {
            padding: 0 0 50px;
        }
    }

    &__list {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-bottom: 0;
        padding: 0 55px;
        list-style: none;

        @media (max-width: 1599px) {
            padding: 0;
        }

        @media (max-width: 1250px) {
            padding: 0 20px;
        }

        @include mobile {
            padding: 0;
        }

        li {
            display: flex;
            flex-flow: row;
            width: calc(33.333% - 30px);
            align-items: center;
            margin-right: 30px;

            @include mobile {
                width: 100%;
                margin-right: 0;
                margin-bottom: 30px;
            }

            img {
                width: 100%;
                height: auto;
                display: block;
            }

            &:last-child {
                margin-right: 0;
                width: 33.333%;

                @include mobile {
                    width: 100%;
                    margin-bottom: 0;
                }
            }

            > div {
                + div {

                    @include mobiletablet {
                        display: flex;
                        flex-flow: column;
                    }

                    a {
                        @include mobiletablet {
                            order: 2;
                        }
                    }
                }
            }
        }
    }

    &__img {
        a {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            height: 150px;
            width: 150px;
            display: block;
            margin-right: 25px;
            position: relative;

            @include mobiletablet {
                width: 105px;
                height: 105px;
                margin-right: 17px;
            }

            @include mobile {
                border-left: 5px solid $border-blue;
            }

            .playvideo {
                position: absolute;
                width: 50px;
                height: 50px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                min-width: unset;
                max-width: unset;

                @include desktop {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    a, a:hover, a:focus, a:active {
        outline: 0;
        text-decoration: none
    }

    &__category-name {
        font-size: 10px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.25px;
        color: $text-color-grey;
        text-transform: uppercase;
        display: block;

        @include mobiletablet {
            order: 3;
        }
    }

    &__date {
        display: none;

        @include mobiletablet {
            display: block;
            font-size: 10px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1.25px;
            color: $text-color-grey;
            text-transform: uppercase;
            order: 1;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        color: $text-color-blue;

        @include mobiletablet {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.34;
            letter-spacing: 1.5px;
        }
    }

    &__intro {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.83;
        letter-spacing: normal;
        margin-bottom: 0;

        @include mobiletablet {
            display: none;
        }
    }
}
