﻿.calltoaction-block {
    padding: 50px;
    min-height: 230px;

    @include mobile {
        padding: 35px 0 70px;
    }

    &__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        @include mobile {
            flex-flow: column;
        }
    }

    &__content {
        max-width: 590px;
        width: 50%;

        @include mobile {
            text-align: center;
            width: 100%;
        }
    }

    &__heading {
        font-size: 35px;
        font-weight: bold;
        line-height: 1.43;
        margin-top: 0;

        @include mobile {
            font-size: 25px;
            line-height: 2;
        }
    }

    &__lead {
        font-size: 16px;
        line-height: 1.63;
    }

    &__button {
        @include mobile {
            margin-top: 20px;
        }
    }

    &__link {
        margin-left: 40px;

        @include mobile {
            margin-left: 0;
        }
    }


    &--theme-light {
        background: $background-white;
        border-top: 1px solid $border-pale-grey;

        .calltoaction-block__heading {
            color: $text-color-blue;
        }

        .calltoaction-block__lead {
            color: $text-color-black;
        }

        .calltoaction-block__link {
            @include button-primary($background-blue, $text-color-white);
        }
    }

    &--theme-dark {
        background: $background-blue;

        .calltoaction-block__heading {
            color: $text-color-white;
        }

        .calltoaction-block__lead {
            color: $text-color-whiteish;
        }

        .calltoaction-block__link {
            @include button-primary($background-white, $text-color-orange);

            color: $text-color-orange !important;
            &:hover {
                color: $text-color-blue !important;
            }
        }
    }
}
