﻿.p-errorpage {
    .title-and-lead {
        padding: 280px 20px;

        @include mobiletablet {
            padding: 165px 20px;
        }

        @include mobile {
            padding: 165px 20px;
        }

        h1 {
            font-size: 100px;
            font-weight: bold;
            line-height: 1.12;
            letter-spacing: -3.5px;

            @include mobiletablet {
                font-size: 40px;
            }

            @include mobile {
                margin-bottom: 0;
            }
        }
    }


    #siteWrapper .bodyWrapper > .container {
        @include mobile {
            padding: 0;
        }
    }
}
