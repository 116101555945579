﻿$primary-color: #000;
$light-grey: #EBE9E5;
$medium-light-gray: #a0a0a0;
$dark-blue: #0f264d;
$dark-green: #0f4d39;
$light-orange: #FDB713;
$orange-1:#F47721;

$text-color-white: #fff;
$text-color-almost-white: #f3f3f3 ;
$text-color-whiteish: #f2f2f2;
$text-color-black: #242424;
$text-color-blue: #274476;
$text-color-grey: #9b9b9b;
$text-color-darkgrey: #4a4a4a;
$text-color-orange: #ee7727;
$text-color-darker-grey: #777777;

$background-pale-grey: #eff1f5;
$background-white: #fff;
$background-blue:#274476;
$background-light-blue:#F0F1F5;
$background-orange: #ee7727;

$border-blue: #274476;
$border-white: #fff;
$border-pale-grey: #eff1f5;

$font-header: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-header-weight: 700;
$font-body: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-body-weight: 400;

@mixin button-primary($bg, $text) {
    background: $bg;
    color: $text;
    border-color: $bg;
    border-radius: 20px;
    padding: 8px 30px;
    font-weight: 500;
    font-size: 14px;

    &:hover {
        background: $bg;
        color: $text;
        border-color: $bg;
    }

    &:focus {
        outline: 0;
        border-color: $bg;
        box-shadow: none;
        color: $text;
    }


    &:active {
        background: $bg;
        color: $text;
        border-color: $bg;
    }
}
@mixin button-default($text) {
    background: transparent;
    color: $text;
    border-color: $text;
    border-radius: 20px;
    padding: 8px 30px;
    font-weight: 500;
    font-size: 14px;

    &:hover {
        background: transparent;
        color: $text;
        border-color: $text;
    }

    &:focus {
        outline: 0;
        color: $text;
        border-color: $text;
        box-shadow: none;
        background: transparent;
    }

    &:active {
        background: transparent;
        color: $text;
        border-color: $text;
    }
}
@mixin arrow-link($text) {
    border: 0;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-size: 15px;
    color: $text;

    &:after {
        color: $text;
        display: inline;
    }
}

//Media queries 
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
    @media (min-width: #{0}) and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}
@mixin mobiletablet {
    @media (min-width: #{0}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}