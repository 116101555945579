﻿body {
    font-family: $font-body;
    font-weight: $font-body-weight;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-header;
    font-weight: $font-header-weight;
}

blockquote {
    position: relative;
    font-family: $font-header;
    font-weight: $font-header-weight;
    font-size: 24px;
    color: $primary-color;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
    padding: 20px 40px;
    margin-bottom: 30px;

    &::before {
        content: open-quote;
        display: block;
        position: absolute;
        top: 30px;
        left: 130px;
        font-size: 64px;
        line-height: 1;
    }

    &::after {
        content: close-quote;
        display: block;
        position: absolute;
        bottom: 30px;
        right: 130px;
        font-size: 64px;
        line-height: 1;
    }

    cite {
        display: block;
        font-size: 12px;
        color: $medium-light-gray;
        text-transform: uppercase;
        font-style: normal;
        margin-top: 20px;
    }
}

