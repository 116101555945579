﻿.navigation-block {
    &--info-box {
        @include mobile {
            padding: 0 20px 45px !important;
        }

        .navigation-block {
            &__container {
                > div div {
                    width: 100%;
                    margin: 0;
                    display: flex;
                    flex-flow: wrap;
                }

                > div > div {
                    width: calc(33.333% - 30px);
                    margin: 0 15px 30px;

                    @include tablet {
                        width: calc(50% - 30px);
                    }

                    @include mobile {
                        margin: 5px 0;
                        width: 100%;
                    }
                }

                .title-lead-and-image {
                    padding: 35px 28px;
                    background: $background-white;
                    border: 1px solid $border-pale-grey;
                    box-shadow: 2px 0px 5px -1px rgba(0, 0, 0, 0.11);
                    padding: 35px 28px;
                    height: 100%;

                    @include mobile {
                        border-top: 0;
                        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                        padding: 20px 25px !important;
                    }

                    &__content {
                        min-width: auto;
                        max-width: none;
                    }

                    &__icon {
                        width: 100%;
                        margin: 0 0 20px;

                        img {
                            height: 30px;
                            width: auto;
                        }

                        @include mobile {
                            display: inline-block;
                            width: 30px;
                            margin-right: 20px;
                            margin-bottom: 20px;
                        }
                    }


                    h3 {
                        font-size: 12px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1.83;
                        letter-spacing: 1.5px;
                        margin-top: 0;
                        color: $text-color-blue;
                        text-transform: uppercase;

                        @include mobile {
                            margin-bottom: 20px;
                            display: inline-block;
                        }
                    }

                    &__lead {
                        margin: 0;
                        font-size: 14px;
                        font-weight: normal;
                        font-style: normal;
                        line-height: 1.71;
                        letter-spacing: normal;

                        @include mobile {
                            font-size: 12px;
                            line-height: 1.83;
                        }
                    }
                }
            }
        }
    }
}
