﻿.card-container-block {
  > div > p {
    max-width: 607px;
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 25px;
  }
}

.cards-container {
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    
    @include desktop {
      flex-direction: row;
    }

    > div > div {      
      flex-grow:1;
      flex-basis: 0;
    }
  }
}