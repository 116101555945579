﻿.product-ar-block {
    padding-bottom: 70px;

    @include mobiletablet {
        padding-bottom: 35px;
    }

    &__buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        .btn {
            @include button-default($text-color-blue);
            margin: 10px;

            @include mobile {
                padding: 8px 5px;
                font-size: 10px;
                margin: 5px;
            }

            .glyphicon {
                font-size: 20px;
                vertical-align: text-bottom;
                margin-right: 5px;

                @include mobile {
                    font-size: 13px;
                }
            }
        }
    }

    &__content {
        text-align: center;
    }

    &__image {
        max-width: 630px;
        margin: 0 auto;
        display: block;
        margin-top: 90px;

        @include mobile {
            padding: 55px 35px 0;
            margin-top: 0;
        }

        img {
            width: 100%;
            display: block;
            height: auto;
        }
    }

    &__heading {
        font-size: 55px;
        font-weight: bold;
        line-height: 1.18;
        color: $text-color-blue;
        margin: 90px auto 10px;
        max-width: 917px;

        @include mobiletablet {
            margin: 50px auto 10px;
            font-size: 25px;
            line-height: 1.38;
        }
    }

    &__lead {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.63;
        max-width: 917px;
        margin: 45px auto 10px;

        @include mobiletablet {
            margin: 15px auto 10px;
            font-size: 16px;
            line-height: 1.63;
            font-weight: bold;
        }
    }
}
