﻿.hero-background {
    position: relative;
    display: flex;
    flex-flow: column;

    &__breadcrumb {
        .breadcrumb {
            margin: 20px 0 0;
            float: left;

            @media (min-width: 992px) {
                padding: 0 15px;
            }

            @media (min-width: 1200px) {
                padding: 0 8px;
            }

            @include mobiletablet {
                padding: 0;
            }
        }

        .outlink {
            margin: 20px 0 0;
            float: right;
            font-weight: bold;

            @media (min-width: 992px) {
                padding: 0 15px;
            }

            @media (min-width: 1200px) {
                padding: 0 8px;
            }

            @include mobiletablet {
                padding: 0;
            }

            &:after {
                color: #274476;
                display: inline;
                padding-top: 0;
                background-image: url('/src/images/ArrowBlue.svg');
                background-position: 0px 5px;
                margin-left: 10px;
            }
        }

        &--no-img {
            .breadcrumb {
                margin: 20px 0 0;

                @media (min-width: 992px) {
                    position: absolute;
                    top: 0;
                    margin-top: 160px;
                }

                @include mobiletablet {
                    position: absolute;
                    top: 0;
                    margin-top: 90px;
                }
            }
        }
    }

    &__container {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 1;
        position: relative;

        .embed-responsive {
            width: 100%;
            height: 80vh;
            left: 0;
            display: flex;
            flex-flow: column;
            justify-content: center;
        }

        &:after {
            background-color: rgba(0, 0, 0, 0.3);
            content: "";
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            top: 0;
            z-index: -1;
        }
    }

    &__content {
        padding: 0 30px;
        width: 100%;
        max-width: calc(1166px + 60px);
        text-align: center;
        margin: 0 auto;
    }

    &__heading {
        text-shadow: 0 0 40px rgba(0, 0, 0, 0.17);
        font-size: 100px;
        font-weight: bold;
        line-height: 1.12;
        letter-spacing: -2.92px;
        color: $text-color-white;

        @include mobiletablet {
            font-size: 40px;
        }
    }

    &__page-name {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        color: $text-color-white;
        text-transform: uppercase;
    }

    &__no-img {
        .hero-background__content {
            max-width: calc(865px + 60px);
        }

        &.hero-background__container {
            &:after {
                display: none;
            }
        }


        .hero-background__page-name {
            font-weight: 500;
            letter-spacing: 1.5px;
            color: $text-color-orange;
        }

        .hero-background__heading {
            letter-spacing: -3.5px;
            color: $text-color-blue;
            text-shadow: none;
        }

        .embed-responsive {
            height: auto;
            height: unset;
            padding: 230px 0 0;

            @include mobiletablet {
                padding: 130px 0 0;
            }
        }
    }

    &__navigation {
        &--card-view {
            z-index: 1;
            margin-top: -12.5vh;
            width: 100%;

            @include mobile {
                margin-top: -20vh;
            }

            > div {
                display: flex;
                flex-flow: row;
                justify-content: center;
                background: $background-white;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                margin: 0 auto;
                max-width: 1320px;
                border-radius: 3px;

                @include mobile {
                    flex-flow: column;
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                    background: $background-pale-grey;
                }


                div {
                    &:last-child {
                        .titleleadandimage-block {
                            border-right: 0;

                            @include mobile {
                                border-bottom: 0;
                            }
                        }
                    }
                }

                .titleleadandimage-block {
                    border-right: 1px solid $border-pale-grey;
                    padding: 35px;
                    max-width: 440px;
                    height: 100%;
                    position: relative;


                    @include mobile {
                        max-width: auto;
                        max-width: unset;
                        padding: 25px;
                        border-right: 0;
                        border-bottom: 1px solid $border-white;
                    }

                    @include tablet {
                        padding: 35px 10px;
                    }


                    &__heading {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.83;
                        letter-spacing: 1.5px;
                        color: $text-color-blue;
                        text-transform: uppercase;
                        margin-top: 0;
                    }

                    &__lead {
                        font-size: 14px;
                        line-height: 1.71;
                        margin-bottom: 40px;

                        @include mobile {
                            display: none;
                        }
                    }

                    &__link {
                        font-size: 12px;
                        font-weight: 500;
                        color: $text-color-blue;
                        display: flex;
                        align-items: center;
                        position: absolute;
                        bottom: 35px;

                        &:before {
                            content: "";
                            display: block;
                            border-top: 2px solid $border-blue;
                            width: 25px;
                            height: 2px;
                            margin-right: 5px;
                        }

                        &:after {
                            display: none;
                        }

                        @include mobile {
                            bottom: auto;
                            bottom: unset;
                            position: relative;
                        }
                    }
                }
            }
        }

        &--thin-view {
            > div {
                display: flex;
                flex-flow: row;
                justify-content: center;
                background: $background-white;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
                margin: 0 auto;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;

                &::-webkit-scrollbar {
                    height: 2px;
                    border-radius: 0px;
                    background: $background-pale-grey;
                }

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba($background-blue,0.3);
                    border-radius: 0px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $border-blue;
                    outline: 1px solid $border-blue;
                    border-radius: 0;
                }

                @include mobiletablet {
                    justify-content: flex-start;
                }


                .titleleadandimage-block {
                    padding: 25px;
                    height: 100%;

                    &__heading {
                        display: none;
                    }

                    &__lead {
                        display: none;
                    }

                    &__link {
                        font-size: 14px;
                        color: $text-color-darkgrey;
                        white-space: nowrap;

                        @include mobiletablet {
                            font-size: 12px;
                        }


                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
