﻿.titleleadcalltoaction-block {
    width: 100%;
    display: block;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    background-repeat: no-repeat;
    background-size: cover;

    &.gallerymode {
        background-size: contain;
        background-position: center;
        min-height: 85vh;
    }

    &__container {
        padding: 120px 20px 90px;

        @include mobile {
            padding: 35px 20px 30px;
        }
    }


    &__title {
        font-size: 35px;
        font-weight: bold;
        line-height: 1.14;
        letter-spacing: normal;
        color: $text-color-blue;
        max-width: 660px;
        margin: 0 auto;
        padding-bottom: 30px;

        @include mobile {
            font-size: 25px;
            line-height: 1.38;
            margin-top: 0;
        }
    }

    &__subheading {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.83;
        letter-spacing: 1.5px;
        color: $text-color-orange;
        text-transform: uppercase;
        margin-top: 0;
        max-width: 660px;
        margin: 0 auto 20px;
    }

    &__content {
        margin: 0 auto;
        width: 100%;
    }


    &__lead {
        padding: 0 20px;
        font-size: 16px;
        line-height: 1.63;
        max-width: 740px;
        margin: 0 auto;
    }

    &__button {
        @include button-default($text-color-blue);
        margin-top: 30px;
        white-space: normal;
    }

    &__maincontent {
        > div {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            max-width: 1060px;
            margin: 0 auto;

            @include tablet {
                justify-content: flex-start;
            }

            > div {
                width: 33.333%;
                padding: 30px 15px;

                @include tablet {
                    width: 50%;
                    padding: 30px 15px;
                }

                @include mobile {
                    width: 100%;
                    padding: 30px 0px;
                }

                .title-lead-and-image {
                    &__container {
                        width: 100%;
                        flex-flow: wrap;
                        
                        @include desktop{
                            justify-content: flex-start;
                        }

                        @include mobiletablet {
                            margin: 0;
                        }
                    }


                    &__content {
                        margin-right: 0;
                        margin-left: 0;
                        min-width: auto;
                        max-width: auto;
                        min-width: unset;
                        max-width: unset;
                        text-align: left;
                    }

                    &__title {
                        font-size: 14px;
                    }

                    &__lead {
                        font-size: 14px;
                        line-height: 1.43;
                        color: $text-color-darkgrey;
                        margin: 10px 0 0;
                    }

                    .btn-default {
                        @include arrow-link($text-color-blue);
                        font-weight: 500;
                        display: flex;
                        align-items: flex-end;
                        margin: 15px 0 0;

                        &:after {
                            background-image: url(/src/images/ArrowBlue.svg);
                        }
                    }

                    &__icon {
                        img {
                            height: 30px;
                        }
                    }
                }
            }
        }
    }

    .embed-responsive {
        width: 100%;
        height: 100%;
        left: 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        z-index: 0;

        video {
            object-fit: cover;
            z-index: -1;
            width: 100%;
        }
    }

    &--background {
        position: relative;
        min-height: 60vh;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 0;
            background-color: rgba(0, 0, 0, 0.2);
        }

        .embed-responsive {
            z-index: 1;
            min-height: 60vh;

            iframe {
                z-index: -1;
            }
        }

        &__container {
            padding: 170px 0 160px;

            @include mobile {
                padding: 60px 0 60px;
            }
        }

        .titleleadcalltoaction-block {
            &__title {
                font-size: 55px;
                font-weight: bold;
                line-height: 1.18;
                color: $text-color-white;
                max-width: 920px;

                @include tablet {
                    font-size: 3em;
                }

                @include mobile {
                    font-size: 40px;
                }
            }

            &__button {
                @include button-default($text-color-white);
                margin-top: 0;
                z-index: 1;
                position: relative;
            }

            &__subheading {
                color: $text-color-white;
            }

            &__lead {
                color: $text-color-white;
                margin-bottom: 30px;
            }

            &__content {
                max-width: 920px;
                padding: 0 20px;

                @include mobile {
                    padding: 20px;
                }
            }
        }
    }
}
