﻿.p-articlelistingpage {

    .child-page {
        img {
            min-width: 100%;
            max-width: 100%;
        }

        h3 {
            margin-top: 0;

            a {
                color: $text-color-blue;
                text-decoration: none;
            }
        }

        p {
            &:first-child {
                position: relative;

                .playvideo {
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    min-width: unset;
                    max-width: unset;
                }
            }
        }
    }

    .article-container {
        padding-top: 40px;

        > .container {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            margin-top: 30px;
            margin-bottom: 120px;

            @include mobile {
                display: none;
            }

            > span {
                display: flex;
                align-self: center;
            }

            > div {
                > input {
                    border: 0;
                    border-bottom: 2px solid $border-blue;
                    border-radius: 0;
                    box-shadow: none;
                    color: $text-color-blue;
                    font-size: 30px;
                    font-weight: 500;
                    letter-spacing: normal;
                    text-align: center;
                    margin-left: 40px;
                    text-transform: uppercase;
                }
            }
        }


        &__filter {
            width: 100%;
            display: block;

            .active--mob {
                display: none;

                @include mobile {
                    display: block;
                    margin: 0 auto;
                    color: $text-color-blue;
                    font-weight: bold;
                    border-radius: 0;
                    box-shadow: none;
                    background: none;
                    border: 0;

                    &:hover, &:focus, &:active {
                        outline: 0;
                        border-radius: 0;
                        box-shadow: none;
                        background: none;
                        border: 0;
                        color: $text-color-blue;
                    }

                    &:after {
                        content: "\f078";
                        font-family: FontAwesome;
                        color: $text-color-blue;
                        display: inline;
                        margin-left: 3px;
                    }
                }
            }

            .dropdown {
                @include mobile {
                    margin: 15px 0;
                }

                > ul {
                    list-style: none;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    margin: 0;
                    padding-left: 0;
                    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.11);
                    width: 100%;
                    border: 0;
                    border-radius: 0;
                    position: relative;

                    @include mobile {
                        box-shadow: none;
                        position: absolute;
                        display: none;
                        padding: 0;
                    }

                    > li {
                        font-size: 14px;
                        text-align: center;

                        a {
                            color: $text-color-darkgrey;
                            margin: 25px 35px;
                            display: block;
                            cursor: pointer;
                            background: transparent;
                            padding: 0;

                            @include tablet {
                                margin: 25px 15px;
                            }

                            @include mobile {
                                margin: 25px;
                            }

                            &:hover, &:focus, &:active {
                                text-decoration: none;
                                background: transparent;
                            }
                        }


                        &.active {
                            a {
                                color: $text-color-blue;
                                font-weight: bold;
                            }

                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }

                &.open {
                    .dropdown-menu {
                        display: block;
                        box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.11);
                    }
                }
            }
        }

        &__news {
            border-top: 1px solid $border-pale-grey;
            padding-top: 120px;

            @include mobile {
                border-top: 0;
                padding-top: 0;
            }

            ul {
                list-style: none;
                display: flex;
                flex-flow: row wrap;
                margin: 0 auto;
                padding: 35px 50px;

                @include mobiletablet {
                    padding: 35px 18px;
                }

                @include mobile {
                    padding: 15px 5px;
                }
            }

            li {
                width: calc(33.333% - 30px);
                margin: 35px 15px;

                @include mobiletablet {
                    width: calc(50% - 30px);
                }

                @include mobile {
                    width: calc(100% - 30px);
                    margin: 15px;

                    &:nth-child(6n+6) {
                        margin-right: 0;

                        .article-container__news-img {
                            width: 100%;
                            margin-right: 0;
                            height: 212px;
                        }

                        .article-container__news-content {
                            width: 100%;
                            display: flex;
                            flex-flow: column;

                            p {
                                display: block;
                                order: 3;
                                margin: 0;
                                font-size: 14px;
                                line-height: 1.43;
                                letter-spacing: normal
                            }

                            span {
                                margin-top: 10px;
                                order: 4;
                            }

                            .date {
                                margin-top: 8px;
                                order: 1;
                            }

                            h6 {
                                margin-top: 5px;
                                order: 2;
                            }
                        }

                        > a {
                            flex-flow: wrap;
                        }
                    }
                }

                a {
                    display: flex;
                    flex-flow: row;

                    &:hover, &:focus, &:active {
                        text-decoration: none;
                    }

                    @include mobile {
                        align-items: center;
                    }
                }
            }

            &-img {
                width: 150px;
                min-width: 150px;
                height: 150px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-color: $background-pale-grey;
                margin-right: 25px;

                @media (max-width: 1300px) {
                    width: 100px;
                    min-width: 100px;
                    height: 100px;
                }

                @include mobiletablet {
                    width: 150px;
                    min-width: 150px;
                    height: 150px;
                }

                @include mobile {
                    width: 105px;
                    min-width: 105px;
                    height: 105px;
                    margin-right: 17px;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        display: block;
                        border-left: 5px solid $border-blue;
                        top: 0;
                        left: 0;
                        height: 100%;
                    }
                }
            }

            &-content {
                padding-top: 18px;

                @include mobile {
                    display: flex;
                    flex-flow: column;
                    padding-top: 0;
                }

                span {
                    font-size: 10px;
                    font-weight: 500;
                    letter-spacing: 1.25px;
                    color: $text-color-grey;
                    margin-bottom: 5px;
                    text-transform: uppercase;

                    @include mobile {
                        order: 3;
                    }

                    &.date {
                        display: none;

                        @include mobile {
                            display: block;
                            order: 1;
                        }
                    }
                }

                h6 {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.33;
                    color: $text-color-blue;
                    margin-bottom: 10px;

                    @include mobile {
                        font-size: 14px;
                        line-height: 1.34;
                        letter-spacing: 1.5px;
                        order: 2;
                        margin: 0 0 5px;
                    }
                }

                p {
                    font-size: 13px;
                    line-height: 1.54;
                    color: $text-color-darkgrey;
                    max-height: 60px;
                    overflow: hidden;

                    @include mobile {
                        display: none;
                    }
                }
            }
        }

        &__load-more {
            margin: 10px 0 80px;
            display: block;

            button {
                margin: 0 auto;
                display: block;
                @include button-default($text-color-blue);
                padding: 8px 50px;
            }
        }

        .article-content-area {
            .news-item {
                margin-top: 0;

                @include mobile {
                    padding: 0 15px 48px 15px
                }
            }
        }

        .news-item__heading {
            display: none;
        }
    }

    .news-signup {
        background: $background-pale-grey;
        border-top: 2px solid $border-pale-grey;
        padding: 70px 0;

        & > .container {
            & > .formcontainerblock {
                width: 100%;

                .EPiServerForms {
                    margin: 0;
                    padding: 0;
                }
            }
        }

        &__input {
            input {
                background: $background-pale-grey;
            }

            .input-group-addon {
                background: $background-pale-grey;
            }
        }

        @include mobile {
            border-top: 0;
        }
    }
}
