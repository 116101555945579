.quoteSectionBlock{
    &__container{
        display: flex;
        padding-top: 30px;
        justify-content: space-between;
        flex-direction: row;
        gap: 77px;
        @include mobile{
            flex-direction: column;
            padding-bottom: 10px;
            gap: 20px;
        }
    }
    &__left{
        width: 45vw;
        @include tablet{
            width: 50vw;
        }
    }
    &__right{
        width: 25vw;
        @include tablet{
            padding-top: 10px;
            width: 35vw;
        }
    }
    &__left, &__right{
        display: flex;
        flex-direction: column;
        gap: 24px;
        @include mobile{
            width: 100%;
        }
    }

    &__title{
        margin: 0 !important;
        font-size: 36px !important;
        color: $text-color-blue;
        line-height: 44px; 
    }   
    &__text{
        font-size: 16px;
        line-height: 26px;
    }
    &__quote{
        color:$text-color-blue;
        font-size: 16px;
        margin: 0 !important;
        line-height: 26px;
    }
    &__img-text-container{
        max-width: 305px;
        display: flex;
        align-items: center;
        gap: 16px;
        @include mobiletablet{
            max-width: 100%;
        }
    }
    &__img{
        max-height: 77px;
        border-radius: 50%;
        object-fit: contain;
    }
    &__img-text{
        font-size: 12px;
        font-weight: 700;
        color: $text-color-grey;
    }
}