﻿.organization-statistics-block {
    color: $text-color-white;
    padding: 64px 0;
    display: flex;
    justify-content: space-between;
    position: relative;
    
    &__text-container {
        @include mobiletablet {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        @include desktop {
            max-width: 525px;
        }
    }

    &__title {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 32px;
    }

    &__text {
        font-size: 16px;
        line-height: 26px;
    }

    &__number {
        font-size: 64px;
        font-weight: 700;
    }

    &__label {
        font-size: 14px;
        line-height: 20px;
    }

    &__counter-list {
        display: flex;
        gap: 40px;
        margin-top: 18px;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @include mobiletablet {
            flex-direction: column;
        }
    }

    &__video {
        width: 100vw;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: -1;
    }
}

.world-map {
    &__container {
        padding: 0 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        z-index: 1;
        position: relative;

        > div {
            width: 100%;
            height: auto !important;  
            left: 0;
        }
    }

    &__underlay {
        background: $background-blue;
        border: solid 1px #979797;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        opacity: 0.7;
        z-index: 0;
    }

    &__content {
        position: relative;
        z-index: 1;
    }

    &__heading {
        margin-top: 75px;
        margin-bottom: 0;
        padding: 0 50px;
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.5px;
        color: $text-color-white;

        @include mobile {
            margin-top: 70px;
        }
    }

    &__map {
        position: relative;
        z-index: 1;
        width: 100%;
        margin-left: 20px;
        max-width: 83vh;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        @include mobiletablet {
            display: none;
        }

    }
}