﻿.multibox-list-block {
    &__list {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-flow: row;
        justify-content: center;
        margin: 30px 0 10px;

        @include mobile {
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            width: 100%;
            justify-content: flex-start;

            &::-webkit-scrollbar {
                height: 2px;
                background: $background-pale-grey;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba($background-blue,0.3);
      
            }

            &::-webkit-scrollbar-thumb {
                background-color: $border-blue;
                outline: 1px solid $border-blue;
            }
        }
    }

    &__column {
        width: calc(20% - 30px);
        max-width: 230px;
        display: flex;
        flex-flow: column;
        padding: 10px 15px;

        @include tablet {
            width: calc(30% - 30px);
            max-width: auto;
            max-width: unset;
        }

        @include mobile {
            width: 100%;
            max-width: none;
            min-width: 62%;
            padding: 10px;
        }
    }

    &__img {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__heading {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.83;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        color: $text-color-blue;
        margin: 15px 0 5px;
        max-width: 540px;
    }

    &__lead {
        font-size: 12px;
        line-height: 1.83;
        color: $text-color-black;
        max-width: 540px;
    }

    a, a:hover, a:focus {
        text-decoration: none;
    }

    &--box-border {
        .multibox-list-block {
            &__list {
                max-width: 1375px;
                margin: 0 auto;

                @media (max-width: 1599px) {
                    max-width: 1070px;
                }

                @media (max-width: 1199px) {
                    max-width: auto;
                    max-width: unset;
                    padding: 0 65px;
                }

                @include tablet {
                    padding: 0;
                }

                @include mobile {
                    padding: 0;
                }
            }

            &__column {
                background: $background-white;
                border: 1px solid $border-pale-grey;
                box-shadow: 2px 0px 5px -1px rgba(0, 0, 0, 0.11);
                padding: 0;
                width: 20%;
                max-width: 276px;

                &:last-child {
                    border-right: 0;

                    @include mobile {
                        border-right: 1px solid $border-pale-grey;
                    }
                }

                a {
                    padding: 50px 28px 50px;
                    height: 100%;

                    @include mobile {
                        padding: 35px 15px 35px;
                    }
                }

                @include tablet {
                    width: 33.333%;
                    max-width: auto;
                    max-width: unset;
                }

                @include mobile {
                    width: 100%;
                    max-width: auto;
                    max-width: unset;
                    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.11);
                }
            }

            &__heading {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.71;
                letter-spacing: normal;
                text-transform: none;
            }

            &__lead {
                margin-bottom: 0;
                font-weight: normal;
                font-style: normal;
                line-height: 1.83;
            }
        }
    }
}
