﻿.p-standardcontentpage {
    .breadcrumbs-container {
        position: relative;

        .breadcrumb {
            position: absolute;

            @media (min-width: 992px) {
                top: 160px;
                padding: 0 15px;
            }

            @media (min-width: 1200px) {
                top: 160px;
                padding: 0 8px;
            }

            @include mobiletablet {
                top: 85px;
                padding: 0;
                margin-bottom: 0;
            }
        }
    }

    .title-and-lead {
        padding: 280px 20px 50px;

        @include mobiletablet {
            padding: 165px 20px 10px;
        }

        @include mobile {
            padding: 165px 30px 0px;
        }

        h1 {
            font-size: 100px;
            font-weight: bold;
            line-height: 1.12;
            letter-spacing: -3.5px;

            @include mobiletablet {
                font-size: 40px;
            }
        }

        &__sectionname {
            margin-bottom: 27px;
        }
    }
}

.EPiServerForms {
    background: $background-pale-grey;
    padding: 65px 28px 65px;
    margin-top: 100px;

    > * {
        max-width: 730px;
        margin: 0 auto;
    }

    h1, h2, h3, h4, h5, h6 {
        color: $text-color-blue;
    }

    hr {
        border-top: 1px solid #ddd;
    }

    p {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        color: $text-color-darkgrey;
    }

    .FormDateTimeRange .FormDateTimeRange__Start, .FormDateTimeRange .FormDateTimeRange__End {
        width: calc(50% - 8px);
        min-width: calc(50% - 8px);
    }

    .Form {
        &__Status .Form__Success__Message {
            background-color: $text-color-white;
            color: $text-color-blue;
        }

        &__Status .Form__Warning__Message {
            background-color: $text-color-white;
            color: $text-color-orange;
        }

        &__Element__ValidationError {
            color: $text-color-orange !important;

            p {
                color: $text-color-orange !important;
            }

            &:empty {
                display: none;
            }

            &-large {
                font-size: 2rem;

                p {
                    font-size: 2rem;
                }
            }
        }

        &__Element__ValidationRequired {
            float: left !important;
            color: $text-color-orange;
            margin: 30px 0 5px 10px;
        }

        &__Element {
            &.FormRange {
                > span {
                    display: flex;
                    flex-flow: row;

                    span {
                        text-transform: uppercase;
                        font-size: 10px;
                        font-weight: 500;
                        color: $text-color-blue;
                        width: 100%;
                        margin: 30px 0 5px;
                        border: 0;
                    }
                }
            }

            &.FormSelection {
                select[multiple] {
                    height: 80px;
                }
            }

            &.FormTextbox {
                textarea {
                    height: 200px;
                }
            }
        }

        &__NavigationBar {
            display: flex;
            flex-flow: row;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            &__ProgressBar {
                border: 1px solid $text-color-blue !important;
                border-radius: 30px;

                &--Progress {
                    background: $text-color-blue !important;
                }
            }
        }

        &__NavigationBar__Action {
            background: $text-color-blue !important;
            color: $text-color-white !important;
            border-color: $text-color-blue !important;
            border-radius: 20px !important;
            padding: 8px 30px !important;
            font-weight: 500 !important;
            font-size: 14px !important;
            font-weight: 500 !important;
            height: auto !important;
            width: auto !important;
            max-width: none !important;
            min-width: none !important;

            &:focus, &:active {
                outline: 0;
                border: none !important;
            }

            &.btnPrev {
                background: transparent !important;
                color: $text-color-blue !important;
                border-color: $text-color-blue;
                border-radius: 20px !important;
                padding: 8px 30px !important;
                font-weight: 500 !important;
                font-size: 14px !important;
                margin: 45px 0 30px !important;
                height: auto !important;
                height: auto !important;

                &:focus, &:active {
                    outline: 0;
                    border: none !important;
                }
            }

            &.btnNext {
                background: $text-color-blue !important;
                color: $text-color-white !important;
                border-color: $text-color-blue !important;
                border-radius: 20px !important;
                padding: 8px 30px !important;
                font-weight: 500 !important;
                font-size: 14px !important;
                font-weight: 500 !important;
                height: auto !important;

                &:focus, &:active {
                    outline: 0;
                    border: none !important;
                }
            }
        }

        &__Description {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.58;
            letter-spacing: normal;
            color: $text-color-darkgrey;
            margin-bottom: 10px;
        }

        &__Title {
            margin-bottom: 20px;
            color: $text-color-blue;
        }
    }



    .FormChoice {
        &__Input {
            &--Radio, &--Checkbox {
                min-width: auto;
                width: auto;
            }
        }

        label {
            display: flex;
            flex-flow: row;
            align-items: center;
            margin: 0 0 5px;

            input {
                margin-right: 20px;
            }
        }

        &--Image {
            fieldset {
                display: flex;
                flex-flow: wrap;
            }

            &__Item {
                display: flex !important;
                flex-flow: row;
                width: 15px;

                &__Caption {
                    order: 3;
                    display: flex;
                    align-items: center;
                }

                img {
                    border: 0;
                    width: 200px;
                    max-width: 200px;
                    width: auto !important;
                    margin: 0 20px;
                    height: auto;
                }
            }
        }
    }

    .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
        border: 1px solid $text-color-orange;
        background: $text-color-orange;
        color: $text-color-white;
    }

    .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
        border: 1px solid $text-color-blue;
        background: $text-color-blue;
        color: $text-color-white;
    }



    input, select, textarea {
        border-radius: 0;
        border: 1px solid $text-color-white;
        height: 45px;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: $text-color-grey;
        background: $background-white;
        padding: 15px 13px;

        &:focus {
            outline: 0;
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            opacity: 0.36;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            color: $text-color-grey;
        }

        &::-moz-placeholder {
            opacity: 0.36;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            color: $text-color-grey;
        }

        &:-ms-input-placeholder {
            opacity: 0.36;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            color: $text-color-grey;
        }

        &:-moz-placeholder {
            opacity: 0.36;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            color: $text-color-grey;
        }
    }

    textarea {
        height: 90px;
    }

    .FormResetButton {
        background: transparent !important;
        color: $text-color-blue !important;
        border-color: $text-color-blue;
        border-radius: 20px !important;
        padding: 8px 30px !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        margin: 45px 0 30px !important;
        height: auto !important;
        height: auto !important;
        width: auto;
        min-width: auto;

        &:focus, &:active {
            outline: 0;
        }
    }

    .rc-anchor-error-message {
        color: $text-color-orange;
    }

    button {
        background: $text-color-blue !important;
        color: $text-color-white !important;
        border-color: $text-color-blue !important;
        border-radius: 20px !important;
        padding: 8px 30px !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        height: auto !important;
        border: 0;

        &:focus, &:active {
            outline: 0;
        }

        &:disabled {
            background: $medium-light-gray !important;
        }
    }

    label, .Form__Element__Caption {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
        color: $text-color-blue;
        width: 100%;
        margin: 30px 0 5px;
        border: 0;

        &.pull-left {
            width: auto;
        }
    }

    .FormChoice {
        fieldset {
            &.display-flex {
                display: flex;
                flex-wrap: nowrap;

                label {
                    width: auto;
                    margin-right: 50px !important;
                    margin-bottom: 30px;

                    input {
                        height: auto;
                    }
                }
            }
        }
    }
}
