.titleimagelinkblock{
  position: relative;
  
  .title-image-link__block{
    width: 100%;
    height: 100%;
    
    img{
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }


    .lead-linktext__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80%;
      width: 100%;
      
      .lead-content{
        text-align: center;
        padding-bottom: 20px;
        color: $text-color-white;
      }

      .link-linktext-content{
        text-align: center;
        
        a {
          font-weight: 700;
          background: $text-color-orange;
          border-radius: 25px;
          border: none;
          padding: 15px;
          min-width: 300px;
          text-align: center;
          color: $text-color-white;
          display: inline-block;
        }
      }
      
    }
    
    
  }
}
