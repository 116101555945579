﻿.p-globallandingpage {

    &__container {
        > div {
            div {
                &:last-child {
                    .titleleadandimage-block {
                        border-right: 0;

                        @include mobile {
                            border-bottom: 1px solid $border-pale-grey;
                        }
                    }
                }
            }
        }
    }

    .hero-background__navigation--card-view {
        .titleleadandimage-block {
            background: $background-white;
            border: 1px solid $border-pale-grey;
            box-shadow: 2px 0px 5px -1px rgba(0, 0, 0, 0.11);
            padding: 50px 28px 80px;
            height: 100%;
            position: relative;

            @include mobile {
                box-shadow: none;
                border: 0;
                border-top: 1px solid $border-pale-grey;
                padding: 50px 35px 85px;
            }


            &.clickable {
                padding: 35px 28px;

                @include mobile {
                    padding: 35px;
                }
            }

            &__heading {
                font-size: 12px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.83;
                letter-spacing: 1.5px;
                margin-top: 0;
                color: $text-color-blue;
                text-transform: uppercase;

                @include mobile {
                    line-height: normal;
                }
            }

            &__lead {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;

                @include mobile {
                    line-height: 1.43;
                }
            }

            &__link {
                font-size: 12px;
                font-weight: 500;
                color: $text-color-blue;
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 20px;

                @include mobile {
                    position: relative;
                    bottom: 0;
                    padding: 20px 0 10px;
                }

                &:before {
                    content: "";
                    display: block;
                    border-top: 2px solid $text-color-blue;
                    width: 55px;
                    height: 2px;
                    margin-right: 10px;
                }
            }

            &__icon {
                width: 100%;
                margin: 0 0 20px;

                img {
                    height: 30px;
                    width: auto;
                }
            }

            &__img {
                width: 100%;
                margin: 0 0 20px;

                img {
                    width: 100%;
                }
            }
        }
    }

    &--icons {
        @include mobile {
            padding: 0 20px 45px;

            .titleleadandimage-block {
                border-top: 0;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                padding: 20px 25px !important;

                &__icon {
                    display: inline-block;
                    width: 30px;
                    margin-right: 20px;
                    margin-bottom: 20px;
                }

                &__heading {
                    display: inline-block;
                    margin-bottom: 20px;
                }

                &__lead {
                    font-size: 12px;
                    line-height: 1.83;
                }
            }
        }
    }
}


.video-block {
	height: 65vh;
	max-height: 650px;
	margin-bottom: 20px;

	.embed-responsive {
	  height: 100%;
	}

	.video-with-overlay__video {
	  z-index: 0;
	}
}
