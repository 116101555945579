$block-padding-bottom: 60px;
$text-font-size:14px;

.twoimageblock,
.calltoactionblock,
.twoimageblock,
.videoblock,
.twocolumntextimageblock,
.formoutercontainerblock,
.fourcolumntextblock,
.titleleadcalltoactionblock,
.titleleadcalltoactionsliderblock,
.titleimagerichtextblock,
.twocolumnrichtextblock,
.textandimage-block,
.twocolumnctablock,


{
  padding-bottom:$block-padding-bottom;
  margin-left: auto;
  margin-right: auto;

  h1, h2, h3, h4, h5, h6 {
    color: $border-blue;
  }

  h1{
    font-size: 36px;
  }

  h2{
    font-size: 30px;
  }

  h3{
    font-size: 24px;
  }

  h4{
    font-size: 18px;
  }

  h5{
    font-size: 14px;
  }

  h6{
    font-size: 12px;
  }

  h1,h2, h3{
    margin-bottom: 10px;
    margin-top: 20px;
  }

  h4,h5,h6{
    margin-bottom: 10px;
    margin-top: 10px;
  }

}