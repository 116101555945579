.submenublock {
    position: sticky;
    top: 0;
    z-index: 15;
    @include mobiletablet {
        top: 64px;
        z-index:11;
    }
}

.submenuBlock{

    &__container {
        padding-left: 0px;
        padding-right: 0px;
    }

    &__flex-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding:9px 0px;
        @include mobiletablet{
            flex-direction: column;
            align-items: start;
            padding-top: 0px;
            padding-bottom: 0px;
            overflow: auto;
        }

        &--right, &--left{
            width: auto;
            padding-left: 20px;
            padding-right: 20px;
        }

        &--left {
            padding-top: 18px;
            padding-bottom: calc(18px - 2px);
        }
        &--right{
            position: relative;
            display: flex;
            gap: 42px;
            align-items: center;
            @include mobiletablet{
                min-width: 100%;
                flex-direction: row;
                margin-top: 0px;
                align-items: start;
                white-space: nowrap;
                background-color: $dark-blue;
                align-items: center;
                gap: 25px;
            }
          .submenuitemblock{
                a{
                    display: block;
                    color: $text-color-white;
                    font-size: 14px;
                    font-weight: $font-header-weight;
                    line-height: 17px;
                    text-decoration: none;
                    cursor: pointer;
                    @include mobiletablet{
                        font-size: 13px;
                        padding: 15px 0;
                    }
                }
                @include mobiletablet{
                    flex-direction: column;
                }
                &__cta{
                    .btn-primary {
                        @include button-primary($background-orange, $text-color-white);
                        margin: 0 auto;
                        font-weight: 700;
                        line-height: 24px;

                        @include mobiletablet{
                            padding: 5px 13px;
                        }
                    }
                }
            }
        }

        &--left{
            @include mobiletablet{
                position: sticky;
                left: 0;
            }

            h2{
                color: $text-color-white;
                font-size: 16px;
                font-weight: $font-header-weight;
                line-height: 26px;
                text-transform: uppercase;
                margin: 0;
             }
        }   
    }
}