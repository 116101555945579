﻿.board-of-directors {
    margin: 60px 0 275px;

    @include tablet {
        margin: 0 0 100px;
    }

    @include mobile {
        margin: 50px 0 100px;
    }

    &__container {
        max-width: 1400px;
        margin: 0 auto;

        @media (max-width: 1599px) {
            max-width: 1100px;
        }

        @media (max-width: 1200px) {
            max-width: auto;
            max-width: unset;
            padding: 0 70px;
        }

        @include mobiletablet {
            padding: 0;
        }
    }

    &__row {
        @include mobiletablet {
            margin: 0;
        }

        > div {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;

            > div {
                width: 25%;

                @include tablet {
                    width: 33.333%;
                }

                @include mobile {
                    width: 100%;
                }
            }
        }
    }



    &__column {
        padding-right: 30px;
        padding-left: 30px;
        margin: 70px 0 0;
        width: 100%;

        @include mobile {
            margin: 35px 0 0;
        }
    }

    &__img {
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto 20px;

        @media (max-width: 1200px) {
            width: 100px;
            height: 100px;
        }

        @include mobile {
            margin: 0 auto 10px;
        }


        div {
            width: auto;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
            background-color: $text-color-whiteish;
        }
    }

    &__heading {
        margin: 0 0 5px;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        text-align: center;
        color: $text-color-blue;
    }

    &__lead {
        font-size: 10px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.25px;
        text-align: center;
        text-transform: uppercase;
        color: $text-color-grey;
        margin: 0 0 20px;

        @include mobile {
            margin: 0 0 10px;
        }
    }

    &__content {
        margin: 0;
        font-size: 12px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        max-width: 400px;
        margin: 0 auto;
    }
}
