$v2-max-width: 700px;
$padding-top-desktop: 140px;
$padding-top-mobile: 100px;

.p-articleitempage, .p-standardcontentpage,
.p-bloglandingpage, .p-blogitempage {
    .article_v2 {
        max-width: 100%;
        padding-top: $padding-top-desktop;

        @include mobile {
            padding-top: $padding-top-mobile;
        }

        .info-container {
            @include desktop {
                margin-left: -19px;
                margin-right: -19px;
            }

            @media (min-width: 1200px) and (max-width: 1214px) {
                margin-right: -44px;
                margin-left: -44px;
            }

            @media (min-width: 1215px) and (max-width: 1299px) {
                margin-right: calc((1200px - (100% + 15px)) / 2 * -1);
                margin-left: calc((1200px - (100% + 15px)) / 2 * -1);
            }

            @media (min-width: 1300px) and (max-width: 1599px) {
                max-width: 1200px;
                margin-right: auto;
                margin-left: auto;
                padding-left: 5px;
            }

            @media (min-width: 1600px) {
                margin-left: -52px;
                margin-right: -52px;
            }
        }

        .breadcrumb {
            margin: 0 !important;
        }

        &.no-hero-bg {
            padding-top: $padding-top-desktop;

            @include mobile {
                padding-top: $padding-top-mobile;
            }

            .article-additional-info {
                max-width: 100%;

                @include mobile {
                    margin-bottom: 50px;
                }
            }

            @include mobile {
                .title-and-lead {
                    padding: 0;

                    .title-and-lead__container {
                        padding: 0;
                    }
                }
            }
        }

        .article-additional-info {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .article-additional-info__divider {
                width: 100%;
                height: 1px;
                background: $light-grey;
                margin: 20px 0;
            }

            .article-additional-info__content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .category-list {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;

                    @include mobile {
                        gap: 7px;
                    }

                    .category-list__item {
                        border: 1px solid $orange-1;
                        border-radius: 25px;
                        font-size: 14px;
                        line-height: 24px;
                        padding: 3px 20px;
                        color: $text-color-blue;

                        @include mobile {
                            font-size: 13px;
                            padding: 5px 13px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        &.hero-background, .hero-background {
            .hero-background__container {
                .hero-background__content {
                    span.hero-background__page-name {
                        font-weight: 700;
                        color: $text-color-white;
                        font-size: 11px;
                    }

                    .hero-background__heading {
                        max-width: inherit;
                    }
                }
            }
        }

        .title-and-lead, .article__content {
            max-width: $v2-max-width;
            width: 100%;
            text-align: left;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            &__share {
                background-image: url(/Static/img/social-share-icons.png);
                background-repeat: no-repeat;
                display: inline-block;
                width: 45px;
                height: 45px;

                &__container {
                    position: absolute;
                    top: 100px;
                    right: -100px;
                    width: 45px;
                }

                &__email {
                    background-position: 0 0;
                }

                &__linkedin {
                    background-position: 0 -155px;
                    margin-top: 5px;
                }
            }
        }

        .title-and-lead, .article__content.intro {
            padding: 20px 0 30px 0;
            text-align: center;

            p {
                font-size: 18px;
                line-height: 30px;
            }
        }


        .article__content {
            position: relative;
            position: relative;

            h1, h2, h3 {
                margin-bottom: 10px;
                margin-top: 20px;
            }

            h4, h5, h6 {
                margin-bottom: 10px;
                margin-top: 10px;
            }

            p {
                font-size: 14px;
                line-height: 25px;
            }

            ul {
                padding: 0 0 0 28px;
            }
        }

        .article__block {
            max-width: 1145px;
            padding-top: 0;

            .textandimageblock {
                .textandimage-block__text {
                    p {
                        font-size: 14px;
                    }
                }
            }

            .twoimageblock {
                padding-bottom: 60px;

                .image-block {
                    & > div {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        row-gap: 16px;

                        .caption {
                            font-size: 14px;
                        }
                    }
                }
            }
            //two column text/image block
            .twocolumntextimageblock {
                article {
                    width: 100%;
                    padding-top: 100px;

                    .column {

                        @include desktop {
                            width: 50%;

                            &.column--1 {
                                padding-left: 0;
                            }

                            &.column--2 {
                                padding-right: 0;
                            }
                        }

                        .section-title {
                            color: $border-blue;
                            font-size: 24px;
                        }

                        div {
                            h1, h2, h3, h4, h5, h6 {
                                color: $border-blue;
                                font-size: 18px;
                                margin: 30px 0 8px 0;
                            }

                            ul {
                                padding: 0 0 0 28px;
                            }
                        }
                    }
                }
            }
            //four column text block
            .fourcolumntextblock {
                margin-top: 230px;

                .container {
                    width: 100%;
                    background: $background-light-blue;

                    & > div {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        .column {
                            flex: 100%;
                            float: none;

                            @include desktop {
                                flex: 50%;
                            }

                            h1, h2, h3, h4, h5, h6 {
                                color: $border-blue;
                            }

                            &.column--2 {

                                .section-title {
                                    margin-bottom: 0;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: $light-orange;
                                }
                            }


                            .column__img {
                                top: -150px;
                                left: 0;
                                height: calc(100% + 150px);
                            }
                        }
                    }
                }
            }

            .formoutercontainerblock {
                .container {
                    width: 100%;
                }
            }

            .quoteblock {
                border-left: 4px solid $light-orange;
                padding: 32px;

                blockquote {
                    text-align: left;
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 700;
                    color: $text-color-black;
                    font-style: italic;
                    max-width: unset;
                }

                .author {
                    text-align: left;
                    color: $text-color-black;
                }
            }

            .calltoactionblock {
                .calltoaction-block {
                    .calltoaction-block__container {
                        width: 100%;
                    }

                    .calltoaction-block__button {
                        a {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

.p-blogitempage {
    .article_v2 {
        padding-top: 0;

        @include mobile {
            padding-top: 0;
        }
    }
}
