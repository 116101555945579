﻿.locations-map-block {
    @include desktop {
        position: relative;
        height: 700px;
    }
    
    &__filters-section {
        @include desktop {
            position: absolute;
            z-index: 2;
        }

        background-color: $text-color-blue;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        color: white;
        padding: 10px 40px;

        > h1 {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 30px;
        }

        > label {
            font-size: 10px;
            font-weight: 500;
            margin-bottom: 8px;
        }

        > select {
            width: 250px;
            height: 45px;
            margin-bottom: 20px;
            color: black;
            font-size: 12px;
            line-height: 17px;
            padding: 5px 10px;
            border: 0px;
            border-right: 10px solid white;
            background-color: white;

            .default-select-value {
                color: $text-color-darker-grey
            }
        }
    }

    &__map {
        @include desktop {
            position: absolute;
            top: 100px;
        }

        @include mobiletablet {
            position: relative;
        }

        width: 100%; 
        height: 600px; 
    }

    &__marker-popup {
        z-index: 10;

        display: flex;
        flex-direction: column;
        gap: 6px;

        > h1 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 6px;
        }

        > p {
            font-size: 18px;
        }
        
        > a {
            font-size: 18px;
            text-decoration: underline;
        }

        > a:focus, > a:focus-visible {
            outline: transparent;
        }
    }
}