$two-column-text-padding: 40px;

.twocolumnbackgroundblock {
  .twocolumnbackground-block{
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: 80px; 
    padding: 80px 20px;
    
    
    .twocolumn-background__block {
 
      .twocolumn-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 1145px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        
        @include mobile{
          flex-direction: column !important;
        }
        
        
        .twocolumn-content{
          color: $text-color-white;
          line-height: 26px;
          flex: 50%;
          padding-right: 50px;
          
          
          .twocolumn-title {
            color: $text-color-white;
          }
          
          ul{
            list-style: inside;
            padding-left: 0;
          }
          
          @include mobile{
            padding-bottom: 20px;
            padding-right: 0;
          }
          
        }

        .twocolumn-image {
          flex: 50%;    
          
          & > div{
            height: 100%;
            
            .videoblock{
              height: 100%;
              .video-block{
                height: 100%;
                .embed-responsive{
                  height: 100%;
                  max-width: 500px;
                  
                  video{
                    z-index: inherit;
                    
                  }
                }
              }
            }
          }
          
          img {
            max-width: 500px;
            width: 100%;
          }
        }
      }
      
      &.image-left{
        .twocolumn-container {
          flex-direction: row-reverse;
          .twocolumn-content{
            padding-left: $two-column-text-padding;
            padding-right: 50px;
            
            @include mobile{
              padding-bottom: 20px;
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
      
      @include mobile{
        padding: 20px;
        font-size: 16px;
      }
      
    }
  }
}