﻿.hero-block-container {
    position: relative;
    display: flex;
    justify-content: center;
    height: 60vh;

    @include mobiletablet {
        min-height: 300px;
        height: 45vh;
    }
    
    > img, video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        
        @include mobiletablet {
            height: 45vh;
        }
    }

    .hero-block-text-content {
        position: absolute;
        bottom: 50px;
    }

    .title {
        max-width: 500px;
        font-size: 44px;
        font-weight: 700;

        @include tablet {
            font-size: 32px;
        }

        @include mobile {
            font-size: 24px;
        }
    }
    .text {
        max-width: 500px;
        margin-top: 24px;
        font-size: 18px;
        font-weight: 300;
    }
}