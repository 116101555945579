﻿.titleleadfact-block {

    &__container {
        padding: 90px 65px 80px;
        width: 100%;
        position: relative;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        @include tablet {
            padding: 50px 8px 75px;
            margin: 0;
        }

        @include mobile {
            padding: 50px 0px;
            margin: 0;
        }
    }

    &__content {
        width: 55%;
        order: 2;
        max-width: 655px;
        margin-left: calc(55% - 655px);

        @media (max-width: 1599px) {
            margin-left: 0;
        }

        @include mobile {
            max-width: auto;
            max-width: unset;
            width: 100%;
            order: 1;
        }


        ul {
            padding-left: 0;
            list-style: none;
            column-count: 2;
            column-gap: 40px;
            padding-top: 5px;

            @include mobiletablet {
                column-count: 1;
            }


            li {
                margin-bottom: 15px;



                span, a {
                    font-size: 16px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.5;
                    color: $text-color-blue;
                    position: relative;

                    &:hover, &:active, &:focus {
                        text-decoration: none;
                        outline: none;
                    }
                }

                a {
                    &:after {
                        content: "";
                        background-image: url("/src/images/ArrowBlue.svg");
                        background-size: cover;
                        background-repeat: no-repeat;
                        margin-left: 5px;
                        margin-top: 5px;
                        display: inline;
                        position: absolute;
                        width: 12px;
                        height: 12px;
                    }
                }
            }

            &.one {
                column-count: 1;

                li {
                    @include tablet {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__content-text {
        h1, h2, h3, h4, h5, h6 {
            color: $text-color-blue;
        }

        a, a:hover, a:focus {
            color: $text-color-orange;
            font-size: 16px;
            line-height: 1.63;
            margin-top: 40px;

            @include mobile {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.71;
                margin-top: 25px;
            }
        }

        p {
            font-size: 16px;
            line-height: 1.63;
            margin-top: 40px;

            @include mobile {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.71;
                margin-top: 25px;
            }
        }
    }

    &__heading {
        font-size: 35px;
        line-height: 1.29;
        letter-spacing: normal;
        color: $text-color-blue;

        @include tablet {
            font-size: 3rem;
        }

        @include mobile {
            font-size: 25px;
            line-height: 1.38;
            margin: 0 0 20px;
            font-weight: bold;
        }
    }

    &__lead {
        font-size: 16px;
        line-height: 1.63;
        margin-top: 40px;

        @include mobile {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.71;
            margin-top: 25px;
        }
    }

    &__facts-heading {
        margin-top: 40px;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1.5px;
        color: black;
        text-transform: uppercase;

        @include mobile {
            margin-top: 25px;
        }
    }

    &__image {
        width: 45%;
        order: 1;
        align-self: center;

        img {
            width: 100%;
            max-width: auto;
            max-width: unset;
            display: block;

            @media (max-width: 1599px) {
                max-width: 430px;
            }

            @media (max-width: 1200px) {
                max-width: auto;
                max-width: unset;
            }
        }

        @media (max-width: 1200px) {
            padding-right: 30px;
        }


        @include mobile {
            width: 100%;
            order: 2;
            padding-right: 0;
            margin: 0 auto;
            margin-top: 50px;
        }

        &--circle {
            border-radius: 50%;
            max-width: 500px;

            img {
                border-radius: 50%;
                border: 5px solid $border-blue;
            }

            @include mobile {
                padding: 0 20px;
            }
        }
    }

    &--img-right {
        .titleleadfact-block {
            &__content {
                order: 1;
                margin-left: 0;
                margin-right: calc(55% - 655px);

                @include mobile {
                    margin-right: 0;
                }
            }

            &__image {
                order: 2;



                @media (max-width: 1200px) {
                    padding-left: 30px;
                }

                @include mobile {
                    padding-left: 0;
                }

                &--circle {

                    img {
                        float: right;
                    }

                    @include mobile {
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}
