﻿.productcontent-block {
    &__container {
        padding: 90px 65px 80px;
        width: 100%;
        position: relative;

        @include tablet {
            padding: 50px 8px 75px;
            margin: 0;
        }

        @include mobile {
            padding: 50px 0 35px;
            margin: 0;
        }
    }

    &__intro {
        width: 50%;

        @include mobile {
            width: 100%;
        }
    }

    &__title {
        font-size: 35px;
        line-height: 1.29;
        letter-spacing: normal;
        color: $text-color-blue;
        margin: 0 0 30px;
        max-width: 555px;

        @include tablet {
            font-size: 3rem;
        }

        @include mobile {
            font-size: 25px;
            font-weight: bold;
            line-height: 1.38;
            margin: 0 0 15px;
        }
    }

    &__lead {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: normal;
        max-width: 555px;

        @include mobile {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.71;
        }
    }

    &__mobile-only-read-more {
        @include mobile {
            display: flex;
        }
        @include tablet {
            display: none;
        }
        @include desktop {
            display: none;
        }
    }

    &__content {
        display: flex;
        flex-flow: row wrap;

        > div {
            width: 50%;

            @include mobile {
                width: 100%;
            }
        }

        .product {
            &__info {
                @include mobile {
                    order: 2;
                    margin-top: 20px;
                }

                > h3 {
                    font-size: 18px;
                    line-height: 1.56;
                    letter-spacing: normal;
                    color: $text-color-blue;
                    max-width: 555px;

                    @include mobile {
                        font-size: 14px;
                    }
                }

                > p {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.71;
                    letter-spacing: normal;
                    max-width: 555px;
                }

                > a {
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $text-color-blue;
                    padding: 5px 0;
                    display: flex;
                    align-items: center;

                    &:before {
                        content: "";
                        border-top: 2px solid $border-blue;
                        width: 25px;
                        display: block;
                        margin-right: 12px;
                    }
                }

                ul {
                    padding-left: 0;
                    list-style: none;
                    display: flex;
                    flex-flow: row wrap;
                    margin-bottom: 0;

                    @include mobile {
                        margin-top: 20px;
                    }

                    li {
                        width: 74px;
                        margin: 40px 40px 0 0;

                        &:last-child {
                            margin: 40px 0 0 0;
                        }

                        cursor: pointer;

                        @include mobile {
                            margin: 10px 10px 10px 0;
                            width: calc(25% - 10px);
                        }


                        > div {
                            height: 74px;
                            width: 74px;
                            margin: 0 auto;

                            @include mobile {
                                height: 60px;
                                width: 60px;
                            }

                            > div {
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: top;
                                height: 100%;
                                width: 100%;
                                border-radius: 50%;
                            }
                        }

                        span {
                            display: block;
                            margin-top: 20px;
                            font-size: 12px;
                            font-weight: normal;
                            line-height: 1.83;
                            letter-spacing: normal;
                            text-align: center;

                            @include mobile {
                                margin-top: 5px;
                                font-weight: bold;
                            }
                        }

                        &.active {
                            > div {
                                > div {
                                    border: 3px solid $border-blue;

                                    @include mobile {
                                        border: 2px solid $border-blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__img {
                position: absolute;
                right: 65px;
                top: 50%;
                display: flex;
                justify-content: flex-end;
                transform: translateY(-50%);
                width: calc(50% - 65px);
                max-height: calc(100% - 170px);

                @media (max-width: 1599px) {
                    width: calc(50% - 110px);
                }

                @include tablet {
                    right: 0;
                    width: calc(50% - 20px);
                }

                @include mobile {
                    width: 100%;
                    right: 0;
                    position: relative;
                    top: auto;
                    top: unset;
                    transform: none;
                    transform: unset;
                    margin-top: 20px;
                    max-height: auto;
                    max-height: unset;
                    order: 1;
                    display: block;
                }

                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &--default {
        .productcontent-block {
            &__content {
                .product {
                    &__info {
                        .dropdown-menu {
                            position: relative;
                            top: 0;
                            background: none;
                            border: 0;
                            box-shadow: none;
                            border-radius: 0;
                            padding: 0;
                            margin: 0;
                        }


                        .dropdown-toggle {
                            display: none;
                        }
                    }
                }
            }
        }

        &.right {
            .productcontent-block {
                &__title {
                }

                &__lead {
                }

                &__intro {
                    margin-left: 50%;
                    padding-left: calc(50% - 555px);

                    @include mobile {
                        margin-left: 0;
                        padding-left: 0;
                    }
                }

                &__content {
                    > div {
                        margin-left: 50%;
                        padding-left: calc(50% - 555px);

                        @include mobile {
                            margin-left: 0;
                            padding-left: 0;
                        }
                    }

                    .product {
                        &__img {
                            margin-left: 0;
                            padding-left: 0;
                            right: 0;
                            left: 65px;

                            @include mobiletablet {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }

        @include mobile {
            .productcontent-block {
                &__content {
                    > div {
                        display: flex;
                        flex-flow: wrap;
                        position: relative;
                    }

                    .product {
                        &__info {
                            > h3 {
                                display: none;
                                orphans: 1;
                            }

                            > p {
                                order: 3;
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 1.71;
                            }

                            > a {
                                order: 4;
                            }

                            ul {
                                order: 2;
                            }

                            .dropdown-toggle {
                                width: 100%;
                                height: 62px;
                                border-radius: 6px;
                                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                                background-color: $background-white;
                                padding: 13px 11px;
                                color: $text-color-blue;
                                border: 0;
                                font-size: 12px;
                                font-weight: bold;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: 1.56;
                                text-align: left;
                                display: flex;
                                align-items: center;
                                position: relative;
                                margin-bottom: 15px;

                                &:hover, &:focus, &:active {
                                    outline: 0;
                                }

                                &:after {
                                    content: "\f078";
                                    font-family: FontAwesome;
                                    color: $text-color-blue;
                                    display: inline;
                                    right: 15px;
                                    position: absolute;
                                    font-size: 12px;
                                    top: 22px;
                                }

                                > div > div {
                                    height: 36px;
                                    width: 36px;
                                    border-radius: 50%;
                                    background-position: top;
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    margin-right: 12px;
                                }

                                span {
                                    width: 100%;
                                    white-space: normal;
                                    line-height: 1;
                                    padding-right: 17px;
                                }
                            }

                            .dropdown-menu {
                                display: none;
                            }

                            &.open {
                                .dropdown-menu {
                                    top: -15px;
                                    border-radius: 0 0 6px 6px;
                                    box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.08);
                                    background-color: $background-white;
                                    flex-flow: column;
                                    display: flex;
                                    width: 100%;
                                    border: 0;

                                    .active {
                                        display: none;
                                    }

                                    li {
                                        width: calc(100% - 22px);
                                        margin: 10px 11px;
                                        display: flex;
                                        justify-content: flex-start;
                                        flex-flow: row;
                                        align-items: center;
                                        color: $text-color-blue;
                                        font-weight: bold;

                                        > div {
                                            margin: 0 12px 0 0;
                                            width: auto;
                                            height: auto;

                                            > div {
                                                height: 36px;
                                                width: 36px;
                                            }
                                        }

                                        span {
                                            white-space: normal;
                                            line-height: 1;
                                            text-align: left;
                                        }
                                    }
                                }

                                .dropdown-toggle {
                                    border-radius: 6px 6px 0 0;
                                }

                                .dropdown-backdrop {
                                    display: none;
                                }
                            }
                        }

                        &__img {
                            order: 5;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &--link {
        .productcontent-block {
            &__container {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }

            &__lead {
                margin-bottom: 25px;
            }

            &__intro {
                order: 1;
                max-width: 555px;
                margin-right: calc(50% - 555px);

                @media (max-width: 1599px) {
                    margin-right: 0;
                }

                @include mobile {
                    max-width: auto;
                    max-width: unset;
                }

                ul {
                    padding-left: 0;
                    list-style: none;
                    display: flex;
                    flex-flow: row wrap;
                    padding-top: 15px;

                    @include mobile {
                        margin-bottom: 0;
                    }

                    li {
                        padding: 12px 0;
                        border-bottom: 1px solid $border-pale-grey;
                        width: 50%;

                        @media (max-width: 1200px) {
                            width: 100%;
                        }

                        img {
                            max-width: 45px;
                            height: auto;
                            width: 100%;
                            margin-right: 15px;
                        }

                        a {
                            display: flex;
                            flex-flow: row;
                            text-decoration: underline;
                            align-items: center;
                            min-height: calc(70px - 24px);
                            margin-right: 30px;

                            @include mobile {
                                margin-right: 0;
                            }
                        }

                        span {
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 1.71;
                            color: $text-color-blue;
                            width: calc(100% - 45px);
                        }

                        &:last-child {
                            width: 100%;

                            @include mobile {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }

            &__img {
                width: 50%;
                order: 2;
                align-self: center;


                img {
                    width: 100%;
                    max-width: 450px;
                    max-width: unset;
                    display: block;
                    float: right;

                    @media (max-width: 1599px) {
                        max-width: 430px;
                    }

                    @media (max-width: 1200px) {
                        max-width: auto;
                        max-width: unset;
                        padding-left: 30px;
                    }

                    @include mobile {
                        padding-left: 0;
                    }
                }

                @include mobile {
                    width: 100%;
                    order: 1;
                    display: none;
                }
            }

            &__sub-heading {
                font-size: 18px;
                font-weight: bold;
                font-style: normal;
                line-height: 1.56;
                letter-spacing: normal;
                color: $text-color-blue;
            }
        }

        &.right {
            .productcontent-block {


                &__intro {
                    order: 2;
                    margin-left: calc(50% - 555px);
                    margin-right: 0;


                    @media (max-width: 1599px) {
                        margin-left: 0;
                    }

                    @include mobile {
                        max-width: auto;
                        max-width: unset;
                        order: 1;
                    }

                    ul {
                        padding-left: 0;

                        li {

                            img {
                                margin-right: 15px;
                            }
                        }
                    }
                }

                &__img {
                    order: 1;

                    @include mobile {
                        order: 2;
                    }

                    img {
                        float: left;

                        @media (max-width: 1200px) {
                            padding-right: 30px;
                            padding-left: 0;
                        }

                        @include mobile {
                            padding-right: 0;
                        }
                    }

                    @include mobile {
                        order: 2;
                    }
                }
            }
        }
    }

    &--image {
        .productcontent-block {
            &__container {
                display: flex;
                flex-flow: row wrap;
            }

            &__intro {
                max-width: 555px;
                margin-right: calc(50% - 555px);


                @media (max-width: 1599px) {
                    margin-right: 0;
                }

                @include mobile {
                    max-width: auto;
                    max-width: unset;
                    order: 1;
                }

                .product-info {
                    width: 100%;

                    > a {
                        font-size: 12px;
                        font-weight: 500;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: $text-color-blue;
                        padding: 5px 0;
                        display: flex;
                        align-items: center;

                        @include mobile {
                            display: inline-flex;
                        }

                        &:before {
                            content: "";
                            border-top: 2px solid $border-blue;
                            width: 25px;
                            display: block;
                            margin-right: 12px;
                        }
                    }

                    .dropdown-backdrop {
                        display: none;
                    }

                    .dropdown-toggle {
                        display: none;

                        @include mobile {
                            width: 100%;
                            height: 62px;
                            border-radius: 6px;
                            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
                            background-color: $background-white;
                            padding: 13px 11px;
                            color: $text-color-blue;
                            border: 0;
                            font-size: 12px;
                            font-weight: bold;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 1.56;
                            text-align: left;
                            display: flex;
                            align-items: center;
                            position: relative;
                            margin: 25px 0 15px;

                            > div > div {
                                height: 36px;
                                width: 85px;
                                background-position: top;
                                background-size: contain;
                                background-repeat: no-repeat;
                                margin-right: 12px;
                                box-shadow: none;
                            }

                            span {
                                width: 100%;
                                white-space: normal;
                                line-height: 1;
                                padding-right: 17px;
                            }

                            &:hover, &:active, &:focus {
                                outline: 0;
                            }

                            &:after {
                                content: "\F078";
                                font-family: FontAwesome;
                                color: $text-color-blue;
                                display: inline;
                                right: 15px;
                                position: absolute;
                                font-size: 12px;
                                top: 22px;
                            }
                        }
                    }

                    .dropdown-menu {
                        position: relative;
                        top: 0;
                        background: none;
                        border: 0;
                        box-shadow: none;
                        border-radius: 0;
                        padding: 0;
                        margin: 0;
                        width: 100%;

                        @include mobile {
                            display: none;
                        }
                    }

                    &.open {
                        .dropdown-toggle {
                            border-radius: 6px 6px 0 0;
                        }

                        .dropdown-menu {
                            @include mobile {
                                top: -15px;
                                border-radius: 0 0 6px 6px;
                                box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.08);
                                background-color: $background-white;
                                flex-flow: column;
                                display: flex;
                                width: 100%;

                                .active {
                                    display: none;
                                }

                                li {
                                    width: calc(100% - 22px);
                                    margin: 10px 11px;

                                    span {
                                        margin-top: 5px;
                                        font-weight: bold;
                                        color: $text-color-blue;
                                        width: 100%;
                                        white-space: normal;
                                        line-height: 1;
                                        text-align: left;
                                    }

                                    a {
                                        display: flex;
                                        justify-content: flex-start;
                                        flex-flow: row;
                                        align-items: center;
                                        color: $text-color-blue;
                                        font-weight: bold;
                                        margin: auto;
                                        margin: unset;
                                        width: 100%;

                                        > div {
                                            margin: 0 12px 0 0;
                                            width: auto;
                                            height: auto;

                                            > div {
                                                height: 36px;
                                                width: 85px;
                                                box-shadow: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ul {
                    padding-left: 0;
                    list-style: none;
                    display: flex;
                    flex-flow: row wrap;
                    margin-bottom: 0;

                    li {
                        width: calc(25% - 20px);
                        margin: 40px 20px 0 0;
                        cursor: pointer;

                        @include mobile {
                            width: calc(50% - 20px);
                        }

                        a {
                            width: 100%;
                            margin: 0 auto;
                            display: block;
                            background: transparent;
                            padding: 0;

                            &:hover, &:focus, &:active {
                                text-decoration: none;
                                background: transparent;
                            }

                            > div {
                                height: 73px;

                                div {
                                    background-size: cover;
                                    background-repeat: no-repeat;
                                    background-position: top;
                                    height: 100%;
                                    width: 100%;
                                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
                                }
                            }

                            span {
                                display: block;
                                margin-top: 20px;
                                font-size: 12px;
                                font-weight: normal;
                                line-height: 1.83;
                                letter-spacing: normal;
                                text-align: center;
                                color: $text-color-black;
                                white-space: pre-wrap;

                                @include mobile {
                                    margin-top: 5px;
                                }
                            }
                        }

                        &.active {
                            a {
                                div {
                                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
                                    border: 2px solid $border-blue;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.right {
            .productcontent-block {

                &__intro {
                    margin-left: 50%;
                    padding-left: calc(50% - 555px);
                    margin-right: 0;
                    max-width: none;

                    @include mobile {
                        margin-left: 0;
                        padding-left: 0;
                    }
                }


                &__content {

                    .product {
                        &__img {
                            margin-left: 0;
                            padding-left: 0;
                            right: 0;
                            left: 65px;

                            @include mobiletablet {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
