﻿.card-block {
	height: 100%;

	&__image {
		width: 100%;
		object-fit: cover;
	}

	&__content {
		> h4 {
			color: $text-color-blue;
			font-size: 16px;
			font-weight: 700;
			line-height: 26px;
		}

		> p {
			font-size: 16px;
			font-weight: 400;
			line-height: 26px;
			max-width: 500px;
		}

		> div {
			display: flex;
			align-items: center;
			gap: 8px;

			> a {
				color: $text-color-orange;
				font-size: 14px;
				font-weight: 500;
				line-height: 24px;
				display: flex;
				align-items: center;
				gap: 10px;
			}

			.arrow {
				color: $text-color-orange;
				width: 16px;
				height: 16px;
				background-image: url(/src/images/ArrowOrange.svg);
			}
		}
	}

	&:not(.single-image) {
		.card-block__images-container {
			position: relative;

			img {
				transition-timing-function: ease-in;
				transition-duration: 0.5s;

				&:not(:nth-child(1)) {
					position: absolute;
					top: 0;
					left: 0;
					opacity: 0;
				}
			}

			&:hover {
				img:nth-child(1) {
					opacity: 0;
					transition-duration: 0.6s;
				}
				@for $i from 2 through 15 {
					img:nth-child(#{$i}) {
						opacity: 1;
						transition-delay: calc((#{$i} - 2) * 0.8s);
					}
				}
			}

			&.visible-on-viewport {
				$delay: 1.5s;
				img:nth-child(1) {
					opacity: 0;
					transition-delay: calc($delay);
				}
				@for $i from 2 through 15 {
					img:nth-child(#{$i}) {
						opacity: 1;
						transition-delay: calc((#{$i} - 2) * 0.8s + $delay);
					}
				}
			}
		}
	}
}

.h-100{
	height: 100%;
}