﻿
.multi-column-block {
 
    &__container {
        margin: 90px 65px 120px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        > div {
            width: 100%;


            @include mobile {
                width: 100%;
            }

            &:first-child {
                max-width: 554px;


                @include mobile {
                    justify-content: center;
                    display: flex;
                    flex-flow: column;
                    margin: 0 auto;
                    margin: unset;
                }
            }
        }

        @include mobiletablet {
            margin: 70px 28px 100px 28px;
        }

        @include mobile {
            margin: 40px 0 0 0;
        }
    }

    &__heading {
        font-size: 35px;
        line-height: 1.43;
        margin-bottom: 40px;
        color: $text-color-blue;
        margin-top: 0;

        @include mobile {
            font-size: 17px;
            line-height: normal;
            margin-bottom: 20px;
        }
    }

    &__lead {
        font-size: 16px;
        line-height: 1.63;
        margin-bottom: 60px;

        @include mobile {
            margin-bottom: 0;
        }
    }

    .btn-primary {
        @include button-primary($background-orange, $text-color-white);
        margin: 0 auto;
        font-weight: 500;
    }

    &__columns {
        @include mobile {
            margin-top: 60px;
        }

        > div {
            display: flex;
            flex-flow: row wrap;
            width: 100%;


            > div {
                width: 25%;
                margin-bottom: 45px;

                @include tablet {
                    width: 50%;
                    max-width: 554px;
                }

                @include mobile {
                    width: 100%;
                    max-width: 554px;
                    margin-bottom: 0;
                }

                .title-lead-and-image {
                    height: 100%;

                    &__container {
                        flex-flow: wrap;
                        justify-content: flex-start;
                        margin: 0;
                        height: 100%;
                    }

                    &__icon {
                        display: block;

                        img {
                            width: 40px;
                        }
                    }

                    &__content {
                        width: calc(100% - 30px);
                        min-width: auto;
                        min-width: unset;
                        max-width: auto;
                        max-width: unset;
                        margin-left: 0;
                        margin-right: 0;
                        height: 100%;
                        position: relative;

                        @include mobile {
                            width: 100%;
                        }

                        h3 {
                            font-size: 14px;
                            text-transform: uppercase;
                            margin: 25px 0 15px;

                            @include mobile {
                                margin: 0 0 10px;
                                line-height: normal;
                            }
                        }

                        p {
                            font-size: 14px;
                            line-height: 1.71;
                            margin: 0 0 40px 0;

                            @include mobile {
                                line-height: 1.43;
                                margin: 0 0 40px 0;
                            }
                        }

                        .btn-default {
                            @include arrow-link($text-color-blue);
                            align-items: flex-end;
                            display: flex;
                            position: absolute;
                            bottom: 0;
                            margin: 0;

                            &:after {
                                background-image: url('/src/images/ArrowBlue.svg');
                                margin-left: 10px;
                            }

                            @include mobile {
                                position: relative;
                                margin-bottom: 70px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.content-shading-cover {
    .multi-column-block {
        &__container {
            margin: 90px 65px 65px;

            @include mobiletablet {
                margin: 70px 28px 70px 28px;
            }

            @include mobile {
                margin: 40px 0 0 0;
            }
        }
    }
}
