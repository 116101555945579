﻿.textandimage-block {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    max-width: 1020px;
    float: right;

    @media (max-width: 1599px) {
        padding-left: 30px;
    }

    @media (max-width: 1215px) {
        max-width: 900px;
        margin: 0 auto;
        float: none;
        padding: 0;
    }

    &__text {
        width: 50%;
        order: 1;

        h1, h2, h3, h4, h5, h6 {
            color: $text-color-blue;
            margin-bottom: 30px;
            font-weight: bold;

            @include mobile {
                margin-bottom: 25px;
            }
        }

        h1, h2, h3 {
            line-height: 1.29;

            @include mobile {
                font-size: 25px;
                font-weight: bold;
                line-height: 1.38;
            }
        }

        h4, h5, h6 {
            line-height: 1.56;
        }

        p {
            line-height: 1.71;
            margin: 0 0 30px;

            @include mobile {
                margin: 0 0 25px;
            }
        }

        @include mobile {
            width: 100%;
        }
    }

    &__img {
        width: calc(50% - 90px);
        margin-left: 90px;
        order: 2;

        img {
            display: block;
            width: 100%;
            height: auto;
        }

        @include mobile {
            width: 100%;
            margin-left: 0;
        }
    }

    &--image-left {
        float: left;



        .textandimage-block {
            &__text {
                order: 2;

                @include mobile {
                    order: 1;
                }
            }

            &__img {
                order: 1;
                margin-left: 0;
                margin-right: 90px;

                @include mobile {
                    margin-right: 0;
                    order: 2;
                }
            }
        }

        @media (max-width: 1599px) {
            padding-left: 0;
            padding-right: 30px;
        }

        @media (max-width: 1215px) {
            float: none;
            padding: 0;
        }
    }
}
